import axios from './axios';
import util from './util';

const linkPortfolio = (redirect) => {
  return axios
    .get('/snaptrade/redirect', {
      params: { redirect },
    })
    .then(util.throwOrReturn);
};

const checkForNewAccounts = (since) => {
  return axios
    .get('/snaptrade/check', {
      params: { since },
    })
    .then(util.throwOrReturn);
};

export default {
    linkPortfolio,
    checkForNewAccounts,
};
