import axios from './axios';
import util from './util';

const getUserInfo = () => {
  return axios
    .get('/v1/user')
    .then(util.throwOrReturn);
};

const updateUserInfo = ({ first_name, last_name, email }) => {
  return axios
    .patch('/v1/user', {
      first_name,
      last_name,
      email,
    })
    .then(util.throwOrReturn);
};

const uploadAvatar = (file) => {
  const data = new FormData();
  data.append('image', file);
  return axios
    .post('/v1/user/avatar', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(util.throwOrReturn);
}

const updateAppSetting = (key, value) => {
  return axios
    .patch('/v1/user/settings/app', {
      key,
      value,
    })
    .then(util.throwOrReturn);
}

const updateEmailSetting = (email, enable) => {
  return axios
    .patch('/v1/user/settings/emails', {
      email,
      enable,
    })
    .then(util.throwOrReturn);
}

const getUserPlan = () => {
  return axios
    .get('/v1/user/billing/plan')
    .then(util.throwOrReturn);
}

const getUserInvoices = () => {
  return axios
    .get('/v1/user/billing/invoices')
    .then(util.throwOrReturn);
}

const cancelPlan = () => {
  return axios
    .delete('/v1/user/billing/plan')
    .then(util.throwOrReturn);
}

const resumePlan = () => {
  return axios
    .post('/v1/user/billing/plan/reactivate')
    .then(util.throwOrReturn);
}

const getFavouriteStocks = () => {
  return axios
    .get('/v1/favourite-stocks')
    .then(util.throwOrReturn);
}

const getClimateConsents = () => {
  return axios
    .get('/v1/notification-consents/climate')
    .then(util.throwOrReturn);
}

const optInForClimateNotification = (stock) => {
  return axios
    .post('/v1/notification-consents/climate', {
      stock,
    })
    .then(util.throwOrReturn);
}

const optOutForClimateNotification = (stock) => {
  return axios
    .delete('/v1/notification-consents/climate', {
      data: { stock },
    })
    .then(util.throwOrReturn);
}

export default {
  getUserInfo,
  updateUserInfo,
  uploadAvatar,
  updateAppSetting,
  updateEmailSetting,
  getUserPlan,
  getUserInvoices,
  cancelPlan,
  resumePlan,
  getFavouriteStocks,
  getClimateConsents,
  optInForClimateNotification,
  optOutForClimateNotification,
};
