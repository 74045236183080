import { realtimeInstance } from './axios';
import util from './util';

const fetchMarket = () => {
  return realtimeInstance
    .get('/market')
    .then(util.throwOrReturn);
}

const fetchFavourites = () => {
  return realtimeInstance
    .get('/favourites')
    .then(util.throwOrReturn);
}

const fetchStockPrice = (ticker) => {
  return realtimeInstance
    .get(`/stocks/${ticker}/price`)
    .then(util.throwOrReturn);
}

const fetchEtfPrice = (ticker) => {
  return realtimeInstance
    .get(`/etfs/${ticker}/price`)
    .then(util.throwOrReturn);
}

const fetchSecurityPrice = (ticker) => {
  return realtimeInstance
    .get(`/securities/${ticker}/price`)
    .then(util.throwOrReturn);
}

export default {
  fetchMarket,
  fetchFavourites,
  fetchStockPrice,
  fetchEtfPrice,
  fetchSecurityPrice,
}
