var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex text-center flex-column flex-md-row flex-grow-1"
  }, [_c('div', {
    staticClass: "pa-sm-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column"
  }, [_c('div', {
    staticClass: "layout-content ma-auto w-full"
  }, [_vm._t("default")], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }