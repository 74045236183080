import api from '@ziggma/api-client';

// Vue app will use the library and will be agnostic of the urls.
// For example:
// api.auth.login(email, password).then(...).catch(...);
// Or when you create the StockService:
// api.stocks.getStock(stockId or ticker).then(...).catch(...);

const logError = (error) => {
  // console.error(`[API] ${error.name}: ${error.details?.messages || error.message}`)
  throw error;
};

export default {
  name: 'AuthService',

  login(email, password) {
    return api.auth
      .login(email, password)
      .catch(logError);
  },

  register(params, token) {
    if (!token) {
      throw new Error('Token must be provided');
    }

    return api.auth
      .register(params, token)
      .catch(logError);
  },

  logout() {
    return api.auth
      .logout()
      .catch(logError);
  },

  authUser() {
    return api.auth
      .user()
      .then((res) => res.data)
      .catch(logError);
  },

  forgotPassword(email, token) {
    if (!email) {
      throw new Error('Email must be provided');
    }
    if (!token) {
      throw new Error('Token must be provided');
    }

    return api.auth
      .forgotPassword(email, token)
      .catch(logError);
  },

  resetPassword(params) {
    return api.auth
      .resetPassword(params)
      .catch(logError);
  },

  async changePassword(password, passwordConfirmation) {
    if (!password) {
      throw new Error('Password must be provided');
    }
    if (!passwordConfirmation) {
      throw new Error('Password confirmation must be provided');
    }
    if (password !== passwordConfirmation) {
      throw new Error('Passwords do not match.');
    }

    return api.auth
      .changePassword({ password, passwordConfirmation })
      .catch(logError);
  },

  verifyEmail(id, hash, queryParams) {
    if (!id) {
      throw new Error('The \'id\' must be provided.');
    }
    if (!hash) {
      throw new Error('The \'hash\' must be provided.');
    }

    return api.auth
      .verifyEmail(id, hash, queryParams)
      .catch(logError);
  },

  resendConfirmationEmail() {
    return api.auth
      .resendConfirmationEmail()
      .catch(logError);
  },
};
