import axios from './axios';
import util from './util';

const createLinkToken = () => {
  return axios
    .get('/plaid/link_token')
    .then(util.throwOrReturn);
};

const createPortfolioFromToken = (token) => {
  return axios
    .post('/plaid/connect', {
      public_token: token,
    })
    .then(util.throwOrReturn);
};

export default {
  createLinkToken,
  createPortfolioFromToken,
};
