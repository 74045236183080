<template>
  <v-menu
    left
    min-width="170"
    offset-y
    transition="slide-y-transition">
    <template #activator="{ on }">
      <v-btn icon :ripple="false" v-on="on">
        <v-avatar color="primary" size="32">
          <v-img v-if="avatarUrl" :src="avatarUrl" />
          <span v-else class="white--text text-h6">{{ userInitials }}</span>
        </v-avatar>
      </v-btn>
    </template>

    <!-- user menu list -->
    <v-list dense nav>
      <v-list-item
        v-for="(item, index) in menu"
        :key="index"
        :disabled="item.disabled"
        :exact="item.exact"
        :to="item.link"
        link>
        <v-list-item-icon>
          <v-icon :class="{ 'grey--text': item.disabled }" small>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.key ? $t(item.key) : item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-list-item href="https://ziggma.com/faq" target="_blank">
        <v-list-item-icon>
          <v-icon small>mdi-help-circle-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('menu.faq') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <v-divider class="my-1" />

      <v-list-item inactive>
        <v-list-item-content class="py-0" @click.stop.prevent>
          <v-list-item-title class="d-flex justify-space-between align-center py-1">
            <div>Dark Mode</div>
            <v-switch
              :input-value="isDark" :ripple="false" hide-details
              @click.stop.prevent @change="toggleTheme" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-1" />

      <v-list-item @click="logout">
        <v-list-item-icon>
          <v-icon small>mdi-logout-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('menu.logout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
/*
|---------------------------------------------------------------------
| Toolbar User Component
|---------------------------------------------------------------------
|
| Quick menu for user menu shortcuts on the toolbar
|
*/

import { mapGetters } from 'vuex';

import config from '@/configs';
import AuthService from '@/services/AuthService';

export default {
  data() {
    return {
      menu: config.toolbar.user,
    };
  },
  computed: {
    ...mapGetters('app', ['isDark']),
    ...mapGetters('user', ['user', 'userInitials']),
    avatarUrl() {
      return this.user.avatar_url; //  || '/images/avatars/avatar1.svg'
    },
  },
  methods: {
    toggleTheme(value) {
      this.$vuetify.theme.dark = value;
      config.theme.globalTheme = this.$vuetify.theme.dark ? 'dark' : 'light';
      this.$store.commit('app/setGlobalTheme', config.theme.globalTheme);
      localStorage.setItem('dark', this.$vuetify.theme.dark.toString());
    },
    async logout() {
      await this.$store.dispatch('app/startLoading');

      AuthService.logout()
        .then(() => {
          return this.$store.dispatch('logoutUser');
        })
        .then(async () => {
          await this.$store.dispatch('resetStore');
          await this.$router.push({ name: 'auth.login' });
        })
        .finally(() => {
          this.$store.dispatch('app/stopLoading');
        });
    },
  },
};
</script>
