import axios from './axios';
import util from './util';

const listCategories = () => {
  return axios
    .get('/v1/categories')
    .then(util.throwOrReturn);
};

const getCategory = (slug) => {
  return axios
    .get(`/v1/category/${slug}`)
    .then(util.throwOrReturn);
};

export default {
  listCategories,
  getCategory,
};
