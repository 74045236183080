var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isLoadingPlans ? _c('div', {
    staticClass: "fill-height d-flex align-center justify-center pa-6"
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "primary",
      "size": "48"
    }
  })], 1) : _vm.plans && _vm.plans.length > 0 ? _c('div', [_c('v-responsive', {
    staticClass: "mx-auto text-center"
  }, [_c('h2', {
    staticClass: "text-h3 text-lg-h2 pb-3"
  }, [_vm._v("Plans & Pricing")])]), _c('v-row', {
    staticClass: "mt-2",
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    staticClass: "d-flex justify-center align-center text-uppercase"
  }, [_c('v-btn-toggle', {
    attrs: {
      "dense": "",
      "mandatory": "",
      "color": "primary",
      "background-color": "transparent"
    },
    model: {
      value: _vm.period,
      callback: function callback($$v) {
        _vm.period = $$v;
      },
      expression: "period"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "outlined": "",
      "value": "monthly"
    }
  }, [_vm._v("MONTHLY BILLING")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "outlined": "",
      "value": "annual"
    }
  }, [_vm._v("ANNUAL BILLING")]), _c('v-chip', {
    attrs: {
      "label": "",
      "small": "",
      "color": "success"
    }
  }, [_vm._v("-25%")])], 1)], 1)], 1), !_vm.isMobile ? _c('v-row', {
    staticClass: "mx-auto justify-center"
  }, [_c('v-col', {
    staticClass: "d-flex justify-center",
    attrs: {
      "cols": "12"
    }
  }, _vm._l(_vm.plans, function (plan, i) {
    return _c('div', {
      key: i,
      staticClass: "mx-2"
    }, [_c('plan-card', {
      attrs: {
        "plan": plan,
        "prefer-annual": _vm.annual,
        "width": "380"
      },
      on: {
        "subscribe": _vm.subscribeTo
      }
    })], 1);
  }), 0)], 1) : _c('v-row', {
    staticClass: "mx-auto justify-center"
  }, _vm._l(_vm.plans, function (plan, i) {
    return _c('v-col', {
      key: i,
      staticClass: "d-flex justify-center",
      attrs: {
        "cols": "12",
        "md": "6",
        "lg": "5",
        "xl": "4"
      }
    }, [_c('plan-card', {
      attrs: {
        "plan": plan,
        "prefer-annual": _vm.annual
      },
      on: {
        "subscribe": _vm.subscribeTo
      }
    })], 1);
  }), 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }