import Vue from 'vue';
import VueECharts from 'vue-echarts';
import { use } from 'echarts/core'

/**
 * Vue ECharts
 * https://github.com/ecomfe/vue-echarts
 *
 */

// import ECharts modules manually to reduce bundle size
import {
  CanvasRenderer,
} from 'echarts/renderers'
import {
  BarChart,
  CandlestickChart,
  LineChart,
  PieChart,
} from 'echarts/charts'
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  MarkPointComponent,
  TimelineComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';

use([
  // Renderers
  CanvasRenderer,
  // Charts
  BarChart,
  CandlestickChart,
  LineChart,
  PieChart,
  // Components
  DatasetComponent,
  GridComponent,
  LegendComponent,
  MarkPointComponent,
  TimelineComponent,
  TitleComponent,
  TooltipComponent,
]);

Vue.component('EChart', VueECharts);
