<template>
  <v-dialog
    :value="!self ? value : isOpen" :width="isMobile ? '100%' : '65%'" max-width="950" persistent
    @input="onInput">
    <template v-if="!disableActivator" #activator="props">
      <slot name="activator" v-bind="props">
        <v-btn depressed color="primary" v-bind="props.attrs" v-on="props.on">Upgrade Now</v-btn>
      </slot>
    </template>

    <v-card flat>
      <v-card-title class="text-h5 text-wrap">
        <v-spacer />
        <v-btn
          right
          icon
          @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-2">
        <plans dialog />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import Plans from '@/components/subscription/Plans';

export default {
  name: 'SubscribeDialog',
  components: { Plans },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    self: {
      type: Boolean,
      default: false,
    },
    disableActivator: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    isMobile() {
      return !this.$vuetify.breakpoint.smAndUp;
    },
  },
  methods: {
    onInput(event) {
      this.$emit('input', event);
      if (!event) {
        this.$emit('close');
      }
      this.isOpen = event;
    },
    closeDialog() {
      this.$emit('input', false);
      this.isOpen = false;
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
