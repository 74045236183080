export const COLORS = [
  '#BD2026',
  '#D2222D',
  '#FF8E15',
  '#FDCD09',
  '#FFBF00',
  '#238823',
  '#007000',
];

export const COLOR_MAP = {
  'score-0': COLORS[0],
  'score-1': COLORS[1],
  'score-2': COLORS[2],
  'score-3': COLORS[3],
  'score-4': COLORS[4],
  'score-5': COLORS[5],
  'score-6': COLORS[6],
};

const getColor = (value) => {
  if (value === undefined || value === null || value === 'NA' || value === '') {
    return 'secondary';
  } else if (value <= 40) {
    return 'red';
  } else if (value <= 60) {
    return 'orange';
  } else if (value <= 80) {
    return 'secondary';
  } else {
    return 'green';
  }
};

const decideColorForScore = (score, steps = [40, 50, 60, 70, 80, 90, 100]) => {
  if (!score || score === 'NA' || score === '') {
    return 'secondary';
  }

  for (let i = 0; i < steps.length; i++) {
    if (score <= steps[i]) {
      return `score-${i}`;
    }
  }

  if (score > steps[steps.length - 1]) {
    return `score-${COLORS.length - 1}`;
  }
  if (score < steps[0]) {
    return 'score-0';
  }

  return 'secondary';
};

export {
  getColor,
  decideColorForScore,
};
