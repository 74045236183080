import axios from './axios';
import util from './util';

const createPremiumCheckoutSession = (priceId, { promo, successUrl, cancelUrl, referrer }) => {
  return axios
    .post('/checkout/session', {
      plan: priceId,
      promo,
      success_url: successUrl,
      cancel_url: cancelUrl,
      referrer,
    })
    .then(util.throwOrReturn)
}

const updatePremiumCheckoutSession = ({ successUrl, cancelUrl }) => {
  return axios
    .post('/checkout/session:update', {
      success_url: successUrl,
      cancel_url: cancelUrl,
    })
    .then(util.throwOrReturn)
}

const fetchCheckoutSession = (sessionId) => {
  return axios
    .get(`/checkout/session/${sessionId}`)
    .then(util.throwOrReturn);
}

export default {
  createPremiumCheckoutSession,
  updatePremiumCheckoutSession,
  fetchCheckoutSession,
}
