import Vue from 'vue';
import moment from 'moment-timezone';
import VueMoment from 'vue-moment';

/**
 * Date library momentjs
 * https://momentjs.com/
 */
// Vue.prototype.$moment = moment;
Vue.use(VueMoment, { moment });
