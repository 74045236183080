import Vue from 'vue';
import Router from 'vue-router';
import sha1 from 'js-sha1';

// Store
import store from '../store';

// Flags
import flags from '../flags';

// Routes
import AuthRoutes from './auth.routes';
import PagesRoutes from './pages.routes';
import UsersRoutes from './users.routes';
import PortfolioRoutes from './portfolio.routes';
import SecuritiesRoutes from './securities.routes';
import ToolsRoutes from './tools.routes';
import ResearchRoutes from './research.routes';
import MarketRoutes from './market.routes';
import MarketPlaceRoutes from './marketplace.routes';

Vue.use(Router);

export const routes = [
  ...AuthRoutes,
  ...UsersRoutes,
  ...PortfolioRoutes,
  ...SecuritiesRoutes,
  ...ToolsRoutes,
  ...ResearchRoutes,
  ...MarketRoutes,
  ...MarketPlaceRoutes,
  {
    path: '/subscription/success',
    name: 'subscription.success',
    component: () => import('@/pages/Subscription/SubscribedPage.vue'),
    meta: {
      requiresAuth: true,
      layout: 'simple',
    },
  },
  ...PagesRoutes,
  {
    path: '*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "utility" */ '@/pages/error/NotFoundPage.vue'),
    meta: {
      layout: 'error',
    },
  },
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes,
});

const initStore = store.dispatch('initStore');

/**
 * Before each route update
 */
router.beforeEach(async (to, from, next) => {
  await store.restored;
  await initStore;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // This route requires the user to be logged in.
    // Checks if logged in and, if not, redirects to login page.
    if (!store.getters['auth/isLoggedIn']) {
      return next({ name: 'auth.login', query: { next: to.path } });
    }

    // Otherwise, proceeds to destination.
    return next();
  }

  // Route does not require auth.
  return next();
});

// let variable = null
// let underMaintenance = null;
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.name === 'utility.maintenance')) {
    return next();
  }

  // if (variable === null) {
  //   variable = await flags.variable('under-maintenance', false);
  //   underMaintenance = variable.value;
  //   variable.onUpdate((value) => {
  //     underMaintenance = value;
  //   });
  // }

  // if (underMaintenance === true) {
  //   return next({ name: 'utility.maintenance' });
  // }

  return next();
});

/**
 * After each route update
 */
// router.afterEach((to, from) => {
// });

export default router;
