var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "mx-auto",
    attrs: {
      "cols": "12",
      "md": "8",
      "xl": "6"
    }
  }, [_c('plans')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }