import en from '@/translations/en';

const supported = ['en'];
let locale = 'en';

try {
  // Gets browser default language.
  const { 0: browserLang } = navigator.language.split('-');

  if (supported.includes(browserLang)) locale = browserLang;
} catch (e) {
  locale = 'en';
}

export default {
  // current locale
  locale,

  // when translation is not available fallback to that locale
  fallbackLocale: 'en',

  // availabled locales for user selection
  availableLocales: [
    {
      code: 'en',
      flag: 'us',
      label: 'English',
      messages: en,
    }],
};
