export default [
  {
    path: '/marketplace/:slug',
    name: 'marketplace.category',
    component: () => import('@/pages/MarketPlace/CategoryPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];
