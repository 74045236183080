export default {
    getEquityScreener: (state) => {
      return state.equities;
    },
    hasEquityScreener: (state) => {
      return state.equities.predefinedScreener !== null || state.equities.userScreener !== null;
    },
    getEtfScreener: (state) => {
      return state.etfs;
    },
  };