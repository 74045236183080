var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column"
  }, [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }