import axios from 'axios';

export const apiInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  //withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  validateStatus: null,
});

export const realtimeInstance = axios.create({
  baseURL: process.env.VUE_APP_REALTIME_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  validateStatus: null,
});

export const useRequestInterceptor = (onFulfilled, onRejected) => {
  apiInstance.interceptors.request.use(onFulfilled, onRejected);
  realtimeInstance.interceptors.request.use(onFulfilled, onRejected);
}

export const useResponseInterceptor = (onFulfilled, onRejected) => {
  apiInstance.interceptors.response.use(onFulfilled, onRejected);
  realtimeInstance.interceptors.response.use(onFulfilled, onRejected);
}

if (process.env.VUE_APP_CF_CLIENT_ID) {
  apiInstance.defaults.headers['CF-Access-Client-Id'] = process.env.VUE_APP_CF_CLIENT_ID;
  apiInstance.defaults.headers['CF-Access-Client-Secret'] = process.env.VUE_APP_CF_CLIENT_SECRET;
  realtimeInstance.defaults.headers['CF-Access-Client-Id'] = process.env.VUE_APP_CF_CLIENT_ID;
  realtimeInstance.defaults.headers['CF-Access-Client-Secret'] = process.env.VUE_APP_CF_CLIENT_SECRET;
}


export default apiInstance;

