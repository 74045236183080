import axios from '@ziggma/api-client/lib/axios';
import util from '@ziggma/api-client/lib/util';

const getTopIndustries = (count = 10) => {
  return axios
    .get(`/v1/research/top-industries`, {
      params: {
        count,
      },
    })
    .then(util.throwOrReturn);
};

const listModelPortfolios = () => {
  return axios
    .get('/v1/research/model-portfolios')
    .then(util.throwOrReturn);
}

const listGuruPortfolios = () => {
  return axios
    .get('/v1/research/guru-portfolios')
    .then(util.throwOrReturn);
}

const getDailyReport = (count = 10) => {
  return axios
    .get('/v1/research/daily/reports', {
      params: {
        count,
      },
    })
    .then(util.throwOrReturn);
}

export default {
  getTopIndustries,
  listModelPortfolios,
  listGuruPortfolios,
  getDailyReport,
};
