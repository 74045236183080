<template>
  <v-app>
    <!-- Layout component -->
    <component :is="currentLayout" v-if="isRouterLoaded && !refreshing && !askForVerification">
      <transition mode="out-in" name="fade">
        <router-view />
      </transition>
    </component>

    <simple-layout v-else-if="askForVerification">
      <email-verification-page />
    </simple-layout>

    <v-snackbar
      :value="toast.show" :color="toast.color" :timeout="toast.timeout"
      bottom
      @input="snackbarInput">
      {{ toast.message }}
      <v-btn
        v-if="toast.timeout === 0" color="white" text
        @click="$store.commit('app/hideToast')">
        {{ $t('common.close') }}
      </v-btn>
    </v-snackbar>

    <v-overlay v-if="loading || refreshing" :value="loading" :style="{ zIndex: 10 }">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';

import ZiggmaRealtime from '@ziggma/realtime';
import config from './configs';

// Layouts
import defaultLayout from './layouts/DefaultLayout';
import simpleLayout from './layouts/SimpleLayout';
import landingLayout from './layouts/LandingLayout';
import authLayout from './layouts/AuthLayout';
import errorLayout from './layouts/ErrorLayout';

// Pages
import EmailVerificationPage from '@/pages/Email/EmailVerificationPage.vue';

/*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
|
| In charge of choosing the layout according to the router metadata
|
*/
export default {
  components: {
    EmailVerificationPage,
    defaultLayout,
    simpleLayout,
    landingLayout,
    authLayout,
    errorLayout,
  },
  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href, i) => ({ rel: 'stylesheet', href, id: `icons-${i}`, undo: false })),
    ],
  },
  computed: {
    ...mapState('app', ['toast', 'loading']),
    ...mapState('auth', ['token']),
    ...mapState('user', ['user', 'refreshing']),
    isRouterLoaded() {
      return this.$route.name !== null;
    },
    routeAllowsUnverified() {
      return this.$route.meta.allowsUnverified || false;
    },
    currentLayout() {
      const layout = this.$route.meta.layout || 'default';
      return layout + 'Layout';
    },
    askForVerification() {
      return !this.routeAllowsUnverified && this.user && !this.user.email_verified;
    },
  },
  watch: {
    // user: {
    //   immediate: true,
    //   handler(user) {
    //     this.$flags.initialize(user);
    //     this.$gtag.config({
    //       user_id: user?.id,
    //       user_properties: {
    //         is_premium: user?.is_premium,
    //       },
    //     });
    //     this.$gtag.set({
    //       user_id: user?.id,
    //       user_properties: {
    //         is_premium: user?.is_premium,
    //       },
    //     });
    //   },
    // },
    token: {
      immediate: true,
      async handler(token) {
        // if (token) {
        //   const variable = await this.$flags.variable('realtime-prices', false);
        //   const enable = variable.value;
        //   if (!enable) {
        //     return;
        //   }
        //   ZiggmaRealtime.connect(token);
        //   ZiggmaRealtime.onQuote((quote) => {
        //     this.$store.dispatch('securities/updateSecurityPrice', quote);
        //     if (this.$route.name === 'portfolio') {
        //       const { ticker } = quote;
        //       this.$store.dispatch('portfolio/updatePortfolioValue', { ticker }, { root: true });
        //     }
        //   });
        // } else {
        //   ZiggmaRealtime.destroy();
        // }
      },
    },
  },
  created() {
    this.$store.dispatch('user/refreshUser');
  },
  methods: {
    snackbarInput(v) {
      if (!v) {
        this.$store.commit('app/hideToast');
      }
    },
  },
};
</script>

<style scoped>
/**
 * Transition animation between pages
 */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
