import axios from './axios';
import util from './util';

const getStrengthsAndWeaknesses = (ticker) => {
  return axios
    .get(`/v1/stocks/${ticker}/strengths-weaknesses`)
    .then(util.throwOrReturn);
};

const getDetails = (ticker) => {
  return axios
    .get(`/v1/stocks/${ticker}/view`)
    .then(util.throwOrReturn);
};

const getAnnualData = (ticker) => {
  return axios
    .get(`/v1/stocks/${ticker}/annual-data`)
    .then(util.throwOrReturn);
};

const getScores = (stock, { startDate, endDate }) => {
  return axios
    .get(`/v1/stocks/${stock}/scores`, {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    })
    .then(util.throwOrReturn);
}

const getPrices = (stock) => {
  return axios
    .get(`/v1/stocks/${stock}/prices`)
    .then(util.throwOrReturn);
};

const getQuarterlyData = (ticker) => {
  return axios
    .get(`/v1/stocks/${ticker}/qtr-data`)
    .then(util.throwOrReturn);
};

const getEstimateData = (ticker, startDate, endDate) => {
  return axios
    .get(`/v1/stocks/${ticker}/forwdata`, {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    })
    .then(util.throwOrReturn);
};

const getPERatios = (ticker, startDate, endDate) => {
  return axios
    .get(`/v1/stocks/${ticker}/pe-ratios`, {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    })
    .then(util.throwOrReturn);
};

const getLineChartData = (ticker, tagName) => {
  return axios
    .get(`/v1/stocks/${ticker}/chart-data?tagName=${tagName}`)
    .then(util.throwOrReturn);
};

const getStockPeers = (ticker) => {
  return axios
    .get(`/v1/stocks/${ticker}/peers`)
    .then(util.throwOrReturn);
};

const getPriceHistories = (ticker, startDate, endDate) => {
  return axios
    .get(`/v1/stocks/${ticker}/price-history`, {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    })
    .then(util.throwOrReturn);
};

const getDailyMetrics = (ticker, startDate, endDate) => {
  return axios
    .get(`/v1/stocks/${ticker}/daily-metrics`, {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    })
    .then(util.throwOrReturn);
};

const getTopScorers = (ticker) => {
  return axios
    .get(`/v1/stocks/${ticker}/top-scorers`)
    .then(util.throwOrReturn);
};

const getClimateScoreHistory = (ticker) => {
  return axios
    .get(`/v1/stocks/${ticker}/climate-history`)
    .then(util.throwOrReturn);
};

export default {
  getStrengthsAndWeaknesses,
  getScores,
  getPrices,
  getDetails,
  getAnnualData,
  getQTRData: getQuarterlyData,
  getEstimateData,
  getPERatios,
  getLineChartData,
  getStockPeers,
  getPriceHistories,
  getTopScorers,
  getClimateScoreHistory,
  getDailyMetrics,
};
