var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.menuItem.isComingSoon ? _c('v-list-item', {
    staticClass: "grey--text",
    attrs: {
      "input-value": _vm.menuItem.value,
      "exact": _vm.menuItem.exact,
      "disabled": _vm.menuItem.disabled,
      "link": ""
    }
  }, [_c('tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-list-item-icon', _vm._g(_vm._b({}, 'v-list-item-icon', attrs, false), on), [_c('v-icon', {
          class: {
            'grey--text': _vm.menuItem.disabled
          },
          attrs: {
            "small": _vm.small,
            "color": "#bfbfbf"
          }
        }, [_vm._v(" " + _vm._s(_vm.menuItem.icon || 'mdi-circle-medium') + " ")])], 1), _c('v-list-item-content', _vm._g(_vm._b({}, 'v-list-item-content', attrs, false), on), [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.menuItem.key ? _vm.$t(_vm.menuItem.key) : _vm.menuItem.text) + " ")])], 1)];
      }
    }], null, false, 1432347622)
  }, [_c('span', {
    staticClass: "font-weight-bold font-italic grey--text"
  }, [_vm._v("Coming Soon...")])])], 1) : !_vm.menuItem.items ? _c('v-list-item', {
    attrs: {
      "input-value": _vm.menuItem.value,
      "to": {
        name: _vm.menuItem.link,
        params: {
          slug: _vm.menuItem.param
        }
      },
      "exact": _vm.menuItem.exact,
      "disabled": _vm.menuItem.disabled,
      "active-class": "primary--text",
      "link": ""
    }
  }, [_c('v-list-item-icon', [_c('v-icon', {
    class: {
      'grey--text': _vm.menuItem.disabled
    },
    attrs: {
      "small": _vm.small
    }
  }, [_vm._v(" " + _vm._s(_vm.menuItem.icon || 'mdi-circle-medium') + " ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.menuItem.key ? _vm.$t(_vm.menuItem.key) : _vm.menuItem.text) + " ")])], 1)], 1) : _c('v-list-group', {
    attrs: {
      "value": _vm.menuItem.regex ? _vm.menuItem.regex.test(_vm.$route.path) : false,
      "disabled": _vm.menuItem.disabled,
      "sub-group": _vm.subgroup,
      "to": {
        name: _vm.menuItem.link
      },
      "link": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn() {
        return [!_vm.subgroup ? _c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "small": _vm.small
          }
        }, [_vm._v(_vm._s(_vm.menuItem.icon || 'mdi-circle-medium'))])], 1) : _vm._e(), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.menuItem.key ? _vm.$t(_vm.menuItem.key) : _vm.menuItem.text) + " ")])], 1)];
      },
      proxy: true
    }])
  }, [_vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }