export default [
  {
    path: '/auth/login',
    name: 'auth.login',
    component: () => import(/* webpackChunkName: "auth" */ '@/pages/Auth/SigninPage.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/auth/:provider/callback',
    name: 'auth.provider-login',
    component: () => import(/* webpackChunkName: "auth" */ '@/pages/Auth/SigninPage.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/auth/register',
    name: 'auth.register',
    component: () => import(/* webpackChunkName: "auth" */ '@/pages/Auth/SignupPage.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/auth/verify/:id/:hash',
    name: 'auth.verify-email',
    component: () => import(/* webpackChunkName: "auth" */ '@/pages/Auth/VerifyEmailPage.vue'),
    meta: {
      layout: 'auth',
      allowsUnverified: true,
    },
  },
  {
    path: '/auth/verify',
    name: 'auth.requiresVerification',
    component: () => import(/* webpackChunkName: "auth" */ '@/pages/Email/EmailVerificationPage.vue'),
    meta: {
      layout: 'simple',
    },
  },
  {
    path: '/auth/forgot-password',
    name: 'auth.forgot',
    component: () => import(/* webpackChunkName: "auth" */ '@/pages/Auth/ForgotPage.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/auth/reset-password',
    name: 'auth.reset',
    component: () => import(/* webpackChunkName: "auth" */ '@/pages/Auth/ResetPage.vue'),
    meta: {
      layout: 'auth',
    },
  },
  // {
  //   path: '/auth/register-success',
  //   name: 'auth.register-success',
  //   component: () => import(/* webpackChunkName: "auth" */ '@/pages/Auth/SignupSuccessPage.vue'),
  //   meta: {
  //     layout: 'simple',
  //   },
  // },
  // legacy redirects
  {
    path: '/user/login',
    name: 'legacy.user.login',
    redirect: '/auth/login',
  },
  {
    path: '/register',
    name: 'legacy.user.register',
    redirect: '/auth/register',
  },
];
