import Vue from 'vue';
import VueGtag from 'vue-gtag';

import config from '@/configs';
import router from '@/router';

const { gaId } = config.analytics;

if (gaId) {
  Vue.use(VueGtag, {
    config: { id: gaId },
    appName: 'Anova Analytics',
    pageTrackerEnabled: true,
    pageTrackerScreenviewEnabled: true,
    pageTrackerExcludedRoutes: [
      'auth.provider-login',
      'auth.verify-email',
    ],
  }, router);
}
