export default [
  {
    path: '/research/screener',
    name: 'research.screener',
    component: () => import(/* webpackChunkName: "research" */ '@/pages/Research/Screener/ScreenerPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: '/research/top-fifty',
  //   name: 'research.top-fifty',
  //   component: () => import(/* webpackChunkName: "research" */ '@/pages/Research/TopFiftyPage.vue'),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: '/research/guru-portfolios',
  //   name: 'research.portfolios.guru',
  //   component: () => import(/* webpackChunkName: "research" */ '@/pages/Research/ModelPortfolioDashboard.vue'),
  //   props: { type: 'guru' },
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: '/research/model-portfolios',
  //   name: 'research.portfolios.model',
  //   component: () => import(/* webpackChunkName: "research" */ '@/pages/Research/ModelPortfolioDashboard.vue'),
  //   props: { type: 'model' },
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
];
