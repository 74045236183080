var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "value": _vm.value,
      "persistent": _vm.loading,
      "width": "500"
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event);
      }
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_vm._v(" Create a new " + _vm._s(_vm.isCreateWatchlist ? 'watchlist' : 'portfolio') + " ")]), _c('v-card-text', [_c('v-form', {
    ref: "createForm",
    attrs: {
      "disabled": _vm.loading,
      "lazy-validation": ""
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Name",
      "rules": _vm.nameRules,
      "clearable": "",
      "counter": "50",
      "dense": "",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.newPortfolio.name,
      callback: function callback($$v) {
        _vm.$set(_vm.newPortfolio, "name", $$v);
      },
      expression: "newPortfolio.name"
    }
  }), !_vm.isCreateWatchlist ? _c('v-text-field', {
    attrs: {
      "label": "Cash",
      "type": "number",
      "rules": _vm.cashRules,
      "dense": "",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.newPortfolio.cash,
      callback: function callback($$v) {
        _vm.$set(_vm.newPortfolio, "cash", $$v);
      },
      expression: "newPortfolio.cash"
    }
  }) : _vm._e()], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "disabled": _vm.loading,
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v(" Close ")]), _c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "color": "primary"
    },
    on: {
      "click": _vm.createPortfolio
    }
  }, [_vm._v(" Create ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }