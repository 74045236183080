var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-inline-flex align-center",
    class: [_vm.colorClass ? _vm.colorClass : '', 'text-center']
  }, [_vm.showArrow && _vm.arrow && _vm.left ? _c('v-icon', {
    class: "".concat(!_vm.dense ? 'mr-1' : ''),
    attrs: {
      "color": _vm.color,
      "dense": "",
      "small": ""
    },
    domProps: {
      "textContent": _vm._s(_vm.arrow)
    }
  }) : _vm._e(), _vm.showSign && _vm.sign ? _c('span', [_vm._v(_vm._s(_vm.sign))]) : _vm._e(), _vm.currency ? _c('span', [_vm._v(" " + _vm._s(_vm._f("formatCurrency")(_vm.value, null, _vm._precision)) + " ")]) : _vm.percentage ? _c('span', [_vm._v(" " + _vm._s(_vm._f("formatPercentage")(_vm.value, _vm._precision)) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm._f("formatNumber")(_vm.value, _vm._precision)) + " ")]), _vm.showArrow && _vm.arrow && !_vm.left ? _c('v-icon', {
    class: "".concat(!_vm.dense ? 'ml-1' : ''),
    attrs: {
      "color": _vm.color,
      "dense": "",
      "small": !_vm.largeArrow,
      "large": _vm.largeArrow
    },
    domProps: {
      "textContent": _vm._s(_vm.arrow)
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }