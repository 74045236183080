export default [
  // {
  //   path: '/market/overview',
  //   name: 'market.overview',
  //   component: () => import(/* webpackChunkName: "market" */ '@/pages/Market/MarketOverviewPage.vue'),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: '/market/news',
  //   name: 'market.news',
  //   component: () => import(/* webpackChunkName: "market" */ '@/pages/Market/NewsPage.vue'),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: '/market/reports',
    name: 'market.reports',
    component: () => import(/* webpackChunkName: "market" */ '@/pages/Market/MarketReportPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];
