import axios from './axios';
import util from './util';

const listIndices = (params = null) => {
  return axios
    .get('/v1/indices', {
      params: params || {},
    })
    .then(util.throwOrReturn);
};

const getIndex = (indexId) => {
  return axios
    .get(`/v1/indices/${indexId}`)
    .then(util.throwOrReturn);
};

export default {
  listIndices,
  getIndex,
};
