import axios from './axios'
import util from './util'

const getEtf = (ticker) => {
    return axios.get(`/v1/etfs/${ticker}`)
        .then(util.throwOrReturn)
        .catch(util.throwOrReturn)
}

const getPriceHistory = (ticker, startDate, endDate) => {
    return axios.get(`/v1/etfs/${ticker}/prices`, {
        params: {
            start_date: startDate,
            end_date: endDate,
        },
    })
    .then(util.throwOrReturn)
    .catch(util.throwOrReturn)
}

export default {
    getEtf,
    getPriceHistory,
}