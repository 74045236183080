import moment from 'moment';
import { PortfolioBuilder } from '@ziggma/models/portfolio';

export default {
  hydrate: (state) => {
    // if (state.lastLoadedAt) {
    //   state.lastLoadedAt = moment(state.lastLoadedAt);
    // }
    state.portfolios = Object.values(state.portfolios)
      .reduce((acc, item) => {
        const builder = new PortfolioBuilder();
        const portfolio = builder.withData(item).build();
        return {
          ...acc,
          [portfolio.id]: portfolio,
        };
      }, {});
    state.loadingPortfolios = false;
  },
  reset: (state) => {
    state.portfolios = {};
    state.loadingPortfolios = false;
    state.lastLoadedAt = null;
    state.selectedPortfolioId = null;
  },

  setLoadingPortfolios: (state, loading) => {
    state.loadingPortfolios = loading;
  },
  setPortfolios: (state, portfolios) => {
    if (!portfolios) {
      state.portfolios = {}
      state.lastLoadedAt = moment().toISOString();
      return;
    }

    if (Array.isArray(portfolios)) {
      portfolios = portfolios.reduce((acc, item) => {
        return {
          ...acc,
          [item.id]: item,
        };
      }, {});
    }

    state.portfolios = portfolios;
    state.lastLoadedAt = moment().toISOString();
  },
  setPortfolio: (state, portfolio) => {
    state.portfolios = {
      ...state.portfolios,
      [portfolio.id]: portfolio,
    };
  },
  removePortfolio: (state, id) => {
    if (id in state.portfolios) {
      const portfolios = {
        ...state.portfolios,
      };
      delete portfolios[id];
      state.portfolios = portfolios;
    }
  },
  setSelectedPortfolioId: (state, id) => {
    state.selectedPortfolioId = id;
  },

  setPortfolioHoldingsLoading: (state, { id, loading }) => {
    state.portfolios[id].isLoadingHoldings = loading;
  },
  setPortfolioHoldings: (state, { id, holdings }) => {
    state.portfolios[id].holdings = holdings;
  },

  updatePortfolioHolding: (state, { id, ticker, latest }) => {
    const portfolio = state.portfolios[id];
    const holdings = portfolio._holdings;
    const holding = holdings.find((h) => h.tickerSymbol === ticker);
    const delta = holding.updateSecurityPrice(latest);
    portfolio.value += delta;
    portfolio._value_as_of = latest.timestamp;
    holding.weight = holding.value / portfolio.value;
  },
};
