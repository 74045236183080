import Vue from 'vue';

Vue.filter('formatPercentage', (value, precision = 3, raw = true) => {
  if (value !== undefined && value !== null && !isNaN(value)) {
    if (raw) {
      return `${(value * 100).toFixed(precision)}%`;
    } else {
      return `${(value).toFixed(precision)}%`;
    }
  }

  return '—';
});
