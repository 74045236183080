import moment from 'moment';

import { configureSentryScope } from '@/sentry';

export default {
  hydrate: (state) => {
    // if (state.user) {
    //   state.user.created_at = moment(state.user.created_at);
    //   state.user.last_login = moment(state.user.last_login);
    // }
  },

  initiateRefresh: (state) => {
    state.refreshing = true;
  },

  // Users
  setUser: (state, user) => {
    state.user = user;
    state.refreshing = false;
    configureSentryScope(state.user);
  },
  setUserSettings: (state, settings) => {
    state.user = {
      ...state.user,
      settings,
    }
  },

  // Stock alerts
  setStockAlerts: (state, alerts) => {
    if (!alerts) {
      return;
    }

    state.stockAlerts = alerts;
    state.stockAlertsState = 'LOADED';
    state.stockAlertsLastLoadedAt = moment().toISOString(true);
  },
  clearStockAlerts: (state) => {
    state.stockAlerts = [];
    state.stockAlertsState = 'NOT_LOADED';
    state.stockAlertsLastLoadedAt = null;
  },
  setLoadingStockAlerts: (state) => {
    state.stockAlertsState = 'LOADING';
  },
  setStockAlertsError: (state, error) => {
    state.stockAlertsState = 'ERRORED';
    state.stockAlertsError = error;
  },

  // Portfolio alerts
  setPortfolioAlerts: (state, alerts) => {
    if (!alerts) {
      return;
    }

    state.portfolioAlerts = alerts;
    state.portfolioAlertsState = 'LOADED';
    state.portfolioAlertsLastLoadedAt = moment().toISOString(true);
  },
  clearPortfolioAlerts: (state) => {
    state.portfolioAlerts = [];
    state.portfolioAlertsState = 'NOT_LOADED';
    state.portfolioAlertsLastLoadedAt = null;
  },
  setLoadingPortfolioAlerts: (state) => {
    state.portfolioAlertsState = 'LOADING';
  },
  setPortfolioAlertsError: (state, error) => {
    state.portfolioAlertsState = 'ERRORED';
    state.portfolioAlertsError = error;
  },
}
