export default {
  common: {
    add: 'Add',
    cancel: 'Cancel',
    description: 'Description',
    delete: 'Delete',
    title: 'Title',
    save: 'Save',
    faq: 'Frequently Asked Questions',
    contact: 'Contact Us',
    tos: 'Terms of Service',
    policy: 'Privacy Policy',
  },
  app: {
    darkMode: 'Dark Mode',
  },
  auth: {
    login: {
      title: 'Login',
      email: 'Email',
      password: 'Password',
      button: 'Login',
      orSignInWith: 'Or',
      forgot: 'Forgot password?',
      noAccount: 'Don\'t have an account?',
      create: 'Sign up here.',
      error: 'These credentials do not match our records.',
    },
    register: {
      title: 'Create Account',
      subtitle: 'Anova Analytics Inc. | Investing Made Easy',
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      password: 'Password',
      passwordConfirm: 'Confirm password',
      button: 'Create Account',
      orSignUpWith: 'Or sign up with',
      agree: 'By signing up, you agree to the',
      account: 'Already have an account?',
      signIn: 'Sign in.',
    },
    forgot: {
      title: 'Forgot Password?',
      subtitle: 'Enter your account email address and we will send you a link to reset your password.',
      email: 'Email',
      button: 'Request Password Reset',
      backToSignIn: 'Back to Sign in',
    },

    profile: 'Profile',
    logout: 'Sign Out',
    check: {
      title: 'Set New Password',
      goBack: 'Back to Login',
      newpassword: 'New Password',
      button: 'Set new password and Login',
      error: 'The action link is invalid',
      verifylink: 'Verifying link...',
      verifyemail: 'Verifying email address...',
      emailverified: 'Email verified! Redirecting...',
    },
  },
  faq: {
    call: 'Have other questions? Please reach out.',
  },
  utility: {
    maintenance: 'In Maintenance',
  },
  menu: {
    search: 'Search securities and companies...',
    profile: 'Account',
    logout: 'Logout',
    // Are we using the below?
    portfolio: 'Portfolio',
    news: 'News',
    pages: 'Pages',
    others: 'Others',
    email: 'Email',
    users: 'Users',
    usersList: 'List',
    usersEdit: 'Edit',
    auth: 'Auth Pages',
    authLogin: 'Signin / Login',
    authRegister: 'Signup / Register',
    authVerify: 'Verify Email',
    authForgot: 'Forgot Password',
    authReset: 'Reset Password',
    errorPages: 'Error Pages',
    errorNotFound: 'Not Found / 404',
    errorUnexpected: 'Unexpected / 500',
    utilityPages: 'Utility Pages',
    utilityMaintenance: 'Maintenance',
    utilitySoon: 'Coming Soon',
    utilityHelp: 'FAQs / Help',
    levels: 'Menu Levels',
    disabled: 'Menu Disabled',
    faq: 'Help',
    feedback: 'Feedback',
    support: 'Support',
    landingPage: 'Landing Page',
    marketOverview: 'Reports',
    topFifty: 'Top 50 Stock List',
    screener: 'Screener',
    guruPortfolios: 'Guru Portfolios',
    modelPortfolios: 'Model Portfolios',
    portfolioCheckup: 'Portfolio Checkup',
    portfolioBuilder: 'Portfolio Builder',
    backTest: 'Back Test',
    simulator: 'Simulator',
    marketplace: 'Startup and VC',
    brokerCashBonus: 'Broker Cash Bonus',
    impactInvesting:'Impact Investing',
  },
  news: {
    no_more: 'You are all caught up!',
  },
  portfolios: {
    plaid: {},
    kpi_purchase_info: 'Shows the KPI value as of the purchase date. This allows you to monitor whether your portfolio companies keep progressing by comparing the current value of a KPI against the value as of the time of purchase. By clicking on the edit icon, enter a position\'s purchase date to easily track your portfolio companies\' financial performance over time',
  },
  // TODO(chrys): remove below
  usermenu: {
    profile: 'Profile',
    signin: 'Login',
    dashboard: 'Dashboard',
    signout: 'Sign Out',
  },
  error: {
    notfound: 'Page Not Found',
    other: 'An Error Occurred',
  },
  check: {
    title: 'Set New Password',
    backtosign: 'Back to Login',
    newpassword: 'New Password',
    button: 'Set new password and Login',
    error: 'The action link is invalid',
    verifylink: 'Verifying link...',
    verifyemail: 'Verifying email address...',
    emailverified: 'Email verified! Redirecting...',
  },
  // Vuetify components translations
  $vuetify: {
    badge: 'Badge',
    close: 'Close',
    dataIterator: {
      noResultsText: 'No matching records found',
      loadingText: 'Loading items...',
    },
    dataTable: {
      itemsPerPageText: 'Rows per page:',
      ariaLabel: {
        sortDescending: 'Sorted descending.',
        sortAscending: 'Sorted ascending.',
        sortNone: 'Not sorted.',
        activateNone: 'Activate to remove sorting.',
        activateDescending: 'Activate to sort descending.',
        activateAscending: 'Activate to sort ascending.',
      },
      sortBy: 'Sort by',
    },
    dataFooter: {
      itemsPerPageText: 'Items per page:',
      itemsPerPageAll: 'All',
      nextPage: 'Next page',
      prevPage: 'Previous page',
      firstPage: 'First page',
      lastPage: 'Last page',
      pageText: '{0}-{1} of {2}',
    },
    datePicker: {
      itemsSelected: '{0} selected',
      nextMonthAriaLabel: 'Next month',
      nextYearAriaLabel: 'Next year',
      prevMonthAriaLabel: 'Previous month',
      prevYearAriaLabel: 'Previous year',
    },
    noDataText: 'No data available',
    carousel: {
      prev: 'Previous visual',
      next: 'Next visual',
      ariaLabel: {
        delimiter: 'Carousel slide {0} of {1}',
      },
    },
    calendar: {
      moreEvents: '{0} more',
    },
    fileInput: {
      counter: '{0} files',
      counterSize: '{0} files ({1} in total)',
    },
    timePicker: {
      am: 'AM',
      pm: 'PM',
    },
    pagination: {
      ariaLabel: {
        wrapper: 'Pagination Navigation',
        next: 'Next page',
        previous: 'Previous page',
        page: 'Goto Page {0}',
        currentPage: 'Current Page, Page {0}',
      },
    },
  },
};
