export default {
  // main navigation - side menu
  menu: [
    {
      text: '',
      key: '',
      items: [
        {
          icon: 'mdi mdi-chart-pie',
          key: 'menu.portfolio',
          text: 'Portfolio',
          link: 'portfolio',
        },
      ],
    },
    {
      text: 'PORTFOLIO TOOLS',
      items: [
        {
          icon: 'mdi-hammer-wrench',
          key: 'menu.simulator',
          text: 'Simulator',
          link: 'tools.simulator',
          premium: true,
        },
        // {
        //   icon: 'mdi-stethoscope ',
        //   key: 'menu.portfolioCheckup',
        //   text: 'Portfolio Checkup',
        //   link: '/portfolio-checkup',
        // },
        // {
        //   icon: 'mdi-hammer-wrench',
        //   key: 'menu.portfolioBuilder',
        //   text: 'Portfolio builder',
        //   link: '/portfolio-builder',
        // },
        {
          icon: 'mdi mdi-recycle',
          key: 'menu.backTest',
          text: 'Back Test',
          link: 'tools.back-test',
          premium: true,
          isComingSoon: true,
        },
      ],
    },
    {
      text: 'RESEARCH',
      items: [
        {
          icon: 'mdi-filter-plus-outline ',
          key: 'menu.screener',
          text: 'Screener',
          link: 'research.screener',
        },
        {
          icon: 'mdi-playlist-star',
          key: 'menu.topFifty',
          text: 'Top 50 Stock List',
          link: 'research.top-fifty',
          premium: true,
          isComingSoon: true,
        },
        // {
        //   icon: 'mdi-account-star-outline ',
        //   key: 'menu.guruPortfolios',
        //   text: 'Guru portfolios',
        //   link: 'research.portfolios.guru',
        //   premium: true,
        // },
        // {
        //   icon: 'mdi-chart-arc ',
        //   key: 'menu.modelPortfolios',
        //   text: 'Model portfolios',
        //   link: 'research.portfolios.model',
        //   premium: true,
        // },
      ],
    },
    {
      text: 'MARKET',
      items: [
        {
          icon: 'mdi-view-dashboard-outline',
          key: 'menu.marketOverview',
          text: 'Reports',
          link: 'market.reports',
        },
        // {
        //   icon: 'mdi-newspaper-variant-outline',
        //   key: 'menu.news',
        //   text: 'News',
        //   link: 'market.news',
        // },
      ],
    },
    {
      text: 'OPPORTUNITIES',
      items: [
      ],
    },
  ],
  // footer links
  footer: [],
};
