export default [
  // Homepage
  {
    path: '/',
    name: 'home',
    redirect: '/portfolio',
  },
  {
    path: '/overview',
    name: 'legacy.portfolio-overview',
    redirect: '/portfolio',
  },
  // Portfolio dashboard
  {
    path: '/portfolio',
    name: 'portfolio',
    component: () => import(/* webpackChunkName: "portfolio" */ '@/pages/Portfolio/PortfolioDashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]
