export default {
    updateEquityPredefinedScreener: async ({ commit }, predefinedScreener ) => {
      await commit('setEquityScreener', { predefinedScreener, undefined });
    },
    updateEquityUserScreener: async ({ commit }, userScreener ) => {
      await commit('setEquityScreener', { undefined, userScreener });
    },
    updateEquityQuery: async ({ commit }, payload ) => {
      await commit('updateEquityQuery', payload);
    },
    resetEquityScreener: async ({ commit } ) => {
      await commit('resetEquityScreener');
    },
    updateEtfQuery: async ({ commit }, payload ) => {
      await commit('updateEtfQuery', payload);
    },
    resetEtfScreener: async ({ commit } ) => {
      await commit('resetEtfScreener');
    },
    updateBondQuery: async ({ commit }, payload ) => {
      await commit('updateBondQuery', payload);
    },
    resetBondScreener: async ({ commit } ) => {
      await commit('resetBondScreener');
    },
  };