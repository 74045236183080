import axios from './axios';
import util from './util';

const sectors = () => {
    return axios.get('/v1/market/sectors')
        .then(util.throwOrReturn)
        .catch(util.throwOrReturn)
}

const getWeekHighLowData = () => {
    return axios.get('/v1/market/week-data')
        .then(util.throwOrReturn)
        .catch(util.throwOrReturn)
}

const getTrailingDividends = (ticker) => {
    return axios.get(`/v1/market/${ticker}/dividends`)
        .then(util.throwOrReturn)
        .catch(util.throwOrReturn)
}

export default {
    sectors,
    getWeekHighLowData,
    getTrailingDividends,
}
