import _ from 'lodash';
import moment from 'moment';

export default {
  user: (state) => {
    if (!state.user || _.isEmpty(state.user)) {
      return null;
    }

    return {
      ...state.user,
      created_at: moment(state.user.created_at),
      last_login: moment(state.user.last_login),
    };
  },
  userInitials: (state) => {
    let initials = '';
    if (state.user.first_name) {
      initials += state.user.first_name[0];
    }
    if (state.user.last_name) {
      initials += state.user.last_name[0];
    }

    return _.upperCase(initials);
  },

  stockAlerts: (state) => state.stockAlerts,
  stockAlertsByTicker: (state) => {
    return _.groupBy(state.stockAlerts, 'stock');
  },

  portfolioAlerts: (state) => state.portfolioAlerts,
  portfolioAlertsByPortfolio: (state) => {
    return _.groupBy(state.portfolioAlerts, 'portfolio');
  },
};
