import Vue from 'vue';
import vuetify from '../plugins/vuetify';

Vue.filter('breakpointTruncate', (value, xsLen, smLen, mdLen, lgLen, xlLen) => {
  if (!value) {
    return 'N/A';
  }

  let length = xlLen || lgLen || mdLen || smLen || xsLen || 30;
  if (vuetify.framework.breakpoint.xlOnly) {
    length = xlLen || length;
  } else if (vuetify.framework.breakpoint.lgOnly) {
    length = lgLen || length;
  } else if (vuetify.framework.breakpoint.mdOnly) {
    length = mdLen || length;
  } else if (vuetify.framework.breakpoint.smOnly) {
    length = smLen || length;
  } else if (vuetify.framework.breakpoint.xsOnly) {
    length = xsLen || length;
  }

  return Vue._.truncate(value, { length });
});
