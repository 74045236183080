import axios from 'axios';

export class UnauthorizedError extends Error {
  constructor(details = null) {
    super('Unauthorized')
    this.name = 'UnauthorizedError'
    this.details = details
  }
}

export class NotFoundError extends Error {
  constructor(details = null) {
    super('NotFound')
    this.name = 'NotFoundError'
    this.details = details
  }
}

export class ClientError extends Error {
  constructor(message, details = null) {
    super(message || 'Something was wrong with your request.')
    this.name = 'ClientError'
    this.details = details
  }
}

export class BackendError extends Error {
  constructor(message, details = null) {
    super(message || 'Unexpected system error. Try again.')
    this.name = 'BackendError'
    this.details = details
  }
}

const ConcatMessages = (messages) => {
  if (!messages) {
    return 'Something went wrong.';
  }

  if (typeof messages === 'object') {
    return Object.values(messages).join(' ');
  }

  if (Array.isArray(messages)) {
    return messages.join(' ');
  }

  if (typeof messages === 'string') {
    return messages;
  }

  return 'Something went wrong.';
}

const throwIfError = (response) => {
  if (axios.isCancel(response)) {
    throw response;
  }

  if (response.status === 401 || response.status === 403) {
    throw new UnauthorizedError(response.data)
  }

  if (response.status === 404) {
    throw new NotFoundError(response.data);
  }

  if (!response.data) {
    return response;
  }

  if (response.status >= 200 && response.status < 300) {
    return;
  }

  // Joins multiple message with space.
  const { data } = response
  const message = data && data.messages && ConcatMessages(data.messages);

  if (response.status >= 500) {
    throw new BackendError(message, data)
  }

  if (response.status >= 400) {
    throw new ClientError(message, data)
  }
}

const throwOrReturn = (response) => {
  //console.debug('response', response)
  throwIfError(response)

  if (!response.data) {
    return response;
  }

  return response.data
}

export default {
  throwIfError,
  throwOrReturn,
}
