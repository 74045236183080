<template>
  <div v-if="isLoadingPlans" class="fill-height d-flex align-center justify-center pa-6">
    <v-progress-circular indeterminate color="primary" size="48" />
  </div>
  <div v-else-if="plans && plans.length > 0">
    <v-responsive class="mx-auto text-center">
      <h2 class="text-h3 text-lg-h2 pb-3">Plans &amp; Pricing</h2>
    </v-responsive>
    <v-row class="mt-2" dense>
      <v-col class="d-flex justify-center align-center text-uppercase">
        <!--        <v-switch v-model="annual" label="Annual Billing" hide-details class="pt-0" />-->
        <v-btn-toggle
          v-model="period" dense mandatory color="primary"
          background-color="transparent">
          <v-btn color="primary" outlined value="monthly">MONTHLY BILLING</v-btn>
          <v-btn color="primary" outlined value="annual">ANNUAL BILLING</v-btn>
          <v-chip label small color="success">-25%</v-chip>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row v-if="!isMobile" class="mx-auto justify-center">
      <v-col cols="12" class="d-flex justify-center">
        <div v-for="(plan, i) in plans" :key="i" class="mx-2">
          <plan-card
            :plan="plan" :prefer-annual="annual" width="380"
            @subscribe="subscribeTo" />
        </div>
      </v-col>
    </v-row>
    <v-row v-else class="mx-auto justify-center">
      <v-col
        v-for="(plan, i) in plans" :key="i" cols="12" md="6"
        lg="5" xl="4" class="d-flex justify-center">
        <plan-card
          :plan="plan" :prefer-annual="annual"
          @subscribe="subscribeTo" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import client from '@ziggma/api-client';
import PlanCard from '@/components/subscription/PlanCard';

export default {
  name: 'Plans',
  components: { PlanCard },
  props: {
    subscription: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    // annual: true,
    period: 'monthly',

    plans: [],
    isLoadingPlans: false,
    error: null,
  }),
  computed: {
    annual() { return this.period === 'annual'; },
    pricing() {
      return this.plans.reduce((acc, plan) => {
        return {
          ...acc,
          [plan.slug]: this.getPricing(plan, this.interval),
        }
      }, {});
    },
    interval() {
      return this.annual ? 'year' : 'month';
    },
    activeProduct() {
      if (!this.subscription) {
        return null;
      }

      return this.subscription.product;
    },
    activePrice() {
      if (!this.subscription) {
        return null;
      }

      return this.subscription.price;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile || !this.$vuetify.breakpoint.smAndUp;
    },
  },
  created() {
    return this.fetchPlans();
  },
  methods: {
    fetchPlans() {
      this.isLoadingPlans = true;
      return client.plans
        .listPlans()
        .then((res) => {
          this.plans = res.data;
        })
        .catch((err) => {
          this.error = err.message;
        })
        .finally(() => {
          this.isLoadingPlans = false;
        });
    },
    getPricing(plan, interval) {
      if (plan.free || !plan.prices) {
        return 0;
      }
      return plan.prices.find((p) => p.interval === interval);
    },
    getPromotedPrice(plan, interval) {
      const pricing = this.getPricing(plan, interval);
      let price = pricing.amount;
      if (this.hasPromo(plan)) {
        const promo = plan.promo;
        if (promo.percent_off) {
          price = price - promo.percent_off * price;
        } else {
          price = price - promo.amount_off;
        }
      }
      return price;
    },
    hasPromo(plan) {
      return !!plan.promo;
    },
    subscribeTo(price) {
      if (!price) {
        return;
      }

      return this.$store.dispatch('app/startLoading')
        .then(() => {
          const id = price.promotion ? price.promotion.id : price.id;
          const successRoute = this.$router.resolve({ name: 'subscription.success', query: { sessionId: '{CHECKOUT_SESSION_ID}' } });
          return client.checkout.createPremiumCheckoutSession(id, {
            successUrl: window.location.origin + successRoute.href,
            cancelUrl: window.location.href,
          });
        })
        .then((res) => {
          return res.data;
        })
        .then(async (session) => {
          await this.$store.dispatch('app/stopLoading');
          return this.$stripe.redirectToCheckout({
            sessionId: session.session_id,
          });
        })
        .catch((err) => {
          return this.$store.dispatch('app/showError', { error: err });
        })
        .finally(() => {
          this.$store.dispatch('app/stopLoading');
        });
    },
  },
};
</script>

<style scoped>

</style>
