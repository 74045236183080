const { io } = require('socket.io-client');

const extraHeaders = {};
if (process.env.VUE_APP_CF_CLIENT_ID) {
  extraHeaders['CF-Access-Client-Id'] = process.env.VUE_APP_CF_CLIENT_ID;
  extraHeaders['CF-Access-Client-Secret'] = process.env.VUE_APP_CF_CLIENT_SECRET;
}

export class ZiggmaRealtime {
  constructor() {
    this.socket = window.socket || null;
  }

  connect(token) {
    if (this.socket) {
      this.destroy();
    }

    // eslint-disable-next-line
    console.log('ZiggmaRealtime | trying to connect...');
    window.socket = io(process.env.VUE_APP_REALTIME_API_URL, {
      path: '/ws',
      auth: {
        token,
      },
      extraHeaders: {
        ...extraHeaders,
      },
    });
    this.socket = window.socket;

    this.socket.on('connect', () => {
      // eslint-disable-next-line
      console.log('ZiggmaRealtime | successfully connected!');
    });

    this.socket.on('connect_error', (err) => {
      // eslint-disable-next-line
      console.error(`ZiggmaRealtime | unable to connect (reason: ${err.message})`);
    });

    this.socket.on('disconnect', () => {
      // eslint-disable-next-line
      console.log('ZiggmaRealtime | disconnected!');
    });
  }

  onConnect(cb) {
    this._checkSocket();
    this.socket.on('connect', cb);
  }

  onQuote(cb) {
    this._checkSocket();
    this.socket.on('quote', cb);
  }

  join(...tickers) {
    //this._checkSocket();
    if (!this.socket) {
      return;
    }
    this.socket.emit('join', tickers);
  }

  leave(...tickers) {
    //this._checkSocket();
    if (!this.socket) {
      return;
    }
    this.socket.emit('leave', tickers);
  }

  leaveAll() {
    //this._checkSocket();
    if (!this.socket) {
      return;
    }
    this.socket.emit('leaveAll');
  }

  destroy() {
    //this._checkSocket();
    if (!this.socket) {
      return;
    }
    this.leaveAll();
    this.socket.disconnect();
    this.socket = null;
  }

  _checkSocket() {
    if (!this.socket) {
      throw new Error('socket not initialized');
    }
  }
}

export default new ZiggmaRealtime();
