var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": "",
      "flat": "",
      "width": _vm.width,
      "max-width": _vm.maxWidth
    }
  }, [_c('v-card-title', [_c('div', {
    staticClass: "d-flex",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('span', {
    staticClass: "text-h4 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.plan.name))]), !_vm.plan.free ? _c('span', [_c('v-chip', {
    staticClass: "text-uppercase mt-1 ml-2",
    attrs: {
      "small": "",
      "color": "primary"
    }
  }, [_vm._v("7-day free trial")])], 1) : _vm._e()])]), _c('v-card-text', {
    staticStyle: {
      "height": "70px"
    }
  }, [_vm.plan.free ? _c('div', {
    staticClass: "d-flex align-end"
  }, [_c('div', {
    staticClass: "text-h3 font-weight-bold text-number mb-5"
  }, [_vm._v("$0")])]) : _vm._e(), _vm.price && !_vm.annual ? _c('div', {
    staticClass: "d-flex align-end"
  }, [!_vm.price.promotion ? _c('div', {
    staticClass: "text-h3 font-weight-bold text-number"
  }, [_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.price.amount)))]) : _vm._e(), _vm.price.promotion ? _c('div', {
    staticClass: "text-h4 font-weight-bold text-number red--text text-decoration-line-through"
  }, [_vm._v(" " + _vm._s(_vm._f("formatCurrency")(_vm.price.amount)) + " ")]) : _vm._e(), _vm.price.promotion ? _c('div', {
    staticClass: "text-h3 font-weight-bold text-number"
  }, [_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.price.promotion.amount)))]) : _vm._e(), _vm.price.interval ? _c('div', {
    staticClass: "text-h6 font-weight-bold text-number"
  }, [_vm._v("/" + _vm._s(_vm._f("shortenInterval")(_vm.price.interval)))]) : _vm._e()]) : _vm._e(), _vm.price && _vm.annual ? _c('div', {
    staticClass: "d-flex align-end"
  }, [!_vm.price.promotion ? _c('div', {
    staticClass: "text-h3 font-weight-bold text-number"
  }, [_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.price.amount / 12)))]) : _vm._e(), _vm.price.promotion ? _c('div', {
    staticClass: "text-h4 font-weight-bold text-number red--text text-decoration-line-through"
  }, [_vm._v(" " + _vm._s(_vm._f("formatCurrency")(_vm.price.amount / 12)) + " ")]) : _vm._e(), _vm.price.promotion ? _c('div', {
    staticClass: "text-h3 font-weight-bold text-number"
  }, [_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.price.promotion.amount / 12)))]) : _vm._e(), _vm.price.interval ? _c('div', {
    staticClass: "text-h6 font-weight-bold text-number"
  }, [_vm._v("/" + _vm._s(_vm._f("shortenInterval")('month')))]) : _vm._e(), _c('div', {
    staticClass: "text-h6 font-weight-medium ml-1"
  }, [_vm._v("(billed annually)")])]) : _vm._e(), _vm.price && _vm.price.promotion ? _c('div', {
    staticClass: "d-flex align-content-center ml-1 mt-2"
  }, [_c('div', {
    staticClass: "pt-1"
  }, [_vm._v("Limited time only")]), _c('v-chip', {
    staticClass: "ml-2",
    attrs: {
      "label": "",
      "small": "",
      "pill": "",
      "color": "#4caf5073"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("formatPercentage")(_vm.promotionSavings, 0)) + " off ")])], 1) : _vm._e()]), _c('v-card-text', {
    staticClass: "pt-0 pb-0"
  }, [_c('v-sheet', {
    staticStyle: {
      "min-height": "330px"
    },
    attrs: {
      "color": "lighten-5"
    }
  }, [_vm.plan.preamble ? _c('span', {
    staticClass: "text-h6 font-weight-bold text-uppercase"
  }, [_vm._v(_vm._s(_vm.plan.preamble))]) : _vm._e(), _vm._l(_vm.plan.features, function (feature, j) {
    return _c('div', {
      key: j,
      staticClass: "d-flex align-center text-h6"
    }, [_c('div', {
      staticClass: "d-flex align-start"
    }, [_c('div', [_c('v-icon', {
      staticClass: "mr-2",
      attrs: {
        "color": "success"
      }
    }, [_vm._v("mdi-check")])], 1), _c('div', [_c('span', {
      staticClass: "font-weight-regular"
    }, [_vm._v(_vm._s(_vm._f("capitalize")(feature.text, true)))])])])]);
  })], 2)], 1), !_vm.plan.free || _vm.active ? _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "x-large": "",
      "block": "",
      "outlined": "",
      "disabled": _vm.active || _vm.disabled
    },
    on: {
      "click": _vm.onSubscribeClick
    }
  }, [_vm._v(" " + _vm._s(_vm.active ? 'Current Plan' : 'Subscribe') + " ")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }