import moment from 'moment';
import _ from 'lodash';
import { HoldingBuilder } from '@ziggma/models/holding';

export class Portfolio {
  constructor() {
    this.id = -1;
    this.name = null;
    this.info = null;
    this.type = null;
    this.availableCash = 0.0;
    this.numHoldings = 0;
    this.numTransactions = 0;
    this.value = 0;
    this._value_as_of = null;
    this._lastUpdatedAt = moment().toISOString();
    //this.isAggregate = false;
    this.account = null;

    this._holdings = [];
    this._holdingsLastLoadedAt = null;
    this._loadingHoldings = false;
  }

  get lastUpdatedAt() {
    return moment(this._lastUpdatedAt);
  }

  set lastUpdatedAt(v) {
    this._lastUpdatedAt = (v ? moment(v) : moment()).toISOString();
  }

  isBank() {
    return this.type === 'BANK';
  }

  isAggregate() {
    return this.type === 'AGGREGATE';
  }

  isVirtual() {
    return this.type === 'BUILDER' || this.type === 'IMPORT' || this.type === 'SAMPLE';
  }

  isSample() {
    return this.type === 'SAMPLE';
  }

  isWatchlist() {
    return this.type === 'WATCHLIST';
  }

  isEditable() {
    return !this.isBank() && !this.isAggregate();
  }

  get valueAsOf() {
    if (!this._value_as_of) {
      return null;
    }
    return moment(this._value_as_of);
  }

  set valueAsOf(v) {
    this._value_as_of = v;
  }

  get holdings() {
    return this._holdings;
  }

  set holdings(holdings) {
    this._holdings = holdings.map((h) => {
      return new HoldingBuilder().withData(h).build();
    });
    this._holdingsLastLoadedAt = moment().toISOString();
  }

  get isLoadingHoldings() {
    return this._loadingHoldings;
  }

  set isLoadingHoldings(v) {
    this._loadingHoldings = v;
  }

  get holdingsLastUpdatedAt() {
    if (!this._holdingsLastLoadedAt) {
      return null;
    }
    return moment(this._holdingsLastLoadedAt);
  }

  containsSecurity(security) {
    if (this.isLoadingHoldings || !this.holdingsLastUpdatedAt || !this.holdings) {
      return false;
    }

    if (typeof security === 'object') {
      security = security.ticker;
    }

    return this.holdings.find((holding) => holding.tickerSymbol === security) !== undefined;
  }

  toBuilder() {
    const builder = new PortfolioBuilder();
    builder.withData({
      ...this,
    });
    return builder;
  }
}

export class PortfolioBuilder {
  constructor() {
    this._portfolio = new Portfolio();
  }

  withData(data) {
    Object.keys(data).forEach((key) => {
      if (typeof this._portfolio[_.camelCase(key)] !== 'function') {
        this._portfolio[_.camelCase(key)] = data[key];
      }
    });

    return this;
  }

  availableCash(v) {
    this._portfolio.availableCash = v;
  }

  // holdings(arr) {
  //   this._portfolio._holdings_loader = async () => {
  //     return arr;
  //   };
  // }
  //
  // holdingsLoader(loader) {
  //   this._portfolio._holdings_loader = loader;
  // }

  build() {
    return this._portfolio;
  }
}

export default Portfolio;
