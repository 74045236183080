import axios from './axios';
import util from './util';

const search = (query, signal = undefined) => {
  return axios
    .get(`/v1/securities`, {
      params: {
        query,
      },
      signal,
    })
    .then(util.throwOrReturn);
}

const getPrice = (ticker, date) => {
  return axios
    .get(`/v1/securities/${ticker}/price`, {
      params: {
        date,
      },
    })
    .then(util.throwOrReturn);
}

const getPriceHistory = (ticker, { startDate, endDate } = undefined) => {
  return axios
    .get(`/v1/securities/${ticker}/prices`, {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    })
    .then(util.throwOrReturn);
}

const getFundamentalsOnDate = (ticker, date) => {
  return axios
    .get(`/v1/securities/${ticker}/fundamentals`, {
      params: {
        date,
      },
    })
    .then(util.throwOrReturn);
};

const getTechnicals = (ticker) => {
  return axios
    .get(`/v1/securities/${ticker}/technicals`)
    .then(util.throwOrReturn);
};

const getSecurity = (ticker) => {
  return axios
    .get(`/v1/securities/${ticker}`)
    .then(util.throwOrReturn)
    .catch(util.throwOrReturn)
}

export default {
  search,
  getPrice,
  getPriceHistory,
  getFundamentalsOnDate,
  getTechnicals,
  getSecurity,
};
