var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-combobox', {
    ref: "search",
    attrs: {
      "items": _vm.items,
      "item-text": "name",
      "item-value": "ticker",
      "loading": _vm.isSearching,
      "search-input": _vm.search,
      "return-object": "",
      "placeholder": _vm.placeholder ? _vm.placeholder : _vm.$t('menu.search'),
      "append-icon": _vm.appendIcon,
      "prepend-inner-icon": _vm.prependInnerIcon,
      "background-color": _vm.backgroundColor,
      "no-filter": "",
      "hide-details": "",
      "solo": _vm.solo,
      "autofocus": _vm.autofocus,
      "clearable": _vm.clearable,
      "outlined": _vm.outlined,
      "filled": _vm.filled,
      "flat": _vm.flat,
      "dense": _vm.dense
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.search = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.search = $event;
      },
      "blur": function blur($event) {
        return _vm.$emit('blur', $event);
      },
      "click:append": function clickAppend($event) {
        return _vm.$emit('click:append', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item,
            attrs = _ref.attrs,
            on = _ref.on;
        return [_c('v-list-item', _vm._g(_vm._b({
          attrs: {
            "link": "",
            "to": {
              name: 'securities.profile',
              params: {
                ticker: item.ticker
              }
            }
          }
        }, 'v-list-item', attrs, false), on), [_c('v-list-item-content', [_c('v-list-item-title', {
          staticClass: "d-flex justify-space-between align-center"
        }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm._f("truncate")(item.name, 50)))]), _c('span', {
          staticClass: "ml-1 text-subtitle-2 text--secondary"
        }, [_vm._v("(" + _vm._s(item.ticker) + ")")]), item.asset_type === 'ETF' ? _c('v-chip', {
          staticClass: "ml-1",
          attrs: {
            "color": "primary",
            "x-small": ""
          }
        }, [_vm._v("ETF")]) : _vm._e()], 1), _c('portfolio-popup', {
          attrs: {
            "security": item
          }
        })], 1)], 1)], 1)];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_vm.isSearching ? _c('div', {
          staticClass: "d-flex flex-grow-1 pa-1"
        }, [_c('v-progress-circular', {
          staticClass: "mr-1",
          attrs: {
            "color": "primary",
            "indeterminate": "",
            "size": "24"
          }
        }), _vm._v(" Searching for securities... ")], 1) : _vm.search && (!_vm.items || _vm.items.length === 0) ? _c('div', {
          staticClass: "d-flex flex-grow-1 pa-1"
        }, [_vm._v(" 0 securities found. ")]) : _vm._e()];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }