import moment from 'moment';

import client from '@ziggma/api-client';
import { PortfolioBuilder } from '@ziggma/models/portfolio';
import { HoldingBuilder } from '@ziggma/models/holding';
import ZiggmaRealtime from '@ziggma/realtime';

export default {
  fetchPortfolios: async ({ commit, dispatch, state, getters }, force = false) => {
    if (state.loadingPortfolios) {
      return getters.portfolios;
    }

    // TODO(chrys): or earlier based on number of times requested?
    if (!force && getters.lastLoadedAt && moment().diff(getters.lastLoadedAt, 'minutes') < 15) {
      return getters.portfolios;
    }

    await commit('setLoadingPortfolios', true);
    return client.portfolios
      .listPortfolios()
      .then((res) => {
        if (!res.data) {
          return [];
        }

        return res.data.map((portfolio) => {
          const builder = new PortfolioBuilder();
          return builder.withData(portfolio).build();
        });
      })
      .then((portfolios) => {
        return dispatch('setPortfolios', portfolios);
      })
      .catch((err) => {
        dispatch('app/showError', { message: err.message, error: err }, { root: true });
        throw err;
      })
      .finally(() => {
        commit('setLoadingPortfolios', false);
      });
  },
  fetchHoldings: async ({ commit, dispatch }, portfolio) => {
    await commit('setPortfolioHoldingsLoading', { id: portfolio.id, loading: true });
    return client.portfolios
      .listHoldings(portfolio.id)
      .then((res) => {
        const holdings = res.data.map((rawHolding) => {
          const builder = new HoldingBuilder();
          return builder.withData(rawHolding).build();
        });
        holdings.forEach((holding) => {
          if (holding.tickerSymbol && holding.security) {
            dispatch('securities/fetchLatestPrice', holding.tickerSymbol, { root: true });
            ZiggmaRealtime.join(holding.tickerSymbol);
          }
        })
        return commit('setPortfolioHoldings', { id: portfolio.id, holdings });
      })
      .finally(() => {
        return commit('setPortfolioHoldingsLoading', { id: portfolio.id, loading: false });
      });
  },

  updatePortfolioValue: async ({ commit, getters, rootGetters }, { ticker }) => {
    const portfolioIds = getters.tickerToPortfolioMap[ticker] || [];
    if (portfolioIds.length === 0) {
      return;
    }

    const quote = rootGetters['securities/latestPriceByTicker'][ticker];
    if (!quote) {
      return;
    }

    await Promise.all(portfolioIds.map(async (id) => {
      await commit('updatePortfolioHolding', { id, ticker, latest: quote });
    }));
  },

  setPortfolios: async ({ commit, state, dispatch, getters }, portfolios) => {
    await commit('setPortfolios', portfolios);
    if (state.selectedPortfolioId && !(state.selectedPortfolioId in state.portfolios)) {
      await dispatch('unselectPortfolio');
    }

    if (portfolios) {
      portfolios.forEach((portfolio) => {
        dispatch('fetchHoldings', portfolio);
      });
    }

    return getters.portfolios;
  },
  setPortfolio: async ({ commit, dispatch }, portfolio) => {
    if (!portfolio) {
      return null;
    }

    const builder = new PortfolioBuilder();
    builder.withData(portfolio);
    portfolio = builder.build();

    await commit('setPortfolio', portfolio);

    if (!portfolio.holdingsLastUpdatedAt) {
      dispatch('fetchHoldings', portfolio);
    }

    return portfolio;
  },
  updatePortfolio: async ({ commit, state, getters }, portfolio) => {
    if (!getters.hasPortfolio(portfolio.id)) {
      throw new Error('Cannot update non-existing portfolio.');
    }

    const updated = getters.getPortfolio(portfolio.id).toBuilder();
    updated.withData({
      ...portfolio,
    });

    await commit('setPortfolio', updated.build());
    return getters.getPortfolio(portfolio.id);
  },
  removePortfolio: async ({ commit, state, dispatch }, portfolio) => {
    if (state.selectedPortfolioId === portfolio.id) {
      await dispatch('unselectPortfolio');
    }
    await commit('removePortfolio', portfolio.id);
    await dispatch('autoSelectPortfolio');
  },
  autoSelectPortfolio: async ({ state, getters, commit, dispatch }) => {
    if (!state.selectedPortfolioId) {
      if (getters.bankPortfolios.length > 0) {
        await dispatch('selectPortfolio', getters.bankPortfolios[0]);
      } else if (getters.virtualPortfolios.length > 0) {
        await dispatch('selectPortfolio', getters.virtualPortfolios[0]);
      } else if (getters.watchlists.length > 0) {
        await dispatch('selectPortfolio', getters.watchlists[0]);
      } else {
        await dispatch('selectPortfolio', null);
      }
    }

    return getters.selectedPortfolio;
  },
  selectPortfolio: async ({ commit }, portfolio) => {
    await commit('setSelectedPortfolioId', portfolio ? portfolio.id : null);
    return portfolio;
  },
  unselectPortfolio: async ({ commit }) => {
    await commit('setSelectedPortfolioId', null);
  },
};
