<template>
  <v-form
    ref="form"
    v-model="formValid"
    :disabled="loading || postTradeLoading"
    lazy-validation
    @submit.prevent>
    <div v-if="portfolio && portfolio.isWatchlist()">
      <v-row dense>
        <v-col cols="12">
          <v-autocomplete
            v-model="selected"
            :disabled="portfolios.length === 1"
            :items="groupedPortfolios"
            :loading="loading"
            :readonly="readonly"
            dense
            hide-details
            item-text="name"
            item-value="id"
            label="Portfolio"
            outlined
            placeholder="Select a portfolio..." />
        </v-col>
      </v-row>
      <v-row :dense="dense" class="pb-1">
        <!-- Error -->
        <v-col v-if="error" cols="12">
          <v-alert
            border="left" class="ma-0" dense
            text type="error">
            {{ error }}
          </v-alert>
        </v-col>
        <v-col cols="8">
          <v-autocomplete
            v-if="!security"
            v-model="transactionSecurity"
            :hide-no-data="!search || search === '' || searching"
            :items="results"
            :loading="searching"
            :rules="rules.security"
            :search-input.sync="search"
            dense
            flat
            hide-details
            item-text="name"
            item-value="ticker"
            label="Security"
            no-data-text="No matching securities"
            no-filter
            outlined
            placeholder="Search securities..."
            prepend-inner-icon="mdi-magnify"
            return-object>
            <!-- Render securities in list -->
            <template #item="{ item }">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }} ({{ item.ticker }})
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <!-- Customize selected item -->
            <template #selection="{ item }">
              {{ item.name }} ({{ item.ticker }})
            </template>
          </v-autocomplete>

          <!-- Autocomplete for fixed security -->
          <v-text-field
            v-else
            :value="`${security.name} (${security.ticker})`"
            dense
            disabled
            flat
            hide-details
            label="Security"
            outlined
            prepend-inner-icon="mdi-magnify"
            readonly />
        </v-col>
        <v-col cols="4">
          <v-btn :loading="addingToWatchlist" block color="primary" @click="addToWatchlist">Add to watchlist</v-btn>
        </v-col>
      </v-row>
    </div>

    <v-row v-else :dense="dense">
      <!-- Order -->
      <v-col cols="12" md="6">
        <v-card flat class="pl-3 pr-3 pb-3" :outlined="isStock">
          <v-card-title class="px-0">
            Place Order
          </v-card-title>
          <!-- Order Type -->
          <v-row dense>
            <v-col cols="12" lg="3">
              <div>
                <v-select
                  v-model="selectedOrderType_"
                  :items="orderTypes"
                  item-text="name"
                  item-value="value"
                  :loading="loading"
                  dense
                  hide-details
                  outlined
                  flat>
                  <template #selection="{ item }">
                    <span :class="{'success--text': item.value === 'buy', 'error--text': item.value === 'sell'}">
                      {{ item.name }}
                    </span>
                  </template>
                  <template #item="{ item }">
                    <span :class="{'success--text': item.value === 'buy', 'error--text': item.value === 'sell'}">
                      {{ item.name }}
                    </span>
                  </template>
                </v-select>
              </div>
            </v-col>
          </v-row>
          <!-- Securities -->
          <v-row dense>
            <!-- Error -->
            <v-col v-if="error" cols="12">
              <v-alert
                border="left" class="ma-0" dense
                text type="error">
                {{ error }}
              </v-alert>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-if="!security"
                v-model="transactionSecurity"
                :hide-no-data="!search || search === '' || searching"
                :items="results"
                :loading="searching"
                :rules="rules.security"
                :search-input.sync="search"
                dense
                flat
                hide-details
                item-text="name"
                item-value="ticker"
                label="Security"
                no-data-text="No matching securities"
                no-filter
                outlined
                placeholder="Search securities..."
                prepend-inner-icon="mdi-magnify"
                return-object>
                <!-- Render securities in list -->
                <template #item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }} ({{ item.ticker }})
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <!-- Customize selected item -->
                <template #selection="{ item }">
                  {{ item.name }} ({{ item.ticker }})
                </template>
              </v-autocomplete>

              <!-- Autocomplete for fixed security -->
              <v-text-field
                v-else
                :value="`${security.name} (${security.ticker})`"
                dense
                disabled
                flat
                hide-details
                label="Security"
                outlined
                prepend-inner-icon="mdi-magnify"
                readonly />
            </v-col>
          </v-row>
          <!-- Portfolios -->
          <v-row dense>
            <v-col cols="12">
              <v-simple-table
                dense
                fixed-header :height="portfolios.length > 4 ? 148 : undefined">
                <template #default>
                  <thead>
                    <tr>
                      <th>Account</th>
                      <th class="text-end" style="width: 100px;">
                        Cash
                      </th>
                      <th class="text-center px-0" style="width: 20px;" />
                      <th class="text-end" style="width: 100px;">
                        Position
                      </th>
                    </tr>
                  </thead>
                  <tbody class="mb-2">
                    <tr
                      v-for="item in groupedPortfolios"
                      :key="item.id"
                      :class="{'bid-section': item.id === selected}"
                      @click="handleClick(item.id)">
                      <td class="d-flex align-center">
                        <v-avatar class="mr-1" left size="18">
                          <v-img
                            v-if="item && item.account && item.account.brokerage.logo_url"
                            :src="item.account.brokerage.logo_url" />
                          <v-icon v-else-if="item.type === 'BANK'" small>mdi-bank</v-icon>
                        </v-avatar>
                        {{ item.name | breakpointTruncate(15, 20, 25, 30, 40) }}
                      </td>
                      <td class="text-end">
                        {{ item.availableCash | formatCurrency }}
                      </td>
                      <td class="text-end px-0">
                        <v-btn v-if="item.type !== 'BANK' && item.type !== 'WATCHLIST'" x-small icon @click="depositDialog = true; depositPortfolio = item">
                          <v-icon x-small>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                      </td>
                      <td class="text-end">
                        {{ positionPerPortfolio[item.id] || 0 }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-expand-transition>
                <v-alert
                  v-show="portfolio && portfolio.isBank()"
                  class="ma-0 mt-2" dense
                  text type="info">
                  Note that bank portfolios are immutable and that any modification will occur on a replica of this portfolio.
                </v-alert>
              </v-expand-transition>
            </v-col>
          </v-row>
          <!-- Price -->
          <v-row dense class="mt-2">
            <v-col cols="12" sm="6" offset-sm="6">
              <v-text-field
                v-model="transactionPrice"
                :disabled="!transactionSecurity"
                :loading="loadingPrice"
                :rules="rules.price"
                label="Price"
                dense
                flat
                hide-details
                min="0"
                outlined
                type="number" />
            </v-col>
          </v-row>
          <!-- Quantity -->
          <v-row dense class="mt-2">
            <v-col cols="12" sm="6" offset-sm="6">
              <v-text-field
                v-model="transactionShares"
                :disabled="!transactionSecurity"
                :rules="rules.shares"
                label="Quantity"
                dense
                flat
                hide-details
                outlined
                type="number" />
            </v-col>
          </v-row>
          <!-- Total Value -->
          <v-row id="transaction-value" dense class="mt-2">
            <v-col cols="12" sm="6" offset-sm="6">
              <v-text-field
                v-model="tradeValue"
                class="px-3"
                disabled
                label="Transaction Value"
                dense
                flat
                hide-details
                readonly
                type="number" />
            </v-col>
          </v-row>
          <!-- Actions -->
          <v-row dense>
            <v-col class="text-right" cols="12">
              <v-btn
                :disabled="simulating && postTradeLoading" :loading="!simulating && postTradeLoading"
                :color="selectedOrderType.color"
                class="mr-3 mt-2"
                @click="execute">
                {{ selectedOrderType.name }}
              </v-btn>
              <v-btn
                :disabled="!simulating && postTradeLoading" :loading="simulating && postTradeLoading" class="mr-1 mt-2"
                color="primary" type="submit"
                @click="simulate">
                Simulate
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- Post-trade Overview -->
      <v-col cols="12" md="6">
        <v-card flat :class="isStock? '' : 'pl-3 pr-3 pb-3'" :outlined="isStock">
          <v-card-title class="px-0">
            Portfolio Impact
          </v-card-title>
          <v-simple-table :dense="dense">
            <template #default>
              <thead>
                <tr>
                  <th class="text-start subtitle-1" style="width: 40%;">KPI</th>
                  <th class="text-end subtitle-1" style="width: 30%;">
                    <v-progress-circular
                      v-if="currentRiskLoading" class="mr-1" indeterminate
                      size="13" />
                    Pre-trade
                  </th>
                  <th class="text-end subtitle-1" style="width: 30%;">
                    <v-progress-circular
                      v-if="postTradeRiskLoading" class="mr-1" indeterminate
                      size="13" />
                    Post-trade
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- Largest Position -->
                <tr>
                  <td class="text-subtitle-1">Top Stock<small class="ml-1 text--secondary">Diversification</small></td>
                  <td class="text-end">{{ currentRisk.largest_position | formatPercentage(2) }}</td>
                  <td class="text-end">
                    <sentimental
                      :reference="currentRisk.largest_position" :value="postTradeRisk.largest_position"
                      left percentage precision="2"
                      show-arrow inverse />
                  </td>
                </tr>
                <!-- Largest Sector -->
                <tr>
                  <td class="text-subtitle-1">Top Sector <small class="ml-1 text--secondary">Diversification</small></td>
                  <td class="text-end">{{ currentRisk.largest_sector | formatPercentage(2) }}</td>
                  <td class="text-end">
                    <sentimental
                      :reference="currentRisk.largest_sector" :value="postTradeRisk.largest_sector"
                      left percentage precision="2"
                      show-arrow inverse />
                  </td>
                </tr>
                <!-- Beta -->
                <tr>
                  <td class="subtitle-1">Beta<small class="ml-1 text--secondary">Risk Factor</small></td>
                  <td class="text-end">{{ currentRisk.beta | formatNumber(2) }}</td>
                  <td class="text-end">
                    <sentimental
                      :reference="currentRisk.beta" :value="postTradeRisk.beta"
                      inverse left precision="2"
                      show-arrow />
                  </td>
                </tr>
                <!-- Ziggma Score -->
                <tr>
                  <td class="subtitle-1">Anova Score</td>
                  <!-- <td class="text-end">{{ currentFinancials.score | formatNumber(0) }}</td> -->
                  <td class="text-end">-</td>
                  <td class="text-end">
                    <sentimental
                      :reference="currentFinancials.score"
                      :value="postTradeFinancials.score"
                      left precision="0"
                      show-arrow />
                  </td>
                </tr>
                <!-- Profit Growth -->
                <tr>
                  <td class="subtitle-1">Profit Growth<small class="ml-1 text--secondary">Next Year</small></td>
                  <td class="text-end">{{ currentFinancials.profit_growth_forward_1 | formatPercentage(2) }}</td>
                  <td class="text-end">
                    <sentimental
                      :reference="currentFinancials.profit_growth_forward_1"
                      :value="postTradeFinancials.profit_growth_forward_1"
                      left percentage precision="2"
                      show-arrow />
                  </td>
                </tr>
                <!-- Yield -->
                <tr>
                  <td class="subtitle-1">Yield</td>
                  <td class="text-end">{{ currentFinancials.yield | formatPercentage(2) }}</td>
                  <td class="text-end">
                    <sentimental
                      :reference="currentFinancials.yield" :value="postTradeFinancials.yield"
                      left precision="2" percentage
                      show-arrow />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-text class="subtitle-2 mt-13 pb-0">
            The portfolio impact of a trade is shown for informational purposes only. It is not to be construed as investment advice.
          </v-card-text>
        </v-card>

        <!-- Message -->
        <!-- <v-alert
          v-if="message"
          border="left" class="mt-2" dense
          text type="success">
          {{ message }}
        </v-alert> -->
      </v-col>
    </v-row>

    <v-dialog
      v-model="depositDialog"
      :persistent="depositLoading"
      width="400"
      @close="closeDepositDialog">
      <v-card v-if="portfolio !== null">
        <v-card-title class="text-h5 text-wrap">
          Make a deposit
        </v-card-title>

        <v-divider />
        <v-card-text class="pb-1 pt-1">
          <v-row v-if="depositError" dense>
            <v-col>
              <v-alert
                dense text type="error"
                border="left" class="ma-0">
                {{ depositError }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="depositAmount"
                :loading="depositLoading"
                :rules="depositRules"
                label="Amount"
                type="number"
                hide-details
                dense
                filled
                outlined />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="depositLoading" color="primary" text
            @click="closeDepositDialog">
            Close
          </v-btn>
          <v-btn :loading="depositLoading" color="primary" @click="deposit">
            Deposit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <subscribe-dialog v-if="!user.is_premium" v-model="subscribeDialog" disable-activator />
  </v-form>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import client from '@ziggma/api-client';

import Sentimental from '@/components/common/Sentimental.vue';
import SubscribeDialog from '@/components/dialogs/SubscribeDialog';

export default {
  name: 'PortfolioSimulator',
  components: {
    Sentimental,
    SubscribeDialog,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    portfolios: {
      type: Array,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    security: {
      type: Object,
      default: () => undefined,
    },
    orderType: {
      type: String,
      default: () => 'buy',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    urlQuery: {
      type: Boolean,
      default: true,
    },
    isStock: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      depositDialog: false,
      depositLoading: false,
      depositAmount: 0.0,
      depositError: null,
      depositPortfolio: null,
      subscribeDialog: false,
      depositRules: [
        (v) => (v && v >= 0) || 'Amount is required and cannot be zero or less than zero.',
      ],
      // form
      formValid: true,
      rules: {
        security: [(v) => !!v || 'You must select a security.'],
        price: [(v) => (v && v > 0) || 'Price must be a positive number.'],
        shares: [(v) => (v && (v > 0)) || 'Number of shares must be greater than zero.'],
      },
      error: null,

      // portfolio
      selected: null,
      selectedProrfolioId: null,

      currentRisk: {},
      currentFinancials: {},

      currentRiskLoading: false,
      currentFinancialsLoading: false,

      postTradeRisk: {},
      postTradeFinancials: {},

      postTradeRiskLoading: false,
      postTradeFinancialsLoading: false,

      addingToWatchlist: false,

      simulating: true,

      //transaction
      transactionSecurity: null,
      transactionSecurityId: null,
      transactionPrice: null,
      transactionShares: 1,

      dateEdit: null,
      loadingPrice: false,

      search: null,
      searching: false,
      results: [],

      menu: false,
      dialog: false,
      controller: null,

      orderTypes: [
        {
          name: 'Buy',
          value: 'buy',
          color: 'success',
        },
        {
          name: 'Sell',
          value: 'sell',
          color: 'error',
        },
      ],
      selectedOrderType_: this.orderType,
    };
  },
  computed: {
    ...mapGetters('user', ['user']),
    selectedOrderType() {
      return this.orderTypes.find((t) => t.value === this.selectedOrderType_) || this.orderTypes[0];
    },
    selectedOrderTypeMultiplier() {
      return this.selectedOrderType_ === 'buy' ? 1 : -1;
    },
    portfolio() {
      return this.lookupPortfolio(this.selected);
    },
    tradeValue() {
      return (this.transactionPrice * this.transactionShares).toFixed(2);
    },
    postTradeLoading() {
      return this.postTradeRiskLoading || this.postTradeFinancialsLoading;
    },
    groupedPortfolios() {
      const bank = this.portfolios.filter((p) => p.isBank()).sort((a, b) => b.value - a.value);
      const virtual = this.portfolios.filter((p) => p.isVirtual()).sort((a, b) => b.name - a.name);
      const watchlist = this.portfolios.filter((p) => p.isWatchlist()).sort((a, b) => b.name - a.name);

      return [
        ...bank,
        ...virtual,
        ...watchlist,
      ];
    },
    positionPerPortfolio() {
      if(!this.transactionSecurity) {
        return this.portfolios.reduce((acc, p) => {
          acc[p.id] = 0;
          return acc;
        }, {});
      }

      return this.portfolios.reduce((acc, p) => {
        const holding = p._holdings.find((holding) => holding.tickerSymbol === this.transactionSecurity.ticker);
        acc[p.id] = holding?.quantity || 0;
        return acc;
      }, {});
    },
  },
  watch: {
    selected(newVal, oldVal) {
      if (!oldVal) {
        this.selectedProrfolioId = newVal;
      }

      if (this.urlQuery && parseInt(this.$route.query.portfolio) !== newVal) {
        this.$router.replace({ query: { portfolio: newVal } });
      }
      if (newVal) {
        this.fetchRisk();
        this.fetchFinancials();
        this.updatePrice();
      }
    },
    portfolios: {
      immediate: true,
      handler(portfolios) {
        if (portfolios.length > 0) {
          const portfolio = this.lookupPortfolio(this.$route.query.portfolio);
          if (portfolio) {
            this.selected = portfolio.id;
          }
          if (!this.lookupPortfolio(this.selected)) {
            this.selected = portfolios[0].id;
          }
        } else {
          this.selected = null;
        }
      },
    },
    search(val) {
      if (this.searching && this.controller !== null) {
        this.controller.abort();
      }
      if (val === null) {
        this.searching = false;
        return;
      }

      const controller = new AbortController();
      this.controller = controller;

      this.searching = true;
      return client.securities.search(val, controller.signal)
        .then((res) => {
          this.results = res.data;
        })
        .then(() => {
          this.searching = false;
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            this.searching = controller !== this.controller;
            return null;
          } else {
            // handle error
          }
        });
    },
    transactionSecurity(newVal, oldVal) {
      if (!this.portfolio || this.portfolio.isWatchlist()) {
        return;
      }

      if (!newVal) {
        this.transactionShares = 1;
        return;
      }

      if (!oldVal || oldVal.ticker !== newVal.ticker) {
        this.transactionShares = this.transactionShares || 1;
        this.updatePrice();
      }
    },
    security: {
      immediate: true,
      handler(security) {
        if (security) {
          if (typeof security === 'string') {
            this.transactionSecurity = { ticker: security, name: security };
          } else {
            this.transactionSecurity = security;
          }
          if (this.portfolio && !this.portfolio.isWatchlist()) {
            this.transactionShares = this.transactionShares || 1;
            this.updatePrice();
          }
        } else {
          this.transactionSecurity = null;
          this.transactionShares = 1;
          this.transactionPrice = null;
        }
      },
    },
  },
  methods: {
    handleClick(val) {
      this.selected = val;
    },
    allowedDates(date) {
      return this.$moment(date).isoWeekday() < 6;
    },
    reset() {
      this.error = null;
    },
    lookupPortfolio(id) {
      if (!id) {
        return null;
      }

      id = parseInt(id);
      return this.portfolios.find((p) => p.id === id);
    },
    updatePrice() {
      if (!this.transactionSecurity) {
        this.transactionPrice = null;
        return Promise.resolve();
      }

      this.loadingPrice = true;
      return client.securities
        .getPrice(this.transactionSecurity.ticker, this.latestBusinessDay())
        .then((res) => {
          this.transactionPrice = res.data.price;
          if (!this.transactionShares) {
            this.transactionShares = 1;
          }
        })
        .finally(() => {
          this.loadingPrice = false;
        });
    },
    fetchRisk() {
      this.currentRisk = {};
      this.postTradeRisk = {};
      this.currentRiskLoading = true;
      return client.portfolios
        .getRisk(this.selected)
        .then((res) => {
          this.currentRisk = res.data;
        })
        .finally(() => {
          this.currentRiskLoading = false;
        });
    },
    fetchFinancials() {
      this.currentFinancials = {};
      this.postTradeFinancials = {};
      this.currentFinancialsLoading = true;
      return client.portfolios
        .getFinancials(this.selected)
        .then((res) => {
          this.currentFinancials = res.data;
        })
        .finally(() => {
          this.currentFinancialsLoading = false;
        });
    },
    simulate() {
      if (!this.$refs.form.validate()) {
        return Promise.reject();
      }

      // if (!this.user.is_premium) {
      //   this.subscribeDialog = true;
      //   return Promise.resolve();
      // }

      this.postTradeRisk = {};
      this.postTradeFinancials = {};

      this.postTradeRiskLoading = true;
      this.postTradeFinancialsLoading = true;
      this.reset();
      this.simulating = true;
      return client.portfolios
        .simulateTransaction(this.selected, {
          security: this.transactionSecurity.ticker,
          date: this.latestBusinessDay(),
          price: this.transactionPrice,
          shares: this.selectedOrderTypeMultiplier * this.transactionShares,
        })
        .then((res) => {
          this.postTradeRisk = res.data.risk;
          this.postTradeFinancials = res.data.financials;
        })
        .catch((error) => {
          this.error = error.message;
        })
        .finally(() => {
          this.postTradeRiskLoading = false;
          this.postTradeFinancialsLoading = false;
        });
    },
    execute() {
      if (!this.$refs.form.validate()) {
        return Promise.reject();
      }

      // if (!this.user.is_premium) {
      //   this.subscribeDialog = true;
      //   return Promise.resolve();
      // }

      this.postTradeRisk = {};
      this.postTradeFinancials = {};

      this.postTradeRiskLoading = true;
      this.postTradeFinancialsLoading = true;
      this.reset();
      this.simulating = false;
      return client.portfolios
        .executeTransaction(this.selected, {
          security: this.transactionSecurity.ticker,
          date: this.latestBusinessDay(),
          price: this.transactionPrice,
          shares: this.selectedOrderTypeMultiplier * this.transactionShares,
        })
        .then((res) => {
          this.postTradeRisk = res.data.risk;
          this.postTradeFinancials = res.data.financials;

          if (this.portfolio.isBank()) {
             this.$store.dispatch('portfolio/setPortfolio', res.data.portfolio);
          } else {
            this.$store.dispatch('portfolio/updatePortfolio', {
              id: this.portfolio.id,
              availableCash: this.portfolio.availableCash - (this.selectedOrderTypeMultiplier * this.tradeValue),
            });
            this.$store.dispatch('portfolio/fetchHoldings', this.portfolio);
          }

          this.$emit('executeTransaction', { ...res.data.transaction });
          this.transactionSecurity = null;
          this.transactionPrice = null;
          this.transactionShares = 1;
          this.$refs.form.resetValidation();

          // if (this.portfolio.isBank()) {
          //   this.$store.dispatch('portfolio/selectPortfolio', res.data.portfolio)
          //   .then(() => {
          //     this.message = 'Note that bank portfolios are immutable and that any modification will occur on a replica of this portfolio.';
          //     this.isMessage = true;
          //   });
          // } else {
          //   this.$store.dispatch('portfolio/selectPortfolio', this.portfolio)
          //   .then(() => {
          //     return this.$router.push({ name: 'portfolio' });
          //   });
          // }
        })
        .catch((error) => {
          this.error = error.message;
        })
        .finally(() => {
          this.postTradeRiskLoading = false;
          this.postTradeFinancialsLoading = false;
        });
    },
    addToWatchlist() {
      if (!this.$refs.form.validate()) {
        return Promise.reject();
      }

      // if (!this.user.is_premium) {
      //   this.subscribeDialog = true;
      //   return Promise.resolve();
      // }

      this.addingToWatchlist = true;
      return client.portfolios
        .addToWatchlist(this.selected, {
          security: this.transactionSecurity.ticker,
        })
        .then(() => {
          return this.$store.dispatch('portfolio/fetchHoldings', this.portfolio);
        })
        .then(() => {
          this.$emit('addToWatchlist');
        })
        .catch((error) => {
          this.error = error.message;
        })
        .finally(() => {
          this.addingToWatchlist = false;
        });
    },
    deposit() {
      if (this.depositAmount <= 0) {
        this.depositError = 'Amount is required and cannot be equal to or less than zero.';
        return Promise.resolve();
      }

      this.depositLoading = true;
      const portfolio = this.depositPortfolio.toBuilder().build();
      return client.portfolios
        .deposit(portfolio.id, { amount: this.depositAmount })
        .then((res) => {
          portfolio.availableCash = res.data.available_cash;
          portfolio.value = res.data.current_value;
          return this.$store.dispatch('portfolio/updatePortfolio', portfolio);
        })
        .then(() => {
          this.closeDepositDialog();
        })
        .catch((error) => {
          this.depositError = error.message;
        })
        .finally(() => {
          this.depositLoading = false;
        });
    },
    closeDepositDialog() {
      this.depositDialog = false;
      this.$nextTick(() => {
        this.depositError = null;
        this.depositAmount = 0.0
      });
    },
    latestBusinessDay(){
      const workday = this.$moment().tz('America/New_York');
      const day = workday.day();
      let diff = 1;
      if (day === 0 || day === 1) {
        diff = day + 2;
      }
      return workday.subtract(diff, 'days').format('YYYY-MM-DD');
    },
  },
};
</script>
<style scoped>
::v-deep #transaction-value .v-text-field > .v-input__control > .v-input__slot:before {
  border: none;
}

.bid-section{
  background-color: #d2b81e;
}
.theme--light .bid-section{
  background-color: #d2b81e;
}

.bid-section{
  background-color: #d2b81e;
}
.bid-section:hover{
  background-color: #d2b81e3b!important;
}
.theme--light .bid-section:hover{
  background-color: #d2b81e3b!important;
}
</style>
