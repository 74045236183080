export default {
  setEquityScreener: (state, { predefinedScreener, userScreener } ) => {
    state.equities = {
      ...state.equities,
      predefinedScreener: predefinedScreener ?? null,
      userScreener: userScreener ?? null,
    };
  },
  updateEquityQuery: (state, payload ) => {
    state.equities = {
      ...state.equities,
      ...payload,
    };
  },
  resetEquityScreener: (state) => {
    state.equities = {
      ...state.equities,
      predefinedScreener: null,
      userScreener: null,
      stockIndex: null,
      sector: null,
      industry: null,
      scoreRange: null,
      filters: [],
    }
  },
  updateEtfQuery: (state, payload ) => {
    state.etfs = {
      ...state.etfs,
      ...payload,
    };
  },
  resetEtfScreener: (state) => {
    state.etfs = {
      ...state.etfs,
      assetClass: null,
      type: null,
      issuers: [],
      sector: null,
      filters: [],
    }
  },
  updateBondQuery: (state, payload ) => {
    state.bonds = {
      ...state.bonds,
      ...payload,
    };
  },
  resetBondScreener: (state) => {
    state.bonds = {
      ...state.bonds,
      country: null,
      type: null,
      issuers: [],
      amortType: null,
      filters: [],
    }
  },
}