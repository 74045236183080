import _ from 'lodash';
import moment from 'moment';

export class Holding {
  constructor() {
    this.tickerSymbol = null;
    this.assetType = null;
    this.isCashEquivalent = false;
    this.institutionPrice = null;
    this.institutionPriceAsOf = null;
    this.institutionValue = null;
    this._purchaseDate = null;
    this.quantity = null;
    this.performance = null;
    this.percentPerformance = null;
    this.costBasis = null;
    this.weight = null;
    this.security = {};
    this._value = null;
  }

  get purchaseDate() {
    return this._purchaseDate;
  }

  set purchaseDate(v) {
    this._purchaseDate = v ? moment(v) : null;
  }

  get value() {
    if (this.security) {
      return this.security.price * this.quantity;
    }
    return this.institutionValue;
  }

  set value(v) {
    this._value = v;
  }

  updateSecurityPrice(latest) {
    const prevValue = this.value;

    if (this.security) {
      this.security.price = latest.price;
      this.security.change_1d = latest.change;
      this.security.percent_change_1d = latest.percent_change;
    } else {
      this.institutionPrice = latest.price;
      this.institutionValue = latest.price * this.quantity;
    }

    this._value = latest.price * this.quantity;
    if (this.costBasis) {
      this.performance = this._value - (this.quantity * this.costBasis);
      this.percentPerformance = this.performance / (this.quantity * this.costBasis)
    } else {
      this.performance = this.percentPerformance = null;
    }

    return this._value - prevValue;
  }
}

export class HoldingBuilder {
  constructor() {
    this._holding = new Holding();
  }

  withData(data) {
    Object.keys(data).forEach((key) => {
      this._holding[_.camelCase(key)] = data[key];
    });

    return this;
  }

  build() {
    return this._holding;
  }
}

export default Holding;
