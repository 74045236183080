export default [
  // Community Material Design Icons | http://materialdesignicons.com/
  'https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css',

  // Google Material Icons | https://github.com/google/material-design-icons
  'https://fonts.googleapis.com/css?family=Material+Icons',

  // Font Awesome 5 Icons | https://fontawesome.com/
  'https://use.fontawesome.com/releases/v5.0.13/css/all.css',
]
