import client from '@ziggma/api-client';

export default {
  name: 'UserService',

  me() {
    return this.getUser();
  },

  getUser() {
    return client.user
      .getUserInfo()
      .then((res) => {
        return res.data;
      });
  },

  updateAppSetting(key, value) {
    return client.user.updateAppSetting(key, value)
      .then((res) => {
        return res.data;
      });
  },

  updateEmailSetting(email, enable) {
    return client.user.updateEmailSetting(email, enable)
      .then((res) => {
        return res.data;
      });
  },
}
