<template>
  <v-dialog
    :value="value"
    :persistent="loading"
    max-width="920"
    @input="$emit('input', $event)">
    <v-card>
      <v-card-title class="text-h5 text-wrap">
        <span>Add to {{ portfolio.isWatchlist() ? 'watchlist' : 'portfolio' }}</span>
        <v-spacer />
        <v-btn
          right
          icon
          @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />

      <div class="pt-1 px-2">
        <portfolio-simulator
          :portfolios="[portfolio]"
          :security="security"
          :url-query="false"
          readonly
          dense
          @executeTransaction="closeDialog"
          @addToWatchlist="closeDialog" />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import PortfolioSimulator from '@/components/portfolio/PortfolioSimulator.vue';

export default {
  name: 'AddToPortfolioDialog',
  components: { PortfolioSimulator },
  model: {
    event: 'input',
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    portfolio: {
      type: Object,
      required: true,
    },
    security: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    closeDialog() {
      this.$emit('input', false);
      this.$nextTick(() => this.editHolding = null);
    },
  },
};
</script>

<style scoped>

</style>
