import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import config from '@/configs/analytics';

const shouldConfigure = () => {
  if (!config.sentryDsn) {
    return false;
  }
  if (process.env.NODE_ENV !== 'production') {
    return false;
  }

  return true;
}

export const configureSentry = (router) => {
  if (!shouldConfigure()) {
    return;
  }

  Sentry.init({
    Vue,
    dsn: config.sentryDsn,
    environment: config.sentryEnvironment,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production.
    tracesSampleRate: 0.6,

    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          'localhost',
          'api.ziggma.com', 'realtime.ziggma.com',
          'api-next.ziggma.com', 'realtime-next.ziggma.com',
          /^\//,
        ],
      }),
      new Sentry.Replay(),
    ],
    // Vue Error Handling
    // Please note that if you enable this integration, by default Vue will not
    // call its `logError` internally. This means that errors occurring in the
    // Vue renderer will not show up in the developer console. If you want to
    // preserve this functionality, make sure to pass the `logErrors: true`
    // option.
    logErrors: true,
  });
}

export const configureSentryScope = (user) => {
  if (!shouldConfigure()) {
    return;
  }

  Sentry.configureScope((scope) => {
    scope.setUser(user ? { ...user } : null);
  });
}

export default configureSentry;
