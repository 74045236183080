var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "shortkey",
      rawName: "v-shortkey",
      value: ['ctrl', '/'],
      expression: "['ctrl', '/']"
    }],
    staticClass: "d-flex flex-grow-1",
    on: {
      "shortkey": _vm.onKeyup
    }
  }, [_c('v-navigation-drawer', {
    attrs: {
      "dark": _vm.menuTheme === 'dark',
      "light": _vm.menuTheme === 'light',
      "right": _vm.$vuetify.rtl,
      "app": "",
      "width": "224"
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('div', {
          staticClass: "pa-1 d-flex align-end cccccccc"
        }, [_c('v-img', {
          staticClass: "d-inline-block ml-3 mt-4 mb-3",
          attrs: {
            "src": _vm.logoSrc,
            "height": "20",
            "max-width": "130"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('div', {
          staticClass: "pa-1 text-center"
        }, _vm._l(_vm.navigation.footer, function (item, index) {
          return _c('v-btn', {
            key: index,
            attrs: {
              "href": item.href,
              "target": item.target,
              "text": ""
            }
          }, [_c('v-icon', {
            staticClass: "mr-1"
          }, [_vm._v("mdi-help-circle-outline")]), _vm._v(" " + _vm._s(item.key ? _vm.$t(item.key) : item.text) + " ")], 1);
        }), 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.drawer,
      callback: function callback($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('main-menu', {
    attrs: {
      "menu": _vm.navigation.menu
    }
  })], 1), _c('v-app-bar', {
    attrs: {
      "color": _vm.isToolbarDetached ? 'surface' : undefined,
      "dark": _vm.toolbarTheme === 'dark',
      "light": _vm.toolbarTheme === 'light',
      "height": 72,
      "app": "",
      "dense": "",
      "extension-height": "24",
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-card', {
    staticClass: "flex-grow-1 d-flex",
    class: [_vm.isToolbarDetached ? 'pa-1 mt-3 mx-1' : 'pa-0 ma-0'],
    attrs: {
      "flat": !_vm.isToolbarDetached,
      "color": "transparent"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-grow-1 align-center"
  }, [_vm.showSearch ? _c('security-search-box', {
    ref: "search",
    attrs: {
      "append-icon": "mdi-close",
      "autofocus": "",
      "background-color": "transparent",
      "flat": "",
      "solo": ""
    },
    on: {
      "blur": function blur($event) {
        _vm.showSearch = false;
      },
      "click:append": function clickAppend($event) {
        _vm.showSearch = false;
      }
    }
  }) : _c('div', {
    staticClass: "d-flex flex-grow-1 align-center"
  }, [_c('v-app-bar-nav-icon', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        _vm.drawer = !_vm.drawer;
      }
    }
  }), _c('v-spacer', {
    staticClass: "d-none d-md-block"
  }), _c('v-layout', {
    staticClass: "d-flex flex-column justify-center"
  }, [_c('security-search-box', {
    ref: "search",
    staticClass: "mx-1 hidden-xs-only",
    attrs: {
      "clearable": "",
      "dense": "",
      "filled": "",
      "flat": "",
      "outlined": ""
    }
  })], 1), _c('v-spacer', {
    staticClass: "d-none d-md-block"
  }), _c('v-spacer', {
    staticClass: "d-block d-sm-none"
  }), _c('v-btn', {
    staticClass: "d-block d-sm-none",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.showSearch = true;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-magnify")])], 1), _c('toolbar-user')], 1)], 1)])], 1), _c('v-main', [_c('v-container', {
    staticClass: "fill-height pa-0",
    attrs: {
      "fluid": !_vm.isContentBoxed
    }
  }, [_vm.$route.meta.requiresPremium && !_vm.user.is_premium ? _c('v-layout', [_c('subscribe-page')], 1) : _c('v-layout', [_vm._t("default")], 2)], 1)], 1), _c('v-footer', {
    attrs: {
      "absolute": "",
      "app": "",
      "inset": "",
      "outlined": ""
    }
  }, [_c('v-spacer'), _c('div', {
    staticClass: "body-2 ma-1"
  }, [_vm._v(" Copyrights © " + _vm._s(_vm.$moment().format('YYYY')) + " Anova. All rights reserved. ")]), _c('v-spacer')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }