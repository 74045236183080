var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_vm.isRouterLoaded && !_vm.refreshing && !_vm.askForVerification ? _c(_vm.currentLayout, {
    tag: "component"
  }, [_c('transition', {
    attrs: {
      "mode": "out-in",
      "name": "fade"
    }
  }, [_c('router-view')], 1)], 1) : _vm.askForVerification ? _c('simple-layout', [_c('email-verification-page')], 1) : _vm._e(), _c('v-snackbar', {
    attrs: {
      "value": _vm.toast.show,
      "color": _vm.toast.color,
      "timeout": _vm.toast.timeout,
      "bottom": ""
    },
    on: {
      "input": _vm.snackbarInput
    }
  }, [_vm._v(" " + _vm._s(_vm.toast.message) + " "), _vm.toast.timeout === 0 ? _c('v-btn', {
    attrs: {
      "color": "white",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$store.commit('app/hideToast');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('common.close')) + " ")]) : _vm._e()], 1), _vm.loading || _vm.refreshing ? _c('v-overlay', {
    style: {
      zIndex: 10
    },
    attrs: {
      "value": _vm.loading
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "64"
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }