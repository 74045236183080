<template>
  <div>
    <v-list-item
      v-if="menuItem.isComingSoon"
      :input-value="menuItem.value"
      :exact="menuItem.exact"
      :disabled="menuItem.disabled"
      class="grey--text"
      link>
      <tooltip top>
        <template #activator="{ on, attrs }">
          <v-list-item-icon v-bind="attrs" v-on="on">
            <v-icon :small="small" color="#bfbfbf" :class="{ 'grey--text': menuItem.disabled }">
              {{ menuItem.icon || 'mdi-circle-medium' }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content v-bind="attrs" v-on="on">
            <v-list-item-title>
              {{ menuItem.key ? $t(menuItem.key) : menuItem.text }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <span class="font-weight-bold font-italic grey--text">Coming Soon...</span>
      </tooltip>
    </v-list-item>
    <v-list-item
      v-else-if="!menuItem.items"
      :input-value="menuItem.value"
      :to="{name: menuItem.link, params: { slug: menuItem.param }}"
      :exact="menuItem.exact"
      :disabled="menuItem.disabled"
      active-class="primary--text"
      link>
      <v-list-item-icon>
        <v-icon :small="small" :class="{ 'grey--text': menuItem.disabled }">
          {{ menuItem.icon || 'mdi-circle-medium' }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{ menuItem.key ? $t(menuItem.key) : menuItem.text }}
          <!-- <tooltip v-if="menuItem.premium" top>
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                class="float-right"
                small
                color="premium"
                v-on="on">
                mdi-license
              </v-icon>
            </template>

            <span>Ziggma Premium Feature</span>
          </tooltip> -->
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-group
      v-else
      :value="menuItem.regex ? menuItem.regex.test($route.path) : false"
      :disabled="menuItem.disabled"
      :sub-group="subgroup"
      :to="{name: menuItem.link}"
      link>
      <template #activator>
        <v-list-item-icon v-if="!subgroup">
          <v-icon :small="small">{{ menuItem.icon || 'mdi-circle-medium' }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ menuItem.key ? $t(menuItem.key) : menuItem.text }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
      <slot />
    </v-list-group>
  </div>
</template>

<script>
/*
|---------------------------------------------------------------------
| Navigation Menu Item Component
|---------------------------------------------------------------------
|
| Navigation items for the NavMenu component
|
*/
export default {
  props: {
    menuItem: {
      type: Object,
      default: () => {},
    },
    subgroup: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
