import client from '@ziggma/api-client';

import UserService from '@/services/UserService';

export default {
  // User
  refreshUser: async ({ commit, state }) => {
    if (!state.user) {
      return Promise.resolve(null);
    }

    await commit('initiateRefresh');
    return UserService.getUser()
      .then(async (user) => {
        await commit('setUser', user);
        return user;
      });
  },
  updateSettings: async ({ commit }, settings) => {
    await commit('setUserSettings', settings);
  },
  verifyEmail: async ({ commit, state }) => {
    await commit('setUser', {
      ...state.user,
      email_verified: true,
    });
  },

  // Stock alerts
  fetchStockAlerts: async ({ commit, dispatch }) => {
    return client.alerts.listStockAlerts()
      .then(async (res) => {
        await commit('setStockAlerts', res.data);
        return res.data;
      })
      .catch(async (err) => {
        await commit('setStockAlertsError', err.message);
        await dispatch('app/showError', { error: err }, { root: true });
        return [];
      });
  },
  createStockAlert: async ({ state, commit, dispatch }, alert) => {
    return client.alerts.createStockAlert(alert)
      .then(async (res) => {
        const alerts = [
          ...state.stockAlerts,
          res.data,
        ];
        await commit('setStockAlerts', alerts);

        return res.data;
      })
      .catch(async (err) => {
        await dispatch('app/showError', { error: err }, { root: true });
        return null;
      });
  },
  updateStockAlert: async ({ state, commit, dispatch }, { alertId, minimum, maximum }) => {
    return client.alerts.updateStockAlert(alertId, { minimum, maximum })
      .then(async (res) => {
        const alerts = [...state.stockAlerts];
        const updatedIndex = alerts.findIndex((a) => a.id === alertId);

        alerts.splice(updatedIndex, 1);
        await commit('setStockAlerts', [
          ...alerts,
          res.data,
        ]);

        return res.data;
      })
      .catch(async (err) => {
        await dispatch('app/showError', { error: err }, { root: true });
        return null;
      });
  },
  deleteStockAlert: async ({ state, commit, dispatch }, alertId) => {
    return client.alerts.deleteStockAlert(alertId)
      .then(async (res) => {
        const alerts = [...state.stockAlerts];
        const deletedIndex = alerts.findIndex((a) => a.id === alertId);

        alerts.splice(deletedIndex, 1);
        await commit('setStockAlerts', alerts);

        return res.data;
      })
      .catch(async (err) => {
        await dispatch('app/showError', { error: err }, { root: true });
        return null;
      });
  },
  // Portfolio alerts
  fetchPortfolioAlerts: async ({ commit, dispatch }) => {
    return client.alerts.listPortfolioAlerts()
      .then(async (res) => {
        await commit('setPortfolioAlerts', res.data);
        return res.data;
      })
      .catch(async (err) => {
        await commit('setPortfolioAlertsError', err.message);
        await dispatch('app/showError', { error: err }, { root: true });
        return [];
      });
  },
  createPortfolioAlert: async ({ state, commit, dispatch }, alert) => {
    return client.alerts.createPortfolioAlert(alert)
      .then(async (res) => {
        const alerts = [
          ...state.portfolioAlerts,
          res.data,
        ];
        await commit('setPortfolioAlerts', alerts);

        return res.data;
      })
      .catch(async (err) => {
        await dispatch('app/showError', { error: err }, { root: true });
        return null;
      });
  },
  updatePortfolioAlert: async ({ state, commit, dispatch }, { alertId, minimum, maximum }) => {
    return client.alerts.updatePortfolioAlert(alertId, { minimum, maximum })
      .then(async (res) => {
        const alerts = [...state.portfolioAlerts];
        const updatedIndex = alerts.findIndex((a) => a.id === alertId);

        alerts.splice(updatedIndex, 1);
        await commit('setPortfolioAlerts', [
          ...alerts,
          res.data,
        ]);

        return res.data;
      })
      .catch(async (err) => {
        await dispatch('app/showError', { error: err }, { root: true });
        return null;
      });
  },
  deletePortfolioAlert: async ({ state, commit, dispatch }, alertId) => {
    return client.alerts.deletePortfolioAlert(alertId)
      .then(async (res) => {
        const alerts = [...state.portfolioAlerts];
        const deletedIndex = alerts.findIndex((a) => a.id === alertId);

        alerts.splice(deletedIndex, 1);
        await commit('setPortfolioAlerts', alerts);

        return res.data;
      })
      .catch(async (err) => {
        await dispatch('app/showError', { error: err }, { root: true });
        return null;
      });
  },
};
