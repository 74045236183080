import Vue from 'vue';

Vue.filter('formatNumber', (value, precision = 3) => {
  if (value !== undefined && value !== null && !isNaN(value)) {
    return value.toFixed(precision);
  }

  return '—';
});

Vue.filter('formatMagnitude', (value, precision = 3) => {
  if (value !== undefined && value !== null && !isNaN(value)) {
    return `${value.toFixed(precision)}x`;
  }

  return '—';
});

Vue.filter('formatNumberPretty', (value, precision = 0) => {
  if (value === undefined || value === null || isNaN(value)) {
    return '—';
  }
  return Number(value).toLocaleString('en-US', { maximumFractionDigits: precision });
});
