import axios from './axios'
import util from './util'

const getCompanyNews = (ticker, count) => {
  return axios.get(`/v1/companies/${ticker}/news?count=${count}`)
    .then(util.throwOrReturn)
    .catch(util.throwOrReturn)
}

const getCompanyOwners = (ticker) => {
  return axios.get(`/v1/companies/${ticker}/owners`)
    .then(util.throwOrReturn)
    .catch(util.throwOrReturn)
}

const getInsiderTransactions = (ticker) => {
  return axios.get(`/v1/companies/${ticker}/insider-transactions`)
    .then(util.throwOrReturn)
    .catch(util.throwOrReturn)
}

export default {
  getCompanyNews,
  getCompanyOwners,
  getInsiderTransactions,
}