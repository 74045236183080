var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "nudge-width": 200,
      "transition": "scale-y-transition",
      "offset-x": ""
    },
    scopedSlots: _vm._u([_vm.dense ? {
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": _vm.inAnyPortfolio ? 'primary' : '',
            "icon": "",
            "small": ""
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              $event.stopPropagation();
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(_vm._s(_vm.getIcon(_vm.inAnyPortfolio)))])], 1)];
      }
    } : {
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": _vm.inAnyPortfolio ? 'primary' : '',
            "depressed": "",
            "outlined": "",
            "small": ""
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              $event.stopPropagation();
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(_vm._s(_vm.getIcon(_vm.inAnyPortfolio)))]), _vm._v(" " + _vm._s(_vm.getContent(_vm.inAnyPortfolio)) + " ")], 1)];
      }
    }], null, true),
    model: {
      value: _vm.isOpen,
      callback: function callback($$v) {
        _vm.isOpen = $$v;
      },
      expression: "isOpen"
    }
  }, [_c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "pb-1 pt-1 primary white--text"
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "color": "white"
    }
  }, [_vm._v(_vm._s(_vm.getIcon(_vm.inAnyPortfolio)))]), _vm._v(" " + _vm._s(_vm.getContent(_vm.inAnyPortfolio)) + " ")], 1), _vm.loadingPortfolios ? _c('v-card-text', {
    staticClass: "d-flex align-center justify-center pa-3"
  }, [_c('v-progress-circular', {
    attrs: {
      "size": "24",
      "indeterminate": ""
    }
  })], 1) : _c('v-list', {
    staticClass: "overflow-auto",
    attrs: {
      "max-height": "400",
      "dense": ""
    }
  }, [_c('v-subheader', [_c('div', {
    staticClass: "d-flex align-center",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', [_vm._v("Watchlists")]), _c('v-spacer'), _c('div', [_c('v-btn', {
    attrs: {
      "color": "primary",
      "text": "",
      "left": "",
      "depressed": "",
      "small": ""
    },
    on: {
      "click": _vm.openCreateWatchlist
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" New ")], 1)], 1)], 1)]), _vm.watchlists.length > 0 ? _c('v-list-item-group', {
    attrs: {
      "value": []
    }
  }, _vm._l(_vm.watchlists, function (watchlist) {
    return _c('v-list-item', {
      key: watchlist.id,
      attrs: {
        "disabled": watchlist.isLoadingHoldings,
        "dense": "",
        "inactive": ""
      },
      on: {
        "click": function click($event) {
          return _vm.addToPortfolio(watchlist);
        }
      }
    }, [watchlist.isLoadingHoldings ? _c('v-progress-circular', {
      staticClass: "mr-1",
      attrs: {
        "size": "16",
        "indeterminate": ""
      }
    }) : _c('v-icon', {
      staticClass: "mr-1",
      attrs: {
        "color": _vm.inPortfolio[watchlist.id] ? 'primary' : '',
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.getIcon(_vm.inPortfolio[watchlist.id])) + " ")]), _vm._v(" " + _vm._s(watchlist.name) + " ")], 1);
  }), 1) : _vm._e(), _c('v-divider'), _c('v-subheader', [_c('div', {
    staticClass: "d-flex align-center",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', [_vm._v("Virtual Portfolios")]), _c('v-spacer'), _c('div', [_c('v-btn', {
    attrs: {
      "color": "primary",
      "text": "",
      "left": "",
      "depressed": "",
      "small": ""
    },
    on: {
      "click": _vm.openCreatePortfolio
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" New ")], 1)], 1)], 1)]), _vm.virtualPortfolios.length > 0 ? _c('v-list-item-group', _vm._l(_vm.virtualPortfolios, function (virtualPortfolio) {
    return _c('v-list-item', {
      key: virtualPortfolio.id,
      attrs: {
        "disabled": virtualPortfolio.isLoadingHoldings,
        "dense": "",
        "inactive": ""
      },
      on: {
        "click": function click($event) {
          return _vm.addToPortfolio(virtualPortfolio);
        }
      }
    }, [virtualPortfolio.isLoadingHoldings ? _c('v-progress-circular', {
      staticClass: "mr-1",
      attrs: {
        "size": "16",
        "indeterminate": ""
      }
    }) : _c('v-icon', {
      staticClass: "mr-1",
      attrs: {
        "color": _vm.inPortfolio[virtualPortfolio.id] ? 'primary' : '',
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.getIcon(_vm.inPortfolio[virtualPortfolio.id])) + " ")]), _vm._v(" " + _vm._s(virtualPortfolio.name) + " ")], 1);
  }), 1) : _vm._e(), _c('v-divider'), _vm.bankPortfolios.length > 0 ? _c('v-subheader', [_c('div', {
    staticClass: "d-flex align-center",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', [_vm._v("Bank Portfolios")]), _c('v-spacer')], 1)]) : _vm._e(), _vm.bankPortfolios.length > 0 ? _c('v-list-item-group', _vm._l(_vm.bankPortfolios, function (bankPortfolio) {
    return _c('v-list-item', {
      key: bankPortfolio.id,
      attrs: {
        "disabled": bankPortfolio.isLoadingHoldings,
        "dense": "",
        "inactive": ""
      },
      on: {
        "click": function click($event) {
          return _vm.addToPortfolio(bankPortfolio);
        }
      }
    }, [bankPortfolio.isLoadingHoldings ? _c('v-progress-circular', {
      staticClass: "mr-1",
      attrs: {
        "size": "16",
        "indeterminate": ""
      }
    }) : _c('v-icon', {
      staticClass: "mr-1",
      attrs: {
        "color": _vm.inPortfolio[bankPortfolio.id] ? 'primary' : '',
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.getIcon(_vm.inPortfolio[bankPortfolio.id])) + " ")]), _vm._v(" " + _vm._s(bankPortfolio.name) + " ")], 1);
  }), 1) : _vm._e()], 1)], 1), _c('create-portfolio-dialog', {
    attrs: {
      "type": _vm.createPortfolioType
    },
    model: {
      value: _vm.createPortfolioDialog,
      callback: function callback($$v) {
        _vm.createPortfolioDialog = $$v;
      },
      expression: "createPortfolioDialog"
    }
  }), _vm.portfolio ? _c('add-to-portfolio-dialog', {
    attrs: {
      "portfolio": _vm.portfolio,
      "security": _vm.security
    },
    model: {
      value: _vm.addDialog,
      callback: function callback($$v) {
        _vm.addDialog = $$v;
      },
      expression: "addDialog"
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }