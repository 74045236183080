import axios, { apiInstance, realtimeInstance, useRequestInterceptor, useResponseInterceptor } from './lib/axios';

import auth from './lib/auth';
import alerts from './lib/alerts';
import checkout from './lib/checkout';
import company from './lib/company';
import etf from './lib/etf';
import bond from './lib/bond';
import indices from './lib/indices';
import industries from './lib/industries';
import market from './lib/market';
import news from './lib/news';
import plaid from './lib/plaid';
import plans from './lib/plans';
import portfolios from './lib/portfolios';
import research from './lib/research';
import realtime from './lib/realtime';
import screener from './lib/screener';
import securities from './lib/securities';
import stocks from './lib/stocks';
import tools from './lib/tools';
import user from './lib/user';
import snaptrade from './lib/snaptrade';
import categories from './lib/categories';

export default {
  axios,
  apiInstance,
  realtimeInstance,
  useRequestInterceptor,
  useResponseInterceptor,
  auth,
  alerts,
  checkout,
  company,
  etf,
  bond,
  indices,
  industries,
  market,
  news,
  plaid,
  plans,
  portfolios,
  research,
  realtime,
  screener,
  securities,
  stocks,
  tools,
  user,
  snaptrade,
  categories,
};
