import moment from 'moment';

export default {
  loadingPortfolios: (state) => state.loadingPortfolios,
  portfoliosRaw: (state) => {
    return Object.values(state.portfolios);
  },
  portfolios: (state) => {
    return Object.values(state.portfolios)
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
  },
  lastLoadedAt: (state) => {
    if (state.lastLoadedAt) {
      return moment(state.lastLoadedAt);
    }
    return null;
  },

  bankPortfolios: (state) => {
    return Object.values(state.portfolios)
      .filter((portfolio) => portfolio.isBank() || portfolio.isAggregate())
      .sort((a, b) => {
        if (a.isAggregate()) {
          return -1;
        }
        if (b.isAggregate()) {
          return 1;
        }
        return a.name.localeCompare(b.name);
      });
  },
  virtualPortfolios: (state) => {
    return Object.values(state.portfolios)
      .filter((portfolio) => portfolio.isVirtual())
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
  },
  watchlists: (state) => {
    return Object.values(state.portfolios)
      .filter((portfolio) => portfolio.isWatchlist())
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
  },

  hasPortfolios: (state) => {
    return state.portfolios.length > 0;
  },
  hasPortfolio: (state) => (id) => {
    return id in state.portfolios;
  },
  getPortfolio: (state) => (id) => {
    return state.portfolios[id] || null;
  },
  allPortfolios: (state) => {
    return Object.values(state.portfolios).sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  },
  hasSelectedPortfolio: (state) => {
    return !!state.selectedPortfolioId;
  },
  selectedPortfolio: (state, getters) => {
    if (!getters.hasSelectedPortfolio) {
      return null;
    }
    return state.portfolios[state.selectedPortfolioId];
  },

  tickerToPortfolioMap: (state) => {
    const portfolios = Object.values(state.portfolios);

    const tickerToPortfolio = portfolios.flatMap((p) => {
      return (p.holdings || [])
        .filter((h) => !!h.tickerSymbol)
        .map((h) => {
          return {
            ticker: h.tickerSymbol,
            portfolio: p.id,
          };
        });
    });

    return tickerToPortfolio.reduce((acc, m) => {
      if (acc[m.ticker]) {
        acc[m.ticker].push(m.portfolio);
        return acc;
      }
      return {
        ...acc,
        [m.ticker]: [m.portfolio],
      };
    }, {});
  },
};
