<template>
  <div>
    <v-menu
      v-model="isOpen"
      :close-on-content-click="false"
      :nudge-width="200"
      transition="scale-y-transition"
      offset-x>
      <template v-if="dense" #activator="{ on, attrs }">
        <v-btn
          :color="inAnyPortfolio ? 'primary' : ''"
          icon
          small
          v-bind="attrs"
          v-on="on"
          @click.prevent.stop>
          <v-icon>{{ getIcon(inAnyPortfolio) }}</v-icon>
        </v-btn>
      </template>
      <template v-else #activator="{ on, attrs }">
        <v-btn
          :color="inAnyPortfolio ? 'primary' : ''"
          depressed
          outlined
          small
          v-bind="attrs"
          v-on="on"
          @click.prevent.stop>
          <v-icon small>{{ getIcon(inAnyPortfolio) }}</v-icon>
          {{ getContent(inAnyPortfolio) }}
        </v-btn>
      </template>

      <v-card flat class="overflow-hidden">
        <v-card-title class="pb-1 pt-1 primary white--text">
          <v-icon class="mr-1" color="white">{{ getIcon(inAnyPortfolio) }}</v-icon>
          {{ getContent(inAnyPortfolio) }}
        </v-card-title>

        <v-card-text v-if="loadingPortfolios" class="d-flex align-center justify-center pa-3">
          <v-progress-circular size="24" indeterminate />
        </v-card-text>
        <v-list v-else max-height="400" class="overflow-auto" dense>
          <v-subheader>
            <div class="d-flex align-center" style="width: 100%">
              <div>Watchlists</div>
              <v-spacer />
              <div>
                <v-btn
                  color="primary"
                  text
                  left
                  depressed
                  small
                  @click="openCreateWatchlist">
                  <v-icon small>mdi-plus</v-icon>
                  New
                </v-btn>
              </div>
            </div>
          </v-subheader>
          <v-list-item-group v-if="watchlists.length > 0" :value="[]">
            <v-list-item
              v-for="watchlist in watchlists" :key="watchlist.id"
              :disabled="watchlist.isLoadingHoldings"
              dense inactive
              @click="addToPortfolio(watchlist)">
              <v-progress-circular
                v-if="watchlist.isLoadingHoldings" size="16" class="mr-1" indeterminate />
              <v-icon
                v-else
                :color="inPortfolio[watchlist.id] ? 'primary' : ''"
                class="mr-1"
                small>
                {{ getIcon(inPortfolio[watchlist.id]) }}
              </v-icon>
              {{ watchlist.name }}
            </v-list-item>
          </v-list-item-group>
          <v-divider />

          <v-subheader>
            <div class="d-flex align-center" style="width: 100%">
              <div>Virtual Portfolios</div>
              <v-spacer />
              <div>
                <v-btn
                  color="primary"
                  text
                  left
                  depressed
                  small
                  @click="openCreatePortfolio">
                  <v-icon small>mdi-plus</v-icon>
                  New
                </v-btn>
              </div>
            </div>
          </v-subheader>
          <v-list-item-group v-if="virtualPortfolios.length > 0">
            <v-list-item
              v-for="virtualPortfolio in virtualPortfolios" :key="virtualPortfolio.id"
              :disabled="virtualPortfolio.isLoadingHoldings"
              dense inactive
              @click="addToPortfolio(virtualPortfolio)">
              <v-progress-circular
                v-if="virtualPortfolio.isLoadingHoldings" size="16" class="mr-1" indeterminate />
              <v-icon
                v-else
                :color="inPortfolio[virtualPortfolio.id] ? 'primary' : ''"
                class="mr-1"
                small>
                {{ getIcon(inPortfolio[virtualPortfolio.id]) }}
              </v-icon>
              {{ virtualPortfolio.name }}
            </v-list-item>
          </v-list-item-group>
          <v-divider />

          <v-subheader v-if="bankPortfolios.length > 0">
            <div class="d-flex align-center" style="width: 100%">
              <div>Bank Portfolios</div>
              <v-spacer />
            </div>
          </v-subheader>
          <v-list-item-group v-if="bankPortfolios.length > 0">
            <v-list-item
              v-for="bankPortfolio in bankPortfolios" :key="bankPortfolio.id"
              :disabled="bankPortfolio.isLoadingHoldings"
              dense inactive
              @click="addToPortfolio(bankPortfolio)">
              <v-progress-circular
                v-if="bankPortfolio.isLoadingHoldings" size="16" class="mr-1" indeterminate />
              <v-icon
                v-else
                :color="inPortfolio[bankPortfolio.id] ? 'primary' : ''"
                class="mr-1"
                small>
                {{ getIcon(inPortfolio[bankPortfolio.id]) }}
              </v-icon>
              {{ bankPortfolio.name }}
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>

      <!-- Dialogs -->
      <create-portfolio-dialog v-model="createPortfolioDialog" :type="createPortfolioType" />
      <add-to-portfolio-dialog v-if="portfolio" v-model="addDialog" :portfolio="portfolio" :security="security" />
    </v-menu>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CreatePortfolioDialog from '@/components/dialogs/CreatePortfolioDialog.vue';
import AddToPortfolioDialog from '@/components/dialogs/AddToPortfolioDialog.vue';

export default {
  name: 'PortfolioPopup',
  components: { AddToPortfolioDialog, CreatePortfolioDialog },
  props: {
    security: {
      type: Object,
      required: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      createPortfolioDialog: false,
      createPortfolioType: 'BUILDER',
      addDialog: false,
      portfolio: null,
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters('portfolio', {
      loadingPortfolios: 'loadingPortfolios',
      bankPortfolios: 'bankPortfolios',
      virtualPortfolios: 'virtualPortfolios',
      watchlists: 'watchlists',
    }),
    inPortfolio() {
      const portfolios = this.bankPortfolios.concat(this.virtualPortfolios, this.watchlists);
      const inPortfolioMap = {};
      portfolios.forEach((portfolio) => {
        inPortfolioMap[portfolio.id] = portfolio.containsSecurity(this.security);
      });
      return inPortfolioMap;
    },
    inAnyPortfolio() {
      return this._.chain(this.inPortfolio).values().some().value();
    },
  },
  created() {
    this.$store.dispatch('portfolio/fetchPortfolios');
  },
  methods: {
    getIcon(inPortfolio) {
      return inPortfolio ? 'mdi-star' : 'mdi-star-outline';
    },
    getContent(inPortfolio) {
      return inPortfolio ? 'In Portfolio' : 'Add to Portfolio';
    },
    addToPortfolio(portfolio) {
      if (!this.inPortfolio[portfolio.id]) {
        this.portfolio = portfolio;
        this.addDialog = true;
      }
    },
    openCreatePortfolio() {
      this.createPortfolioType = 'BUILDER';
      this.createPortfolioDialog = true;
    },
    openCreateWatchlist() {
      this.createPortfolioType = 'WATCHLIST';
      this.createPortfolioDialog = true;
    },
  },
};
</script>
