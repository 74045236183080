const showToast = ({ state, commit }, message) => {
  if (state.toast.show) commit('hideToast');

  setTimeout(() => {
    commit('showToast', {
      color: 'black',
      message,
      timeout: 3000,
    });
  });
};

const showError = ({ state, commit }, { message = 'Failed!', error }) => {
  if (state.toast.show) commit('hideToast');

  setTimeout(() => {
    commit('showToast', {
      color: 'error',
      message: error ? message + ' ' + error.message : message,
      timeout: 10000,
    });
  });
};

const showSuccess = ({ state, commit }, message) => {
  if (state.toast.show) commit('hideToast');

  setTimeout(() => {
    commit('showToast', {
      color: 'success',
      message,
      timeout: 3000,
    });
  });
};

const startLoading = async ({ commit }) => {
  await commit('setLoading', true);
};

const stopLoading = async ({ commit }) => {
  await commit('setLoading', false);
};

export default {
  showToast,
  showError,
  showSuccess,
  startLoading,
  stopLoading,
};
