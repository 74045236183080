import axios from './axios';
import util from './util';

// ----
// CRUD
// ----

const listPortfolios = () => {
  return axios
    .get('/v1/portfolios')
    .then(util.throwOrReturn);
};

const createPortfolio = ({ name, cash, startDate }) => {
  return axios
    .post('/v1/portfolios', {
      name,
      cash,
      start_date: startDate,
    })
    .then(util.throwOrReturn);
};

const createWatchlist = ({ name }) => {
  return axios
    .post('/v1/portfolios', {
      name,
      watchlist: true,
    })
    .then(util.throwOrReturn);
};

const updatePortfolio = ({ id, name }) => {
  return axios
    .patch(`/v1/portfolios/${id}`, {
      name,
    })
    .then(util.throwOrReturn);
};

const deletePortfolio = (id) => {
  return axios
    .delete(`/v1/portfolios/${id}`)
    .then(util.throwOrReturn);
}

const replicatePortfolio = (id, { name }) => {
  return axios
    .post(`/v1/portfolios/${id}:replicate`, {
      name,
    })
    .then(util.throwOrReturn);
}

const uploadPortfolio = ({ name, file, watchlist }) => {
  const data = new FormData();
  data.append('name', name);
  data.append('file', file);
  data.append('watchlist', watchlist ? '1' : '0');

  return axios
    .post('/v1/portfolios:upload', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(util.throwOrReturn);
}

// ------------
// Manipulation
// ------------

const deposit = (id, { amount }) => {
  return axios
    .post(`/v1/portfolios/${id}/cash`, {
      amount,
    })
    .then(util.throwOrReturn);
}

const simulateTransaction = (id, { security, date, price, shares }) => {
  return axios
    .post(`/v1/portfolios/${id}/transactions:simulate`, {
      security,
      date,
      price,
      shares,
    })
    .then(util.throwOrReturn);
}

const executeTransaction = (id, { security, date, price, shares }) => {
  return axios
    .post(`/v1/portfolios/${id}/transactions`, {
      security,
      date,
      price,
      shares,
    })
    .then(util.throwOrReturn);
}

const addToWatchlist = (id, { security }) => {
  return axios
    .post(`/v1/portfolios/${id}/watch`, {
      security,
    })
    .then(util.throwOrReturn);
}

const removeFromWatchlist = (id, ticker) => {
  return axios
    .delete(`/v1/portfolios/${id}/watch/${ticker}`)
    .then(util.throwOrReturn);
}

// ------------
// Details
// ------------

const getMetrics = (id) => {
  return axios
    .get(`/v1/portfolios/${id}/metrics`)
    .then(util.throwOrReturn);
}

const getDiversification = (id) => {
  return axios
    .get(`/v1/portfolios/${id}/diversification`)
    .then(util.throwOrReturn);
}

const getRisk = (id) => {
  return axios
    .get(`/v1/portfolios/${id}/risk`)
    .then(util.throwOrReturn);
}

const getFinancials = (id) => {
  return axios
    .get(`/v1/portfolios/${id}/financials`)
    .then(util.throwOrReturn);
}

const getDividends = (id) => {
  return axios
    .get(`/v1/portfolios/${id}/dividends`)
    .then(util.throwOrReturn);
}

const listHoldings = (id, detailed = true) => {
  return axios
    .get(`/v1/portfolios/${id}/holdings`, {
      params: { detailed },
    })
    .then(util.throwOrReturn);
}

const getHolding = (id, ticker) => {
  return axios
    .get(`/v1/portfolios/${id}/holdings/${ticker}`)
    .then(util.throwOrReturn);
}

const patchHolding = (id, ticker, { purchaseDate }) => {
  return axios
    .patch(`/v1/portfolios/${id}/holdings/${ticker}`, {
      purchase_date: purchaseDate,
    })
    .then(util.throwOrReturn);
}

const listTransactions = (id, page) => {
  return axios
    .get(`/v1/portfolios/${id}/transactions`, {
      params: {
        page,
        perPage: 25,
      },
    })
    .then(util.throwOrReturn);
}

const listNews = (id) => {
  return axios
    .get(`/v1/portfolios/${id}/news`)
    .then(util.throwOrReturn);
}

const listEvents = (id) => {
  return axios
    .get(`/v1/portfolios/${id}/events`)
    .then(util.throwOrReturn);
}

export default {
  // CRUD
  listPortfolios,
  createPortfolio,
  createWatchlist,
  updatePortfolio,
  deletePortfolio,
  replicatePortfolio,
  uploadPortfolio,
  // Manipulation
  deposit,
  simulateTransaction,
  executeTransaction,
  addToWatchlist,
  removeFromWatchlist,
  // Details
  getMetrics,
  getDiversification,
  getRisk,
  getFinancials,
  getDividends,
  listHoldings,
  getHolding,
  patchHolding,
  listTransactions,
  listNews,
  listEvents,
};
