// To use in your project, import the initialize function
import { initialize } from '@devcycle/devcycle-js-sdk';

import config from '@/configs';

const flags = {
  client: null,
  initialize: () => {},
  _isReady: null,
  variable: async () => {
    return true;
  },
};

const getUserIp = () => {
  return fetch('https://api.ipify.org?format=json')
    .then((x) => x.json())
    .then(({ ip }) => {
      return ip;
    })
    .catch(() => {
      return null;
    });
};

const _initialize = async (user) => {
  const ip = await getUserIp();
  if (!user) {
    // The user object needs either a user_id, or isAnonymous set to true
    flags.client = initialize(config.flags, {
      isAnonymous: true,
      customData: { ip },
    });
  } else {
    // The user object needs either a user_id, or isAnonymous set to true
    flags.client = initialize(config.flags, {
      isAnonymous: false,
      user_id: `${user.id}`,
      email: user.email,
      customData: { ip },
    });
  }
}

if (config.flags) {
  flags.initialize = (user) => {
    flags._isReady = _initialize(user);
  }

  flags.variable = async (variable, defaultValue) => {
    await flags._isReady;
    const client = await flags.client.onClientInitialized();
    return client.variable(variable, defaultValue);
  };
}

export default flags;
