import axios from './axios';
import util from './util';

const listStockAlerts = () => {
  return axios
    .get('/v1/stock-alerts')
    .then(util.throwOrReturn);
};
const createStockAlert = ({ ticker, metric, minimum, maximum }) => {
  return axios
    .post('/v1/stock-alerts', {
      ticker,
      metric,
      minimum,
      maximum,
    })
    .then(util.throwOrReturn);
};
const updateStockAlert = (alertId, { minimum, maximum }) => {
  return axios
    .patch(`/v1/stock-alerts/${alertId}`, {
      minimum,
      maximum,
    })
    .then(util.throwOrReturn);
};
const deleteStockAlert = (alertId) => {
  return axios
    .delete(`/v1/stock-alerts/${alertId}`)
    .then(util.throwOrReturn);
};

const listPortfolioAlerts = () => {
  return axios
    .get('/v1/portfolio-alerts')
    .then(util.throwOrReturn);
};
const createPortfolioAlert = ({ portfolioId, metric, minimum, maximum }) => {
  return axios
    .post('/v1/portfolio-alerts', {
      portfolio: portfolioId,
      metric,
      minimum,
      maximum,
    })
    .then(util.throwOrReturn);
};
const updatePortfolioAlert = (alertId, { minimum, maximum }) => {
  return axios
    .patch(`/v1/portfolio-alerts/${alertId}`, {
      minimum,
      maximum,
    })
    .then(util.throwOrReturn);
};
const deletePortfolioAlert = (alertId) => {
  return axios
    .delete(`/v1/portfolio-alerts/${alertId}`)
    .then(util.throwOrReturn);
};

export default {
  // Stock alerts
  listStockAlerts,
  createStockAlert,
  updateStockAlert,
  deleteStockAlert,
  // Portfolio alerts
  listPortfolioAlerts,
  createPortfolioAlert,
  updatePortfolioAlert,
  deletePortfolioAlert,
};
