<template>
  <v-combobox
    ref="search"
    :items="items"
    item-text="name"
    item-value="ticker"
    :loading="isSearching"
    :search-input.sync="search"
    return-object
    :placeholder="placeholder ? placeholder : $t('menu.search')"
    :append-icon="appendIcon"
    :prepend-inner-icon="prependInnerIcon"
    :background-color="backgroundColor"
    no-filter
    hide-details
    :solo="solo"
    :autofocus="autofocus"
    :clearable="clearable"
    :outlined="outlined"
    :filled="filled"
    :flat="flat"
    :dense="dense"
    @blur="$emit('blur', $event)"
    @click:append="$emit('click:append', $event)">
    <!-- Render securities in list -->
    <template #item="{ item, attrs, on }">
      <v-list-item
        v-bind="attrs"
        link :to="{name: 'securities.profile', params: { ticker: item.ticker }}"
        v-on="on">
        <v-list-item-content>
          <v-list-item-title class="d-flex justify-space-between align-center">
            <div>
              <span>{{ item.name | truncate(50) }}</span>
              <span class="ml-1 text-subtitle-2 text--secondary">({{ item.ticker }})</span>
              <v-chip v-if="item.asset_type === 'ETF'" class="ml-1" color="primary" x-small>ETF</v-chip>
            </div>
            <portfolio-popup :security="item" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template #no-data>
      <div v-if="isSearching" class="d-flex flex-grow-1 pa-1">
        <v-progress-circular class="mr-1" color="primary" indeterminate size="24" />
        Searching for securities...
      </div>
      <div v-else-if="search && (!items || items.length === 0)" class="d-flex flex-grow-1 pa-1">
        0 securities found.
      </div>
    </template>
  </v-combobox>
<!--  <div class="d-flex flex-grow-1">-->
<!--    -->
<!--  </div>-->
</template>

<script>
import client from '@ziggma/api-client';
import axios from 'axios';
import PortfolioPopup from '@/components/popups/PortfolioPopup';

export default {
  name: 'SecuritySearchBox',
  components: { PortfolioPopup },
  props: {
    placeholder: {
      type: String,
      default: undefined,
    },
    appendIcon: {
      type: String,
      default: () => '',
    },
    prependInnerIcon: {
      type: String,
      default: 'mdi-magnify',
    },
    solo: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      items: [],
      isSearching: false,
      search: '',
      controller: null,
    };
  },
  watch: {
    search(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.searchSecurities(newVal);
      }
    },
  },
  methods: {
    focus() {
      this.$refs.search.focus();
    },
    searchSecurities(query) {
      // Aborts any currently ongoing search.
      if (this.isSearching && this.controller !== null) {
        this.controller.abort();
      }

      // If query is empty, no further action.
      if (!query) {
        this.isSearching = false;
        return;
      }

      const controller = new AbortController();
      this.controller = controller;

      this.isSearching = true;
      this.items = [];
      return client.securities
        .search(query, controller.signal)
        .then((res) => {
          this.items = res.data;
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            return null;
          } else {
            // handle error
          }
        })
        .finally(() => {
          this.isSearching = controller !== this.controller;
        });
    },
  },
};
</script>

<style scoped>

</style>
