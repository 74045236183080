import axios from './axios';
import util from './util';

const login = (username, password) => {
  return axios
    .post('/v1/auth/login', {
      email: username,
      password,
    })
    .then(util.throwOrReturn);
};

const register = ({
                    first_name,
                    last_name,
                    email,
                    password,
                    password_confirmation,
                    referrer,
                  }, token) => {
  return axios
    .post('/v1/auth/register', {
      first_name,
      last_name,
      email,
      password,
      password_confirmation,
      referrer,
      token,
    })
    .then(util.throwOrReturn);
};

const logout = () => {
  return axios
    .get('/v1/auth/logout')
    .then(util.throwOrReturn);
};

const deleteAccount = (token) => {
  return axios
    .delete('/v1/user', {
      params: {
        token,
      },
    })
    .then(util.throwOrReturn);
};

const verifyEmail = (id, hash, queryParams) => {
  return axios
    .get(`/v1/auth/email/${id}/${hash}:verify`, {
      params: queryParams,
    })
    .then(util.throwOrReturn);
};

const resendConfirmationEmail = () => {
  return axios
    .post('/v1/auth/email:resend')
    .then(util.throwOrReturn);
};

const user = () => {
  return axios.get('/v1/auth/me')
    .then(util.throwOrReturn);
};

const forgotPassword = (email, token) => {
  return axios
    .post('/v1/auth/password:send_reset_link', { email, token })
    .then(util.throwOrReturn);
};

const resetPassword = ({
                         password,
                         password_confirmation,
                         token,
                         email,
                       }) => {
  return axios
    .post('/v1/auth/password:reset', {
      password,
      password_confirmation,
      token,
      email,
    })
    .then(util.throwOrReturn);
};

const changePassword = ({ password, passwordConfirmation }) => {
  return axios
    .post('/v1/auth/password:change', {
      password,
      password_confirmation: passwordConfirmation,
    })
    .then(util.throwOrReturn);
};

export default {
  login,
  register,
  logout,
  deleteAccount,
  verifyEmail,
  resendConfirmationEmail,
  user,
  forgotPassword,
  resetPassword,
  changePassword,
};
