<template>
  <v-list nav dense class="pt-0">
    <div v-for="(item, index) in filteredMenu" :key="index">
      <div v-if="item.key || item.text" class="pa-1 mt-1 subtitle-2">{{ item.key ? $t(item.key) : item.text }}</div>
      <nav-menu :menu="item.items" />
    </div>
  </v-list>
</template>

<script>
import NavMenu from './NavMenu'

export default {
  components: {
    NavMenu,
  },
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    filteredMenu() {
      return this.menu.filter((section) => section.items.length > 0)
    },
  },
}
</script>
