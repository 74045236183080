export default [
  // Portfolio tools
  {
    path: '/tools/simulator',
    name: 'tools.simulator',
    component: () => import(/* webpackChunkName: "portfolio" */ '@/pages/Tools/SimulatorPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: '/tools/back-test',
  //   name: 'tools.back-test',
  //   component: () => import(/* webpackChunkName: "portfolio" */ '@/pages/Tools/BackTestPage.vue'),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
];
