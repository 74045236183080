import Vue from 'vue';
import client from '@ziggma/api-client';

export default {
  fetchLatestPrice: async ({ commit, dispatch }, ticker) => {
    const variable = await Vue.$flags.variable('realtime-prices', false);
    const enable = variable.value;
    if (!enable) {
      return;
    }

    client.realtime.fetchSecurityPrice(ticker)
      .then(async (res) => {
        if (res.data && res.data.last) {
          await dispatch('updateSecurityPrice', {
            security_id: res.data.id,
            ticker: res.data.ticker,
            last_timestamp: res.data.last.timestamp,
            last_price: res.data.last.price,
            change: res.data.last.change,
            percent_change: res.data.last.percent_change,
          });
        }
      })
      .catch(() => {
        //console.error(err.message);
      })
  },

  updateSecurityPrice: async ({ commit, dispatch }, quote) => {
    await commit('updateSecurityPrice', quote);
    // const { ticker } = quote;
    // await dispatch('portfolio/updatePortfolioValue', { ticker }, { root: true });
  },
}
