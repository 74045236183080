<template>
  <v-container fluid class="fill-height align-center justify-center">
    <v-row>
      <v-col cols="12" sm="10" lg="8" class="mx-auto">
        <v-sheet class="mx-auto pa-4" elevation="1" rounded max-width="680">
          <div class="mt-4">
            <v-img :src="logo" contain max-height="100" />
          </div>
          <div class="mt-6">
            <div class="text-h3 text-center font-weight-bold primary--text mb-3">
              Please verify your email!
            </div>
            <div class="text-h5 text-center">You're almost there! We sent an email to</div>
            <div class="text-h5 text-center"> {{ user.email }} </div>
          </div>
          <div class="mt-6">
            <div class="text-center">Just click on the link in that email to complete your signup.</div>
            <div class="text-center">If you don't see it, you may need to <span class="font-weight-bold">check your spam folder.</span></div>
          </div>
          <div class="mt-6">
            <div class="text-center mb-2">Still can't find the email?</div>
            <div class="d-flex justify-center">
              <v-btn
                :disabled="canResendIn > 0"
                :ripple="false"
                depressed color="primary"
                class="text-uppercase"
                @click="resendConfirmationEmail">
                Resend Email {{ canResendIn ? `(${canResendIn})` : '' }}
              </v-btn>
            </div>
            <div class="text-center mt-3 mb-2">Need help? <a href="https://ziggma.com/contact" target="_blank">Contact Us</a></div>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

// services
import AuthService from '@/services/AuthService';

import lightLogo from '@/assets/images/logo/sign.svg'
import darkLogo from '@/assets/images/logo/sign_white.svg'

export default {
  name: 'EmailVerificationPage',
  data: () => ({
    canResendIn: 0,
    resendInterval: null,
  }),
  computed: {
    ...mapGetters('user', ['user']),
     logo() {
      return this.$vuetify.theme.dark ? darkLogo : lightLogo;
    },
  },
  beforeDestroy() {
    if (this.resendInterval) {
      clearTimeout(this.resendInterval);
    }
  },
  methods: {
    resendConfirmationEmail() {
      this.canResendIn = 60;
      return AuthService.resendConfirmationEmail()
        .then(() => {
          this.countdown(60);
        });
    },
    countdown(remaining) {
      this.canResendIn = remaining;
      if (this.canResendIn === 0) {
        return;
      }

      const countdown = this.countdown.bind(this);
      this.resendInterval = setTimeout(() => {
        countdown(remaining - 1);
      }, 1000);
    },
  },
};
</script>

<style scoped>
.v-list--dense .v-list-item {
  min-height: 25px !important;
}
</style>
