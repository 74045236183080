var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "max-width": _vm.maxWidth,
      "open-on-hover": "",
      "open-on-click": false,
      "open-on-focus": "",
      "offset-overflow": "",
      "nudge-top": _vm.top ? _vm.nudge : 0,
      "nudge-right": _vm.right ? _vm.nudge : 0,
      "nudge-bottom": _vm.bottom ? _vm.nudge : 0,
      "nudge-left": _vm.left ? _vm.nudge : 0,
      "auto": _vm.auto,
      "top": _vm.top,
      "bottom": _vm.bottom,
      "left": _vm.left,
      "right": _vm.right,
      "transition": _vm.top ? 'scroll-y-reverse-transition' : 'scroll-y-transition',
      "close-delay": 100,
      "close-on-content-click": false,
      "origin": "center center",
      "content-class": "elevation-0 no-contain",
      "tile": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(props) {
        return [_vm._t("activator", function () {
          return [_c('v-icon', _vm._g(_vm._b({
            staticClass: "ml-1 tooltip-icon",
            attrs: {
              "x-small": ""
            },
            domProps: {
              "textContent": _vm._s(_vm.icon)
            }
          }, 'v-icon', props.attrs, false), props.on))];
        }, null, props)];
      }
    }], null, true)
  }, [_c('v-card', {
    staticClass: "tooltip",
    attrs: {
      "flat": "",
      "outlined": "",
      "tile": "",
      "max-height": _vm.maxHeight
    }
  }, [_c('v-card-text', {
    staticClass: "pa-2"
  }, [_vm._t("default")], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }