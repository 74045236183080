var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "value": _vm.value,
      "persistent": _vm.loading,
      "max-width": "920"
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event);
      }
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5 text-wrap"
  }, [_c('span', [_vm._v("Add to " + _vm._s(_vm.portfolio.isWatchlist() ? 'watchlist' : 'portfolio'))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "right": "",
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "pt-1 px-2"
  }, [_c('portfolio-simulator', {
    attrs: {
      "portfolios": [_vm.portfolio],
      "security": _vm.security,
      "url-query": false,
      "readonly": "",
      "dense": ""
    },
    on: {
      "executeTransaction": _vm.closeDialog,
      "addToWatchlist": _vm.closeDialog
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }