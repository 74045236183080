export default [
  {
    path: '/securities/:ticker',
    name: 'securities.profile',
    component: () => import(/* webpackChunkName: "securities" */ '@/pages/Securities/SecurityProfile.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];
