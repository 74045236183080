<template>
  <div class="d-inline-flex align-center" :class="[colorClass ? colorClass : '', 'text-center']">
    <v-icon
      v-if="showArrow && arrow && left"
      :class="`${!dense ? 'mr-1' : ''}`" :color="color" dense
      small v-text="arrow" />
    <span v-if="showSign && sign">{{ sign }}</span>
    <span v-if="currency">
      {{ value | formatCurrency(null, _precision) }}
    </span>
    <span v-else-if="percentage">
      {{ value | formatPercentage(_precision) }}
    </span>
    <span v-else>
      {{ value | formatNumber(_precision) }}
    </span>
    <v-icon
      v-if="showArrow && arrow && !left"
      :class="`${!dense ? 'ml-1' : ''}`" :color="color" dense
      :small="!largeArrow" :large="largeArrow" v-text="arrow" />
  </div>
</template>

<script>
export default {
  name: 'Sentimental',
  props: {
    value: {
      type: Number,
      default: undefined,
    },
    reference: {
      type: Number,
      default: undefined,
    },
    percentage: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: Boolean,
      default: false,
    },
    precision: {
      type: [Number, String],
      default: undefined,
    },
    showArrow: {
      type: Boolean,
      default: false,
    },
    showSign: {
      type: Boolean,
      default: false,
    },
    inverse: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    neutralColor: {
      type: String,
      default: 'secondary',
    },
    largeArrow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    _precision() {
      return parseInt(this.precision);
    },
    sentimentValue() {
      return this.factor * (this.reference ? (this.value - this.reference) : this.value);
    },
    factor() {
      return this.inverse ? -1 : 1;
    },
    color() {
      if (this.sentimentValue > 0) return 'success';
      if (this.sentimentValue < 0) return 'error';
      return this.neutralColor;
    },
    colorClass() {
      if (this.sentimentValue > 0) return 'success--text';
      if (this.sentimentValue < 0) return 'error--text';
      return this.neutralColor ? `${this.neutralColor}--text` : null;
    },
    arrow() {
      if (this.factor * this.sentimentValue > 0) return 'arrow_upward';
      if (this.factor * this.sentimentValue < 0) return 'arrow_downward';
      return null;
    },
    sign() {
      if (this.factor * this.sentimentValue > 0) return '+';
      //if (this.factor * this.sentimentValue < 0) return '-';
      return null;
    },
  },
};
</script>

<style scoped>

</style>
