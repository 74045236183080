import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';

import config from '../configs';

Vue.use(VueReCaptcha, {
  siteKey: config.security.siteKey,
  loaderOptions: {
    autoHideBadge: true,
  },
});
