import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  user: {},
  refreshing: false,

  // User's subscription, if any.
  subscription: null,

  // User's notifications.
  notifications: [],

  // User-defined stock alerts.
  stockAlerts: [],
  stockAlertsLastLoadedAt: null,
  stockAlertsState: 'NOT_LOADED',
  stockAlertsError: null,

  // User-defined portfolio alerts.
  portfolioAlerts: [],
  portfolioAlertsLastLoadedAt: null,
  portfolioAlertsState: 'NOT_LOADED',
  portfolioAlertsError: null,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
