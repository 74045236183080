<template>
  <v-menu
    :max-width="maxWidth" open-on-hover :open-on-click="false" open-on-focus
    offset-overflow :nudge-top="top ? nudge : 0" :nudge-right="right ? nudge : 0"
    :nudge-bottom="bottom ? nudge : 0" :nudge-left="left ? nudge : 0"
    :auto="auto"
    :top="top" :bottom="bottom"
    :left="left" :right="right"
    :transition="top ? 'scroll-y-reverse-transition' : 'scroll-y-transition'"
    :close-delay="100"
    :close-on-content-click="false"
    origin="center center"
    content-class="elevation-0 no-contain" tile>
    <template #activator="props">
      <slot name="activator" v-bind="props">
        <v-icon
          x-small
          class="ml-1 tooltip-icon"
          v-bind="props.attrs"
          v-on="props.on"
          v-text="icon" />
      </slot>
    </template>

    <v-card
      flat outlined tile class="tooltip"
      :max-height="maxHeight">
      <v-card-text class="pa-2">
        <slot />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    icon: {
      type: String,
      default: 'mdi-information-outline',
    },
    maxWidth: {
      type: [String, Number],
      default: 'auto',
    },
    maxHeight: {
      type: [String, Number],
      default: 'auto',
    },
    auto: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    nudge: {
      type: Number,
      default: 20,
    },
  },
};
</script>

<style scoped lang="css">
.no-contain {
  contain: layout !important;
  overflow: visible;
}
</style>
