import Vue from 'vue'

Vue.filter('convertAmount', (value, tagName) => {
  if (value !== null && value !== 'NA') {
    return tagName === 'Billion'
          ? Number((Number(value) / 1.0e9).toFixed(1))
          : tagName === 'Million'
            ? Number((Number(value) / 1.0e6).toFixed(1))
            : tagName === 'K'
              ? Number((Number(value) / 1.0e3).toFixed(1))
              : value
  }
  return '—'
})

Vue.filter('checkAmount', (amount) => {
  return Math.abs(Number(amount)) >= 1.0e9
        ? 'Billion'
        : Math.abs(Number(amount)) >= 1.0e6
          ? 'Million'
          : Math.abs(Number(amount)) >= 1.0e3
            ? 'K'
            : 'Hundred'
})

Vue.filter('formatMeasurement', (value, precision = 1, tagName) => {
  if (value !== undefined && value !== null && !isNaN(value)) {
    if (tagName === 'MillionTons') {
      return (Number((Number(value) / 1.0e6).toFixed(precision)))
        .toLocaleString('en-US', { maximumFractionDigits: precision });
    }
    if (tagName === 'MetricTons') {
      return Number(value).toLocaleString('en-US', { maximumFractionDigits: precision }) + ' mt';
    }

    return Number(value).toLocaleString('en-US', { maximumFractionDigits: precision });
  }
  return '—';
});
