export default [
  {
    path: '/error/not-found',
    name: 'error.not-found',
    component: () => import(/* webpackChunkName: "utility" */ '@/pages/error/NotFoundPage.vue'),
    meta: {
      layout: 'error',
    },
  },
  {
    path: '/error/unexpected',
    name: 'error.unexpected',
    component: () => import(/* webpackChunkName: "utility" */ '@/pages/error/UnexpectedPage.vue'),
    meta: {
      layout: 'error',
    },
  },
  {
    path: '/utility/maintenance',
    name: 'utility.maintenance',
    component: () => import(/* webpackChunkName: "utility" */ '@/pages/utility/MaintenancePage.vue'),
    meta: {
      layout: 'auth',
    },
  },
  {
    path: '/utility/coming-soon',
    name: 'utility.soon',
    component: () => import(/* webpackChunkName: "utility" */ '@/pages/utility/SoonPage.vue'),
    meta: {
      layout: 'auth',
    },
  },
  // {
  //   path: '/utility/help',
  //   name: 'utility.help',
  //   component: () => import(/* webpackChunkName: "utility" */ '@/pages/utility/HelpPage.vue'),
  // },
];
