import axios from './axios';
import util from './util';

const getIndustryMedians = (industry, { startDate, endDate } = undefined) => {
  return axios
    .get(`/v1/industries/${industry}/medians`, {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    })
    .then(util.throwOrReturn);
};

const getIndustryTopStocks = (industry, count = 5) => {
  return axios
    .get(`/v1/industries/${industry}/top-stocks`, {
      params: {
        count,
      },
    })
    .then(util.throwOrReturn);
};

const getClimateScoreHistory = (industry) => {
  return axios
    .get(`/v1/industries/${industry}/climate-history`)
    .then(util.throwOrReturn);
};

const getTopClimateStocks = (industry, k = 5) => {
  return axios
    .get(`/v1/industries/${industry}/top-climate-stocks`, {
      params: {
        k,
      },
    })
    .then(util.throwOrReturn);
};

const getBottomClimateStocks = (industry, k = 5) => {
  return axios
    .get(`/v1/industries/${industry}/bottom-climate-stocks`, {
      params: {
        k,
      },
    })
    .then(util.throwOrReturn);
};

export default {
  getIndustryMedians,
  getIndustryTopStocks,
  getTopClimateStocks,
  getBottomClimateStocks,
  getClimateScoreHistory,
};
