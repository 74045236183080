<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="8" xl="6" class="mx-auto">
        <plans />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Plans from '@/components/subscription/Plans';

export default {
  name: 'SubscribePage',
  components: { Plans },
};
</script>

<style scoped>

</style>
