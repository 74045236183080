export default {
  // current currency
  currency: {
    label: 'XOF',
    decimalDigits: 2,
    decimalSeparator: '.',
    thousandsSeparator: ',',
    currencySymbol: 'XOF ',
    currencySymbolNumberOfSpaces: 0,
    currencySymbolPosition: 'left',
  },

  // available currencies for user selection
  availableCurrencies: [{
    label: 'USD',
    decimalDigits: 2,
    decimalSeparator: '.',
    thousandsSeparator: ',',
    currencySymbol: '$',
    currencySymbolNumberOfSpaces: 0,
    currencySymbolPosition: 'left',
  },
  {
    label: 'XOF',
    decimalDigits: 2,
    decimalSeparator: '.',
    thousandsSeparator: ',',
    currencySymbol: 'XOF',
    currencySymbolNumberOfSpaces: 1,
    currencySymbolPosition: 'right',
  },
  ],
}
