var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "value": !_vm.self ? _vm.value : _vm.isOpen,
      "width": _vm.isMobile ? '100%' : '65%',
      "max-width": "950",
      "persistent": ""
    },
    on: {
      "input": _vm.onInput
    },
    scopedSlots: _vm._u([!_vm.disableActivator ? {
      key: "activator",
      fn: function fn(props) {
        return [_vm._t("activator", function () {
          return [_c('v-btn', _vm._g(_vm._b({
            attrs: {
              "depressed": "",
              "color": "primary"
            }
          }, 'v-btn', props.attrs, false), props.on), [_vm._v("Upgrade Now")])];
        }, null, props)];
      }
    } : null], null, true)
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "text-h5 text-wrap"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "right": "",
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', {
    staticClass: "pa-2"
  }, [_c('plans', {
    attrs: {
      "dialog": ""
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }