import Vue from 'vue';
import moment from 'moment-timezone';

export default {
  updateSecurityPrice: (state, quote) => {
    const MARKET_OPEN = moment().tz('America/New_York').startOf('day').set({ hour: 9, minute: 30 });
    const MARKET_CLOSE = moment().tz('America/New_York').startOf('day').set({ hour: 16 });

    const { last_timestamp } = quote;
    const currentTimestamp = moment.tz(last_timestamp, 'America/New_York');
    if (!currentTimestamp.isBetween(MARKET_OPEN, MARKET_CLOSE)) {
      return null;
    }

    const { ticker } = quote;
    if (!state.prices[ticker]) {
      Vue.set(state.prices, ticker, {
        timestamp: currentTimestamp,
        price: quote.last_price,
        change: quote.change,
        percent_change: quote.percent_change,
      });
      return state.prices[ticker];
    }

    const previous = state.prices[ticker];
    if (moment(previous.timestamp).isBefore(currentTimestamp)) {
      Vue.set(state.prices, ticker, {
        timestamp: currentTimestamp,
        price: quote.last_price,
        change: quote.change,
        percent_change: quote.percent_change,
      });
    }

    return state.prices[ticker];
  },
};
