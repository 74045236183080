<template>
  <div
    v-shortkey="['ctrl', '/']"
    class="d-flex flex-grow-1"
    @shortkey="onKeyup">
    <!-- System bar -->
    <!--    <v-system-bar app color="primary">-->
    <!--      <div class="font-weight-bold mx-auto">-->
    <!--        Ziggma 2.0 is here!-->
    <!--      </div>-->
    <!--    </v-system-bar>-->

    <!-- Navigation -->
    <v-navigation-drawer
      v-model="drawer"
      :dark="menuTheme === 'dark'"
      :light="menuTheme === 'light'"
      :right="$vuetify.rtl"
      app
      width="224">
      <!-- Navigation menu info -->
      <template #prepend>
        <div class="pa-1 d-flex align-end cccccccc">
          <v-img
            :src="logoSrc"
            class="d-inline-block ml-3 mt-4 mb-3"
            height="20"
            max-width="130" />
        </div>
      </template>

      <!-- Navigation menu -->
      <main-menu :menu="navigation.menu" />

      <!-- Navigation menu footer -->
      <template #append>
        <!-- Footer navigation links -->
        <div class="pa-1 text-center">
          <v-btn
            v-for="(item, index) in navigation.footer"
            :key="index"
            :href="item.href"
            :target="item.target"
            text>
            <v-icon class="mr-1">mdi-help-circle-outline</v-icon>
            {{ item.key ? $t(item.key) : item.text }}
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <!-- Toolbar -->
    <v-app-bar
      :color="isToolbarDetached ? 'surface' : undefined"
      :dark="toolbarTheme === 'dark'"
      :light="toolbarTheme === 'light'"
      :height="72"
      app
      dense
      extension-height="24"
      flat
      outlined>
      <v-card
        :class="[isToolbarDetached ? 'pa-1 mt-3 mx-1' : 'pa-0 ma-0']" :flat="!isToolbarDetached" class="flex-grow-1 d-flex"
        color="transparent">
        <div class="d-flex flex-grow-1 align-center">
          <!-- search input mobile -->
          <security-search-box
            v-if="showSearch" ref="search"
            append-icon="mdi-close"
            autofocus
            background-color="transparent"
            flat
            solo
            @blur="showSearch = false"
            @click:append="showSearch = false" />

          <div v-else class="d-flex flex-grow-1 align-center">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

            <v-spacer class="d-none d-md-block" />

            <!-- search input desktop -->
            <v-layout class="d-flex flex-column justify-center">
              <security-search-box
                ref="search" class="mx-1 hidden-xs-only"
                clearable dense filled flat
                outlined />
              <!-- <market-index-list v-if="$vuetify.breakpoint.mdAndUp" class="pt-1" /> -->
            </v-layout>

            <v-spacer class="d-none d-md-block" />
            <v-spacer class="d-block d-sm-none" />

            <v-btn class="d-block d-sm-none" icon @click="showSearch = true">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>

            <!--            <toolbar-language />-->

            <!--            <div class="hidden-xs-only mx-1">-->
            <!--              <toolbar-currency />-->
            <!--            </div>-->

            <!--            <toolbar-apps />-->

            <!--            <div :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">-->
            <!--              <toolbar-toggle-theme />-->
            <!--            </div>-->

            <!-- <toolbar-premium-status /> -->
            <toolbar-user />
          </div>
        </div>
      </v-card>

      <!--      <template v-if="$vuetify.breakpoint.mdAndUp" #extension>-->
      <!--        <v-spacer />-->
      <!--        <market-index-list />-->
      <!--        <v-spacer />-->
      <!--      </template>-->
    </v-app-bar>

    <v-main>
      <v-container :fluid="!isContentBoxed" class="fill-height pa-0">
        <v-layout v-if="$route.meta.requiresPremium && !user.is_premium">
          <subscribe-page />
        </v-layout>
        <v-layout v-else>
          <slot />
        </v-layout>
      </v-container>
    </v-main>

    <v-footer
      absolute app inset
      outlined>
      <v-spacer />
      <div class="body-2 ma-1">
        Copyrights &copy; {{ $moment().format('YYYY') }} Anova. All rights reserved.
      </div>
      <v-spacer />
    </v-footer>

    <!-- <subscribe-dialog v-model="promotionalDialog" disable-activator @close="onPromotionalDialogClose" /> -->
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import client from '@ziggma/api-client';
// navigation menu configurations
import config from '@/configs';

// services
import UserService from '@/services/UserService';

// pages and components
import MainMenu from '@/components/navigation/MainMenu';
// import MarketIndexList from '@/components/market/MarketIndexList';
import SecuritySearchBox from '@/components/SecuritySearchBox';
//import SubscribeDialog from '@/components/dialogs/SubscribeDialog';
// import ToolbarPremiumStatus from '@/components/toolbar/ToolbarPremiumStatus';
import ToolbarUser from '@/components/toolbar/ToolbarUser';

import SubscribePage from '@/pages/Subscription/SubscribePage.vue';

// mixins
import logoMixin from '@/mixins/logo'

// assets
import lightLogo from '@/assets/images/logo/logo.svg';
import darkLogo from '@/assets/images/logo/logo_white.svg';

export default {
  components: {
    MainMenu,
    SecuritySearchBox,
    //SubscribeDialog,
    SubscribePage,
    //ToolbarPremiumStatus,
    ToolbarUser,
  },
  mixins: [logoMixin],
  data() {
    return {
      drawer: null,
      showSearch: false,
      menuLoading: false,
      navigation: config.navigation,

      systemMessage: null,
      promotionalDialog: false,
      promotionalDialogTimeout: null,
    };
  },
  computed: {
    ...mapState('app', ['product', 'isContentBoxed', 'menuTheme', 'toolbarTheme', 'isToolbarDetached']),
    ...mapGetters('user', ['user']),
    systemBarColor() {
      return this.isDark ? '#FFC10772' : '#FFC107A6';
    },
  },
  watch: {
    promotionalDialog(val) {
      if (val === true) {
        this.onPromotionalDialogClose();
      }
    },
  },
  created() {
    if (!this.user) {
      return;
    }

    const user = this.user;
    if (user.settings && !user.settings.app.v2_promotional_dialog && !user.is_premium) {
      this.promotionalDialogTimeout = setTimeout(() => {
        this.promotionalDialog = true;
      }, 500);
    } else if (!user.settings && !user.is_premium) {
      this.promotionalDialogTimeout = setTimeout(() => {
        this.promotionalDialog = true;
      }, 500);
    }
    this.fetchMarketplaceCategories();
  },
  beforeDestroy() {
    if (this.promotionalDialogTimeout) {
      clearTimeout(this.promotionalDialogTimeout);
    }
  },
  methods: {
    fetchMarketplaceCategories() {
      this.menuLoading = true;
      client.categories.listCategories()
        .then((res) => {
          if (res.data.length > 0) {
            const marketplaceMenu = this._.find(this.navigation.menu, (item) => item.text === 'OPPORTUNITIES');
            marketplaceMenu.items = res.data.map((category) => {
              return {
                icon: category.icon,
                //key: category.name,
                text: category.name,
                link: 'marketplace.category',
                param: category.slug,
              };
            });
          }
        })
        .finally(() => {
          this.menuLoading = false;
        });
    },
    onKeyup() {
      this.$refs.search.focus();
    },
    onPromotionalDialogClose() {
      return UserService.updateAppSetting('v2_promotional_dialog', {
        viewed_at: this.$moment().tz('America/New_York').toISOString(),
      }).then((settings) => {
        return this.$store.dispatch('user/updateSettings', settings);
      });
    },
  },
};
</script>
