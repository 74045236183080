import axios from './axios';
import util from './util';

const runBackTest = (portfolioId, startDate) => {
  return axios
    .get(`/v1/tools/back-test/${portfolioId}`, {
      params: {
        start_date: startDate,
      },
    })
    .then(util.throwOrReturn);
}

export default {
  runBackTest,
};
