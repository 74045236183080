var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "left": "",
      "min-width": "170",
      "offset-y": "",
      "transition": "slide-y-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "icon": "",
            "ripple": false
          }
        }, on), [_c('v-avatar', {
          attrs: {
            "color": "primary",
            "size": "32"
          }
        }, [_vm.avatarUrl ? _c('v-img', {
          attrs: {
            "src": _vm.avatarUrl
          }
        }) : _c('span', {
          staticClass: "white--text text-h6"
        }, [_vm._v(_vm._s(_vm.userInitials))])], 1)], 1)];
      }
    }])
  }, [_c('v-list', {
    attrs: {
      "dense": "",
      "nav": ""
    }
  }, [_vm._l(_vm.menu, function (item, index) {
    return _c('v-list-item', {
      key: index,
      attrs: {
        "disabled": item.disabled,
        "exact": item.exact,
        "to": item.link,
        "link": ""
      }
    }, [_c('v-list-item-icon', [_c('v-icon', {
      class: {
        'grey--text': item.disabled
      },
      attrs: {
        "small": ""
      }
    }, [_vm._v(_vm._s(item.icon))])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.key ? _vm.$t(item.key) : item.text))])], 1)], 1);
  }), _c('v-divider', {
    staticClass: "my-1"
  }), _c('v-list-item', {
    attrs: {
      "inactive": ""
    }
  }, [_c('v-list-item-content', {
    staticClass: "py-0",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
      }
    }
  }, [_c('v-list-item-title', {
    staticClass: "d-flex justify-space-between align-center py-1"
  }, [_c('div', [_vm._v("Dark Mode")]), _c('v-switch', {
    attrs: {
      "input-value": _vm.isDark,
      "ripple": false,
      "hide-details": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
      },
      "change": _vm.toggleTheme
    }
  })], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-1"
  }), _c('v-list-item', {
    on: {
      "click": _vm.logout
    }
  }, [_c('v-list-item-icon', [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-logout-variant")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t('menu.logout')))])], 1)], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }