var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.menu, function (level1Item, level1Index) {
    return _c('nav-menu-item', {
      key: level1Index,
      attrs: {
        "menu-item": level1Item
      }
    }, [level1Item.items ? _vm._l(level1Item.items, function (level2Item, level2Index) {
      return _c('nav-menu-item', {
        key: level2Index,
        attrs: {
          "menu-item": level2Item,
          "subgroup": "",
          "small": ""
        }
      }, [level2Item.items ? _vm._l(level2Item.items, function (level3Item, level3Index) {
        return _c('nav-menu-item', {
          key: level3Index,
          attrs: {
            "menu-item": level3Item,
            "small": ""
          }
        });
      }) : _vm._e()], 2);
    }) : _vm._e()], 2);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }