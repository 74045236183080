<template>
  <v-dialog
    :value="value"
    :persistent="loading"
    width="500"
    @input="$emit('input', $event)">
    <v-card flat>
      <v-card-title>
        Create a new {{ isCreateWatchlist ? 'watchlist' : 'portfolio' }}
      </v-card-title>

      <v-card-text>
        <v-form
          ref="createForm"
          v-model="valid"
          :disabled="loading"
          lazy-validation>
          <!-- Portfolio name -->
          <v-text-field
            v-model="newPortfolio.name"
            label="Name"
            :rules="nameRules"
            clearable
            counter="50"
            dense
            outlined
            required />

          <!-- Portfolio cash -->
          <v-text-field
            v-if="!isCreateWatchlist"
            v-model="newPortfolio.cash"
            label="Cash"
            type="number"
            :rules="cashRules"
            dense
            outlined
            required />
        </v-form>
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="loading" color="primary" text
          @click="closeDialog">
          Close
        </v-btn>
        <v-btn :loading="loading" color="primary" @click="createPortfolio">
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import client from '@ziggma/api-client';

export default {
  name: 'CreatePortfolioDialog',
  model: {
    event: 'input',
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      default: 'BUILDER',
    },
  },
  data() {
    return  {
      valid: true,
      loading: false,

      newPortfolio: {
        name: '',
        cash: 0,
        startDate: this.$moment().format('YYYY-MM-DD'),
      },

      nameRules: [
        (v) => !!v || 'Portfolio name is required',
        (v) => (v && v.length >= 5) || 'Name must be at least 5 characters',
        (v) => (v && v.length <= 50) || 'Name must be less than 50 characters',
      ],
      cashRules: [
        (v) => (v !== '') || 'Initial cash amount is required',
        (v) => (v >= 0) || 'Cash amount cannot be negative',
        (v) => (v <= 100000000) || 'Cash amount cannot exceed $100,000,000',
      ],
    }
  },
  computed: {
    minDate() {
      return this.$moment().tz('America/New_York').subtract(5, 'years').format('YYYY-MM-DD');
    },
    maxDate() {
      return this.$moment().tz('America/New_York').format('YYYY-MM-DD');
    },
    isCreateWatchlist() {
      return this.type === 'WATCHLIST';
    },
  },
  methods: {
    closeDialog() {
      this.$emit('input', false);
      this.$nextTick(() => {
        this.$refs.createForm.reset();
        this.$refs.createForm.resetValidation();
      });
    },
    createPortfolio() {
      if (!this.$refs.createForm.validate()) {
        return;
      }

      this.loading = true;
      const promise = this.isCreateWatchlist
        ? client.portfolios.createWatchlist({ name: this.newPortfolio.name })
        : client.portfolios.createPortfolio({ ...this.newPortfolio });

      promise
        .then((res) => {
          return this.$store.dispatch('portfolio/setPortfolio', res.data);
        })
        .then((portfolio) => {
          this.$store.dispatch('portfolio/selectPortfolio', portfolio);
          this.$emit('create', portfolio);
          this.closeDialog();
        })
        .catch((err) => {
          return this.$store.dispatch('app/showError', { error: err });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // Utils
    allowedDates(date) {
      return this.$moment(date).isoWeekday() < 6;
    },
  },
};
</script>

<style scoped>

</style>
