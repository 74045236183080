<template>
  <div>
    <v-app-bar flat height="80">
      <v-container class="py-0 px-0 px-sm-2 fill-height">
        <router-link class="d-flex align-center text-decoration-none mr-2" to="/dashboard/analytics">
          <v-img contain height="36" src="@/assets/images/logo/logo.svg" />
        </router-link>

        <v-spacer />

        <div class="d-none d-md-block">
          <v-btn class="mx-1" text @click="$vuetify.goTo('#pricing');">
            Pricing
          </v-btn>
          <v-btn class="mx-1" text to="/auth/signin">
            Sign in
          </v-btn>
        </div>

        <v-btn large outlined to="/auth/signup">
          Sign Up
        </v-btn>
      </v-container>
    </v-app-bar>

    <v-main>
      <router-view :key="$route.fullPath" />

      <v-footer color="transparent">
        <v-container class="py-5">
          <v-row>
            <v-col cols="12" md="4">
              <div class="text-h6 text-lg-h5 font-weight-bold">Navigation</div>
              <div class="mb-5 mt-1 primary" style="width: 80px; height: 2px" />
              <div class="d-flex flex-wrap">
                <div v-for="(link, i) in links" :key="i" class="w-half body-1 mb-1">
                  <router-link
                    v-if="link.to"
                    :to="link.to"
                    class="text-decoration-none text--primary">
                    {{ link.label }}
                  </router-link>
                  <a
                    v-else
                    :href="link.href"
                    :target="link.target || 'blank'"
                    class="text-decoration-none text--primary">{{ link.label }}</a>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <div class="text-h6 text-lg-h5 font-weight-bold">Contact Information</div>
              <div class="mb-5 mt-1 primary" style="width: 80px; height: 2px" />
              <div class="d-flex mb-2 font-weight-bold">
                <v-icon class="mr-2" color="primary lighten-1">mdi-map-marker-outline</v-icon>
                W Left Street, Long Beach, Los Angeles, CA 90802 USA
              </div>
              <div class="d-flex mb-2">
                <v-icon class="mr-2" color="primary lighten-1">mdi-phone-outline</v-icon>
                <a class="text-decoration-none text--primary" href="#">+03 000 23 00 555 55</a>
              </div>
              <div class="d-flex mb-2">
                <v-icon class="mr-2" color="primary lighten-1">mdi-email-outline</v-icon>
                <a class="text-decoration-none text--primary" href="#">hello@loremcompany.com</a>
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <div class="text-h6 text-lg-h5 font-weight-bold">Newsletter</div>
              <div class="mb-5 mt-1 primary" style="width: 80px; height: 2px" />
              <div class="d-flex flex-column flex-lg-row w-full">
                <v-text-field
                  class="mr-lg-2"
                  dense
                  height="44"
                  label="Your email"
                  outlined
                  solo />
                <v-btn color="primary" large>Subscribe</v-btn>
              </div>
              <div class="text-center text-md-right mt-4 mt-lg-2">
                Connect
                <v-btn
                  class="ml-2" color="primary" fab
                  small>
                  <v-icon>mdi-twitter</v-icon>
                </v-btn>
                <v-btn
                  class="ml-2" color="primary" fab
                  small>
                  <v-icon>mdi-linkedin</v-icon>
                </v-btn>
                <v-btn
                  class="ml-2" color="primary" fab
                  small>
                  <v-icon>mdi-instagram</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-3" />
          <div class="text-center caption">
            © Indielayer 2021. All Rights Reserved
          </div>
        </v-container>
      </v-footer>
    </v-main>
  </div>
</template>

<script>
import config from '@/configs'

export default {
  data() {
    return {
      config,
      links: [
        {
          label: 'Overview',
          to: '#',
        }, {
          label: 'Features',
          to: '#',
        }, {
          label: 'Pricing',
          to: '#',
        }, {
          label: 'Documentation',
          to: '#',
        }, {
          label: 'News',
          to: '#',
        }, {
          label: 'FAQ',
          to: '#',
        }, {
          label: 'About us',
          to: '#',
        }, {
          label: 'Carrers',
          to: '#',
        }, {
          label: 'Press',
          to: '#',
        }],
    }
  },
}
</script>
