var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    attrs: {
      "disabled": _vm.loading || _vm.postTradeLoading,
      "lazy-validation": ""
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    },
    model: {
      value: _vm.formValid,
      callback: function callback($$v) {
        _vm.formValid = $$v;
      },
      expression: "formValid"
    }
  }, [_vm.portfolio && _vm.portfolio.isWatchlist() ? _c('div', [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "disabled": _vm.portfolios.length === 1,
      "items": _vm.groupedPortfolios,
      "loading": _vm.loading,
      "readonly": _vm.readonly,
      "dense": "",
      "hide-details": "",
      "item-text": "name",
      "item-value": "id",
      "label": "Portfolio",
      "outlined": "",
      "placeholder": "Select a portfolio..."
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "pb-1",
    attrs: {
      "dense": _vm.dense
    }
  }, [_vm.error ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    staticClass: "ma-0",
    attrs: {
      "border": "left",
      "dense": "",
      "text": "",
      "type": "error"
    }
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")])], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [!_vm.security ? _c('v-autocomplete', {
    attrs: {
      "hide-no-data": !_vm.search || _vm.search === '' || _vm.searching,
      "items": _vm.results,
      "loading": _vm.searching,
      "rules": _vm.rules.security,
      "search-input": _vm.search,
      "dense": "",
      "flat": "",
      "hide-details": "",
      "item-text": "name",
      "item-value": "ticker",
      "label": "Security",
      "no-data-text": "No matching securities",
      "no-filter": "",
      "outlined": "",
      "placeholder": "Search securities...",
      "prepend-inner-icon": "mdi-magnify",
      "return-object": ""
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.search = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.search = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(item.name) + " (" + _vm._s(item.ticker) + ") ")])], 1)];
      }
    }, {
      key: "selection",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.name) + " (" + _vm._s(item.ticker) + ") ")];
      }
    }], null, false, 934871848),
    model: {
      value: _vm.transactionSecurity,
      callback: function callback($$v) {
        _vm.transactionSecurity = $$v;
      },
      expression: "transactionSecurity"
    }
  }) : _c('v-text-field', {
    attrs: {
      "value": "".concat(_vm.security.name, " (").concat(_vm.security.ticker, ")"),
      "dense": "",
      "disabled": "",
      "flat": "",
      "hide-details": "",
      "label": "Security",
      "outlined": "",
      "prepend-inner-icon": "mdi-magnify",
      "readonly": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-btn', {
    attrs: {
      "loading": _vm.addingToWatchlist,
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.addToWatchlist
    }
  }, [_vm._v("Add to watchlist")])], 1)], 1)], 1) : _c('v-row', {
    attrs: {
      "dense": _vm.dense
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "pl-3 pr-3 pb-3",
    attrs: {
      "flat": "",
      "outlined": _vm.isStock
    }
  }, [_c('v-card-title', {
    staticClass: "px-0"
  }, [_vm._v(" Place Order ")]), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "3"
    }
  }, [_c('div', [_c('v-select', {
    attrs: {
      "items": _vm.orderTypes,
      "item-text": "name",
      "item-value": "value",
      "loading": _vm.loading,
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "flat": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', {
          class: {
            'success--text': item.value === 'buy',
            'error--text': item.value === 'sell'
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: "item",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          class: {
            'success--text': item.value === 'buy',
            'error--text': item.value === 'sell'
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }]),
    model: {
      value: _vm.selectedOrderType_,
      callback: function callback($$v) {
        _vm.selectedOrderType_ = $$v;
      },
      expression: "selectedOrderType_"
    }
  })], 1)])], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_vm.error ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    staticClass: "ma-0",
    attrs: {
      "border": "left",
      "dense": "",
      "text": "",
      "type": "error"
    }
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")])], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [!_vm.security ? _c('v-autocomplete', {
    attrs: {
      "hide-no-data": !_vm.search || _vm.search === '' || _vm.searching,
      "items": _vm.results,
      "loading": _vm.searching,
      "rules": _vm.rules.security,
      "search-input": _vm.search,
      "dense": "",
      "flat": "",
      "hide-details": "",
      "item-text": "name",
      "item-value": "ticker",
      "label": "Security",
      "no-data-text": "No matching securities",
      "no-filter": "",
      "outlined": "",
      "placeholder": "Search securities...",
      "prepend-inner-icon": "mdi-magnify",
      "return-object": ""
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.search = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.search = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(item.name) + " (" + _vm._s(item.ticker) + ") ")])], 1)];
      }
    }, {
      key: "selection",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.name) + " (" + _vm._s(item.ticker) + ") ")];
      }
    }], null, false, 934871848),
    model: {
      value: _vm.transactionSecurity,
      callback: function callback($$v) {
        _vm.transactionSecurity = $$v;
      },
      expression: "transactionSecurity"
    }
  }) : _c('v-text-field', {
    attrs: {
      "value": "".concat(_vm.security.name, " (").concat(_vm.security.ticker, ")"),
      "dense": "",
      "disabled": "",
      "flat": "",
      "hide-details": "",
      "label": "Security",
      "outlined": "",
      "prepend-inner-icon": "mdi-magnify",
      "readonly": ""
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-simple-table', {
    attrs: {
      "dense": "",
      "fixed-header": "",
      "height": _vm.portfolios.length > 4 ? 148 : undefined
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('thead', [_c('tr', [_c('th', [_vm._v("Account")]), _c('th', {
          staticClass: "text-end",
          staticStyle: {
            "width": "100px"
          }
        }, [_vm._v(" Cash ")]), _c('th', {
          staticClass: "text-center px-0",
          staticStyle: {
            "width": "20px"
          }
        }), _c('th', {
          staticClass: "text-end",
          staticStyle: {
            "width": "100px"
          }
        }, [_vm._v(" Position ")])])]), _c('tbody', {
          staticClass: "mb-2"
        }, _vm._l(_vm.groupedPortfolios, function (item) {
          return _c('tr', {
            key: item.id,
            class: {
              'bid-section': item.id === _vm.selected
            },
            on: {
              "click": function click($event) {
                return _vm.handleClick(item.id);
              }
            }
          }, [_c('td', {
            staticClass: "d-flex align-center"
          }, [_c('v-avatar', {
            staticClass: "mr-1",
            attrs: {
              "left": "",
              "size": "18"
            }
          }, [item && item.account && item.account.brokerage.logo_url ? _c('v-img', {
            attrs: {
              "src": item.account.brokerage.logo_url
            }
          }) : item.type === 'BANK' ? _c('v-icon', {
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-bank")]) : _vm._e()], 1), _vm._v(" " + _vm._s(_vm._f("breakpointTruncate")(item.name, 15, 20, 25, 30, 40)) + " ")], 1), _c('td', {
            staticClass: "text-end"
          }, [_vm._v(" " + _vm._s(_vm._f("formatCurrency")(item.availableCash)) + " ")]), _c('td', {
            staticClass: "text-end px-0"
          }, [item.type !== 'BANK' && item.type !== 'WATCHLIST' ? _c('v-btn', {
            attrs: {
              "x-small": "",
              "icon": ""
            },
            on: {
              "click": function click($event) {
                _vm.depositDialog = true;
                _vm.depositPortfolio = item;
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "x-small": ""
            }
          }, [_vm._v("mdi-plus-circle-outline")])], 1) : _vm._e()], 1), _c('td', {
            staticClass: "text-end"
          }, [_vm._v(" " + _vm._s(_vm.positionPerPortfolio[item.id] || 0) + " ")])]);
        }), 0)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-expand-transition', [_c('v-alert', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.portfolio && _vm.portfolio.isBank(),
      expression: "portfolio && portfolio.isBank()"
    }],
    staticClass: "ma-0 mt-2",
    attrs: {
      "dense": "",
      "text": "",
      "type": "info"
    }
  }, [_vm._v(" Note that bank portfolios are immutable and that any modification will occur on a replica of this portfolio. ")])], 1)], 1)], 1), _c('v-row', {
    staticClass: "mt-2",
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "offset-sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "disabled": !_vm.transactionSecurity,
      "loading": _vm.loadingPrice,
      "rules": _vm.rules.price,
      "label": "Price",
      "dense": "",
      "flat": "",
      "hide-details": "",
      "min": "0",
      "outlined": "",
      "type": "number"
    },
    model: {
      value: _vm.transactionPrice,
      callback: function callback($$v) {
        _vm.transactionPrice = $$v;
      },
      expression: "transactionPrice"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-2",
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "offset-sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "disabled": !_vm.transactionSecurity,
      "rules": _vm.rules.shares,
      "label": "Quantity",
      "dense": "",
      "flat": "",
      "hide-details": "",
      "outlined": "",
      "type": "number"
    },
    model: {
      value: _vm.transactionShares,
      callback: function callback($$v) {
        _vm.transactionShares = $$v;
      },
      expression: "transactionShares"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-2",
    attrs: {
      "id": "transaction-value",
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "offset-sm": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "px-3",
    attrs: {
      "disabled": "",
      "label": "Transaction Value",
      "dense": "",
      "flat": "",
      "hide-details": "",
      "readonly": "",
      "type": "number"
    },
    model: {
      value: _vm.tradeValue,
      callback: function callback($$v) {
        _vm.tradeValue = $$v;
      },
      expression: "tradeValue"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "mr-3 mt-2",
    attrs: {
      "disabled": _vm.simulating && _vm.postTradeLoading,
      "loading": !_vm.simulating && _vm.postTradeLoading,
      "color": _vm.selectedOrderType.color
    },
    on: {
      "click": _vm.execute
    }
  }, [_vm._v(" " + _vm._s(_vm.selectedOrderType.name) + " ")]), _c('v-btn', {
    staticClass: "mr-1 mt-2",
    attrs: {
      "disabled": !_vm.simulating && _vm.postTradeLoading,
      "loading": _vm.simulating && _vm.postTradeLoading,
      "color": "primary",
      "type": "submit"
    },
    on: {
      "click": _vm.simulate
    }
  }, [_vm._v(" Simulate ")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    class: _vm.isStock ? '' : 'pl-3 pr-3 pb-3',
    attrs: {
      "flat": "",
      "outlined": _vm.isStock
    }
  }, [_c('v-card-title', {
    staticClass: "px-0"
  }, [_vm._v(" Portfolio Impact ")]), _c('v-simple-table', {
    attrs: {
      "dense": _vm.dense
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-start subtitle-1",
          staticStyle: {
            "width": "40%"
          }
        }, [_vm._v("KPI")]), _c('th', {
          staticClass: "text-end subtitle-1",
          staticStyle: {
            "width": "30%"
          }
        }, [_vm.currentRiskLoading ? _c('v-progress-circular', {
          staticClass: "mr-1",
          attrs: {
            "indeterminate": "",
            "size": "13"
          }
        }) : _vm._e(), _vm._v(" Pre-trade ")], 1), _c('th', {
          staticClass: "text-end subtitle-1",
          staticStyle: {
            "width": "30%"
          }
        }, [_vm.postTradeRiskLoading ? _c('v-progress-circular', {
          staticClass: "mr-1",
          attrs: {
            "indeterminate": "",
            "size": "13"
          }
        }) : _vm._e(), _vm._v(" Post-trade ")], 1)])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-subtitle-1"
        }, [_vm._v("Top Stock"), _c('small', {
          staticClass: "ml-1 text--secondary"
        }, [_vm._v("Diversification")])]), _c('td', {
          staticClass: "text-end"
        }, [_vm._v(_vm._s(_vm._f("formatPercentage")(_vm.currentRisk.largest_position, 2)))]), _c('td', {
          staticClass: "text-end"
        }, [_c('sentimental', {
          attrs: {
            "reference": _vm.currentRisk.largest_position,
            "value": _vm.postTradeRisk.largest_position,
            "left": "",
            "percentage": "",
            "precision": "2",
            "show-arrow": "",
            "inverse": ""
          }
        })], 1)]), _c('tr', [_c('td', {
          staticClass: "text-subtitle-1"
        }, [_vm._v("Top Sector "), _c('small', {
          staticClass: "ml-1 text--secondary"
        }, [_vm._v("Diversification")])]), _c('td', {
          staticClass: "text-end"
        }, [_vm._v(_vm._s(_vm._f("formatPercentage")(_vm.currentRisk.largest_sector, 2)))]), _c('td', {
          staticClass: "text-end"
        }, [_c('sentimental', {
          attrs: {
            "reference": _vm.currentRisk.largest_sector,
            "value": _vm.postTradeRisk.largest_sector,
            "left": "",
            "percentage": "",
            "precision": "2",
            "show-arrow": "",
            "inverse": ""
          }
        })], 1)]), _c('tr', [_c('td', {
          staticClass: "subtitle-1"
        }, [_vm._v("Beta"), _c('small', {
          staticClass: "ml-1 text--secondary"
        }, [_vm._v("Risk Factor")])]), _c('td', {
          staticClass: "text-end"
        }, [_vm._v(_vm._s(_vm._f("formatNumber")(_vm.currentRisk.beta, 2)))]), _c('td', {
          staticClass: "text-end"
        }, [_c('sentimental', {
          attrs: {
            "reference": _vm.currentRisk.beta,
            "value": _vm.postTradeRisk.beta,
            "inverse": "",
            "left": "",
            "precision": "2",
            "show-arrow": ""
          }
        })], 1)]), _c('tr', [_c('td', {
          staticClass: "subtitle-1"
        }, [_vm._v("Anova Score")]), _c('td', {
          staticClass: "text-end"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-end"
        }, [_c('sentimental', {
          attrs: {
            "reference": _vm.currentFinancials.score,
            "value": _vm.postTradeFinancials.score,
            "left": "",
            "precision": "0",
            "show-arrow": ""
          }
        })], 1)]), _c('tr', [_c('td', {
          staticClass: "subtitle-1"
        }, [_vm._v("Profit Growth"), _c('small', {
          staticClass: "ml-1 text--secondary"
        }, [_vm._v("Next Year")])]), _c('td', {
          staticClass: "text-end"
        }, [_vm._v(_vm._s(_vm._f("formatPercentage")(_vm.currentFinancials.profit_growth_forward_1, 2)))]), _c('td', {
          staticClass: "text-end"
        }, [_c('sentimental', {
          attrs: {
            "reference": _vm.currentFinancials.profit_growth_forward_1,
            "value": _vm.postTradeFinancials.profit_growth_forward_1,
            "left": "",
            "percentage": "",
            "precision": "2",
            "show-arrow": ""
          }
        })], 1)]), _c('tr', [_c('td', {
          staticClass: "subtitle-1"
        }, [_vm._v("Yield")]), _c('td', {
          staticClass: "text-end"
        }, [_vm._v(_vm._s(_vm._f("formatPercentage")(_vm.currentFinancials.yield, 2)))]), _c('td', {
          staticClass: "text-end"
        }, [_c('sentimental', {
          attrs: {
            "reference": _vm.currentFinancials.yield,
            "value": _vm.postTradeFinancials.yield,
            "left": "",
            "precision": "2",
            "percentage": "",
            "show-arrow": ""
          }
        })], 1)])])];
      },
      proxy: true
    }])
  }), _c('v-card-text', {
    staticClass: "subtitle-2 mt-13 pb-0"
  }, [_vm._v(" The portfolio impact of a trade is shown for informational purposes only. It is not to be construed as investment advice. ")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": _vm.depositLoading,
      "width": "400"
    },
    on: {
      "close": _vm.closeDepositDialog
    },
    model: {
      value: _vm.depositDialog,
      callback: function callback($$v) {
        _vm.depositDialog = $$v;
      },
      expression: "depositDialog"
    }
  }, [_vm.portfolio !== null ? _c('v-card', [_c('v-card-title', {
    staticClass: "text-h5 text-wrap"
  }, [_vm._v(" Make a deposit ")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "pb-1 pt-1"
  }, [_vm.depositError ? _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('v-alert', {
    staticClass: "ma-0",
    attrs: {
      "dense": "",
      "text": "",
      "type": "error",
      "border": "left"
    }
  }, [_vm._v(" " + _vm._s(_vm.depositError) + " ")])], 1)], 1) : _vm._e(), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "loading": _vm.depositLoading,
      "rules": _vm.depositRules,
      "label": "Amount",
      "type": "number",
      "hide-details": "",
      "dense": "",
      "filled": "",
      "outlined": ""
    },
    model: {
      value: _vm.depositAmount,
      callback: function callback($$v) {
        _vm.depositAmount = $$v;
      },
      expression: "depositAmount"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "disabled": _vm.depositLoading,
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.closeDepositDialog
    }
  }, [_vm._v(" Close ")]), _c('v-btn', {
    attrs: {
      "loading": _vm.depositLoading,
      "color": "primary"
    },
    on: {
      "click": _vm.deposit
    }
  }, [_vm._v(" Deposit ")])], 1)], 1) : _vm._e()], 1), !_vm.user.is_premium ? _c('subscribe-dialog', {
    attrs: {
      "disable-activator": ""
    },
    model: {
      value: _vm.subscribeDialog,
      callback: function callback($$v) {
        _vm.subscribeDialog = $$v;
      },
      expression: "subscribeDialog"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }