import darkLogo from '@/assets/images/logo/logo_white.svg';
import lightLogo from '@/assets/images/logo/logo.svg';

export default {
  computed: {
    isDark() {
      return this.$vuetify.theme.dark || this.toolbarTheme === 'dark';
    },
    logoSrc() {
      return this.isDark ? darkLogo : lightLogo;
    },
  },
}
