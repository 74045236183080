var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list', {
    staticClass: "pt-0",
    attrs: {
      "nav": "",
      "dense": ""
    }
  }, _vm._l(_vm.filteredMenu, function (item, index) {
    return _c('div', {
      key: index
    }, [item.key || item.text ? _c('div', {
      staticClass: "pa-1 mt-1 subtitle-2"
    }, [_vm._v(_vm._s(item.key ? _vm.$t(item.key) : item.text))]) : _vm._e(), _c('nav-menu', {
      attrs: {
        "menu": item.items
      }
    })], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }