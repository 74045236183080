import axios from './axios';
import util from './util';

const fetch = (source, page = 1, perPage = 25) => {
  return axios
    .get('/v1/news', {
      params: {
        source,
        page,
        perPage,
      },
    })
    .then(util.throwOrReturn);
};

const fetchTwitterNews = (page = 1, perPage = 25) => {
  return fetch('twitter', page, perPage);
};

const fetchIntrinioNews = (page = 1, perPage = 25) => {
  return fetch('intrinio', page, perPage);
};

export default {
  fetchTwitterNews,
  fetchIntrinioNews,
};
