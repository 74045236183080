var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "fill-height align-center justify-center",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "mx-auto",
    attrs: {
      "cols": "12",
      "sm": "10",
      "lg": "8"
    }
  }, [_c('v-sheet', {
    staticClass: "mx-auto pa-4",
    attrs: {
      "elevation": "1",
      "rounded": "",
      "max-width": "680"
    }
  }, [_c('div', {
    staticClass: "mt-4"
  }, [_c('v-img', {
    attrs: {
      "src": _vm.logo,
      "contain": "",
      "max-height": "100"
    }
  })], 1), _c('div', {
    staticClass: "mt-6"
  }, [_c('div', {
    staticClass: "text-h3 text-center font-weight-bold primary--text mb-3"
  }, [_vm._v(" Please verify your email! ")]), _c('div', {
    staticClass: "text-h5 text-center"
  }, [_vm._v("You're almost there! We sent an email to")]), _c('div', {
    staticClass: "text-h5 text-center"
  }, [_vm._v(" " + _vm._s(_vm.user.email) + " ")])]), _c('div', {
    staticClass: "mt-6"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_vm._v("Just click on the link in that email to complete your signup.")]), _c('div', {
    staticClass: "text-center"
  }, [_vm._v("If you don't see it, you may need to "), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("check your spam folder.")])])]), _c('div', {
    staticClass: "mt-6"
  }, [_c('div', {
    staticClass: "text-center mb-2"
  }, [_vm._v("Still can't find the email?")]), _c('div', {
    staticClass: "d-flex justify-center"
  }, [_c('v-btn', {
    staticClass: "text-uppercase",
    attrs: {
      "disabled": _vm.canResendIn > 0,
      "ripple": false,
      "depressed": "",
      "color": "primary"
    },
    on: {
      "click": _vm.resendConfirmationEmail
    }
  }, [_vm._v(" Resend Email " + _vm._s(_vm.canResendIn ? "(".concat(_vm.canResendIn, ")") : '') + " ")])], 1), _c('div', {
    staticClass: "text-center mt-3 mb-2"
  }, [_vm._v("Need help? "), _c('a', {
    attrs: {
      "href": "https://ziggma.com/contact",
      "target": "_blank"
    }
  }, [_vm._v("Contact Us")])])])])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }