<template>
  <v-card outlined flat :width="width" :max-width="maxWidth">
    <v-card-title>
      <div class="d-flex" style="width: 100%">
        <span class="text-h4 font-weight-bold">{{ plan.name }}</span>
        <span v-if="!plan.free">
          <v-chip small color="primary" class="text-uppercase mt-1 ml-2">7-day free trial</v-chip>
        </span>
      </div>
    </v-card-title>
    <v-card-text style="height: 70px">
      <div v-if="plan.free" class="d-flex align-end">
        <div class="text-h3 font-weight-bold text-number mb-5">$0</div>
      </div>
      <div v-if="price && !annual" class="d-flex align-end">
        <div v-if="!price.promotion" class="text-h3 font-weight-bold text-number">{{ price.amount | formatCurrency }}</div>
        <div v-if="price.promotion" class="text-h4 font-weight-bold text-number red--text text-decoration-line-through">
          {{ price.amount | formatCurrency }}
        </div>
        <div v-if="price.promotion" class="text-h3 font-weight-bold text-number">{{ price.promotion.amount | formatCurrency }}</div>
        <div v-if="price.interval" class="text-h6 font-weight-bold text-number">/{{ price.interval | shortenInterval }}</div>
      </div>
      <div v-if="price && annual" class="d-flex align-end">
        <div v-if="!price.promotion" class="text-h3 font-weight-bold text-number">{{ (price.amount/12) | formatCurrency }}</div>
        <div v-if="price.promotion" class="text-h4 font-weight-bold text-number red--text text-decoration-line-through">
          {{ (price.amount/12) | formatCurrency }}
        </div>
        <div v-if="price.promotion" class="text-h3 font-weight-bold text-number">{{ (price.promotion.amount/12) | formatCurrency }}</div>
        <div v-if="price.interval" class="text-h6 font-weight-bold text-number">/{{ 'month' | shortenInterval }}</div>
        <div class="text-h6 font-weight-medium ml-1">(billed annually)</div>
      </div>
      <div v-if="price && price.promotion" class="d-flex align-content-center ml-1 mt-2">
        <div class="pt-1">Limited time only</div>
        <v-chip
          label small pill color="#4caf5073"
          class="ml-2">
          {{ promotionSavings | formatPercentage(0) }} off
        </v-chip>
      </div>
    </v-card-text>
    <v-card-text class="pt-0 pb-0">
      <v-sheet color="lighten-5" style="min-height: 330px">
        <span v-if="plan.preamble" class="text-h6 font-weight-bold text-uppercase">{{ plan.preamble }}</span>
        <div v-for="(feature, j) in plan.features" :key="j" class="d-flex align-center text-h6">
          <div class="d-flex align-start">
            <div>
              <v-icon color="success" class="mr-2">mdi-check</v-icon>
            </div>
            <div>
              <span class="font-weight-regular">{{ feature.text | capitalize(true) }}</span>
            </div>
            <!-- <div>
              <v-chip
                v-if="feature.new" class="ml-2" color="orange" small
                label
                outlined>
                New
              </v-chip>
            </div> -->
          </div>
        </div>
      </v-sheet>
    </v-card-text>
    <v-card-actions v-if="!plan.free || active">
      <v-btn
        x-large
        block
        outlined
        :disabled="active || disabled"
        @click="onSubscribeClick">
        {{ active ? 'Current Plan' : 'Subscribe' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'PlanCard',
  filters: {
    shortenInterval(interval) {
      if (interval === 'month') {
        return 'mo.';
      }
      if (interval === 'year') {
        return 'yr.';
      }
      return interval;
    },
  },
  props: {
    plan: {
      type: Object,
      required: true,
    },
    preferAnnual: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [String, Number],
      default: '100%',
    },
    maxWidth: {
      type: [String, Number],
      default: '450',
    },
  },
  data: () => ({
    annual: false,
  }),
  computed: {
    active() {
      if (this.plan.free) {
        return this.plan.active || false;
      }

      return this.price.active;
    },
    price() {
      if (this.plan.free || !this.plan.prices) {
        return null;
      }

      return this.plan.prices.find((price) => price.interval === (this.annual ? 'year' : 'month'));
    },
    hasAnnual() {
      return !this.plan.free && !!this.plan.prices.find((price) => price.interval === 'year');
    },
    promotionSavings() {
      if (this.plan.free) {
        return 0;
      }

      if (!this.price.promotion) {
        return 0;
      }

      return 1 - (this.price.promotion.amount / this.price.amount);
    },
    annualSavings() {
      if (this.plan.free || !this.hasAnnual) {
        return 0;
      }

      const monthly = this.plan.prices.find((price) => price.interval === 'month');
      const yearly = this.plan.prices.find((price) => price.interval === 'year');

      const monthlyAmount = monthly.promotion ? monthly.promotion.amount : monthly.amount;
      const yearlyAmount = yearly.promotion ? yearly.promotion.amount : yearly.amount;

      return 1 - (yearlyAmount / (12 * monthlyAmount));
    },
  },
  watch: {
    preferAnnual: {
      immediate: true,
      handler(preferAnnual) {
        if (!preferAnnual) {
          this.annual = false;
        }

        this.annual = preferAnnual && this.hasAnnual
      },
    },
  },
  methods: {
    onSubscribeClick() {
      this.$nextTick(() => {
        this.$gtag.event('begin_checkout', {
          'event_category': 'ecommerce',
          'event_label': this.plan.name,
        });
      });
      this.$emit('subscribe', this.price);
    },
  },
};
</script>

<style scoped>

</style>
