import axios from './axios';
import util from './util';

const getStockAttributes = (industry) => {
  return axios
    .get(`/v1/screener/stocks/attributes`, {
      params: {
        industry,
      },
    })
    .then(util.throwOrReturn);
}

const getEtfAttributes = () => {
  return axios
    .get(`/v1/screener/etfs/attributes`)
    .then(util.throwOrReturn);
}

const getBondAttributes = () => {
  return axios
    .get(`/v1/screener/bonds/attributes`)
    .then(util.throwOrReturn);
}

const screenStocks = ({ index, sector, industry, scoreMin, scoreMax, filters }, { page, perPage } = undefined, signal = undefined) => {
  return axios
    .post(`/v1/screener/stocks`, {
      index,
      sector,
      industry,
      ziggma_score_min: scoreMin,
      ziggma_score_max: scoreMax,
      filters,
    }, {
      params: {
        next: page,
        perPage: perPage || 25,
      },
      signal,
    })
    .then(util.throwOrReturn);
}

const screenEtfs = ({ assetClass, type, issuers, sector, filters }, { page, perPage } = undefined, signal = undefined) => {
  return axios
    .post(`/v1/screener/etfs`, {
      asset_class: assetClass,
      type,
      issuers,
      sector,
      filters,
    }, {
      params: {
        next: page,
        perPage: perPage || 25,
      },
      signal,
    })
    .then(util.throwOrReturn);
}

const screenBonds = ({ country, type, issuers, amortType, filters }, { page, perPage } = undefined, signal = undefined) => {
  return axios
    .post(`/v1/screener/bonds`, {
      country,
      type,
      issuers,
      amortType,
      filters,
    }, {
      params: {
        next: page,
        perPage: perPage || 25,
      },
      signal,
    })
    .then(util.throwOrReturn);
}

const listStockScreeners = () => {
  return axios
    .get(`/v1/screeners`)
    .then(util.throwOrReturn);
}

const saveStockScreener = (name, { index, sector, industry, scoreMin, scoreMax, filters }) => {
  return axios
    .post(`/v1/screeners`, {
      name,
      index,
      sector,
      industry,
      ziggma_score_min: scoreMin,
      ziggma_score_max: scoreMax,
      filters,
    })
    .then(util.throwOrReturn);
}

const updateStockScreener = (id, name, { index, sector, industry, scoreMin, scoreMax, filters }) => {
  return axios
    .put(`/v1/screeners/${id}`, {
      name,
      index,
      sector,
      industry,
      ziggma_score_min: scoreMin,
      ziggma_score_max: scoreMax,
      filters,
    })
    .then(util.throwOrReturn);
}

const deleteStockScreener = (id) => {
  return axios
    .delete(`/v1/screeners/${id}`)
    .then(util.throwOrReturn);
}

export default {
  getStockAttributes,
  getEtfAttributes,
  screenStocks,
  screenEtfs,
  listStockScreeners,
  saveStockScreener,
  updateStockScreener,
  deleteStockScreener,
  getBondAttributes,
  screenBonds,
}
