import Vue from 'vue';
import App from './App.vue';

// VUEX - https://vuex.vuejs.org/
import store from './store';

// VUE-ROUTER - https://router.vuejs.org/
import router from './router';

// PLUGINS
import vuetify from './plugins/vuetify';
import i18n from './plugins/vue-i18n';
import './plugins/flags';
import './plugins/vue-cookies';
import './plugins/vue-lodash';
import './plugins/vue-shortkey';
import './plugins/vue-head';
import './plugins/vue-gtag';
import './plugins/vue-recaptcha';
import './plugins/apexcharts';
import './plugins/echarts';
import './plugins/animate';
import './plugins/clipboard';
import './plugins/moment';
import './plugins/timeago';
//import './plugins/stripe';

import configureSentry from './sentry';

// COMPONENTS
import '@/components/common';

// FILTERS
import './filters/capitalize';
import './filters/lowercase';
import './filters/uppercase';
import './filters/truncate';
import './filters/formatCurrency';
import './filters/formatDate';
import './filters/formatPercentage';
import './filters/formatNumber';
import './filters/formatMarketCap';
import './filters/checkNumber';
import './filters/breakpointTruncate';

// ZIGGMA API
import client from '@ziggma/api-client';

// STYLES
// Main Theme SCSS
import './assets/scss/theme.scss';

// Animation library - https://animate.style/
import 'animate.css/animate.min.css';

// Set this to false to prevent the production tip on Vue startup.
Vue.config.productionTip = false;

configureSentry(router);

client.useRequestInterceptor(
  (config) => {
    if (store.state.auth.token) {
      // config.headers.common['Authorization'] = 'Bearer ' +
      //   store.state.auth.token;
      config.headers['z-token'] = store.state.auth.token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

client.useResponseInterceptor(
  (response) => {
    if (response.status === 401) {
      store.dispatch('app/startLoading')
        .then(() => store.dispatch('logoutUser'))
        .then(() => {
          if (router.currentRoute.name !== 'auth.login') {
            return Promise.all([
              router.push({ name: 'auth.login' }),
              store.dispatch('app/stopLoading'),
            ]);
          } else {
            return store.dispatch('app/stopLoading');
          }
        });

      return response;
    }
    return response;
  },
  (error) => {
    if (error.response) {
      const { status } = error.response;

      if (401 === status) {
        store.dispatch('app/startLoading')
          .then(() => store.dispatch('logoutUser'))
          .then(() => {
            return Promise.all([
              router.push({ name: 'auth.login' }),
              store.dispatch('app/stopLoading'),
            ]);
          })
          .then(() => {
            return error.message;
          });

        return error.response;
      } else {
        return error.response;
      }
    }

    return error;
  },
);

// Mixin for getting a child object.
Vue.mixin({
  methods: {
    $get(obj, path, defaultValue = null) {
      let result = obj;
      for (const piece of path.split('.')) {
        result = result[piece];
        if (!result || typeof result !== 'object') {
          return result || defaultValue;
        }
      }
      return defaultValue;
    },
  },
});

/*
|---------------------------------------------------------------------
| Main Vue Instance
|---------------------------------------------------------------------
|
| Render the vue application on the <div id="app"></div> in index.html
|
| https://vuejs.org/v2/guide/instance.html
|
*/
export default new Vue({
  i18n,
  vuetify,
  router,
  store,
  mounted() {
    // referrer
    if (document.referrer && !this.$cookies.isKey('z-referrer')) {
      this.$cookies.set('z-referrer', document.referrer);
    }

    // theme
    let isDark = localStorage.getItem('dark');
    if (!isDark) {
      localStorage.setItem('dark', 'true');
      isDark = 'true';
    }

    if (isDark === 'true') {
      this.$vuetify.theme.dark = true;
    }
  },
  render: (h) => h(App),
}).$mount('#app');
