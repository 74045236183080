import { COLOR_MAP } from '@/utils/colors';

export default {
  // global theme for the app
  globalTheme: 'dark', // light | dark

  // side menu theme, use global theme or custom
  menuTheme: 'global', // global | light | dark

  // toolbar theme, use global theme or custom
  toolbarTheme: 'global', // global | light | dark

  // show toolbar detached from top
  isToolbarDetached: false,

  // wrap pages content with a max-width
  isContentBoxed: false,

  // application is right to left
  isRTL: false,

  // dark theme colors
  dark: {
    // background: '#05090c',
    background: '#001e2a',
    // surface: '#111b27',
    surface: '#22222b',
    primary: '#d4af37',
    secondary: '#829099',
    accent: '#f1c500',
    error: '#CC171D',
    info: '#d4af37',
    success: '#4CAF50',
    warning: '#FFC107',
    // Adds score colors.
    ...COLOR_MAP,
    // Premium colors
    premium: '#CFA602',
  },

  // light theme colors
  light: {
    background: '#ffffff',
    surface: '#f2f5f8',
    primary: '#d4af37',
    secondary: '#a0b9c8',
    accent: '#f1c500',
    error: '#CC171D',
    info: '#d4af37',
    success: '#4CAF50',
    warning: '#FFC107',
    // Adds score colors.
    ...COLOR_MAP,
    // Premium colors
    premium: '#CFA602',
  },
}
