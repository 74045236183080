import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  equities: {
    predefinedScreener: null,
    userScreener: null,
    stockIndex: null,
    sector: null,
    industry: null,
    scoreRange: null,
    filters: null,
  },
  etfs: {
    assetClass: null,
    type: null,
    issuers: [],
    sector: null,
    filters: [],
  },
  bonds: {
    country: null,
    type: null,
    issuers: [],
    amortType: null,
    filters: [],
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
