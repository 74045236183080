import axios from './axios';
import util from './util';

const listPlans = () => {
  return axios
    .get('/v1/plans')
    .then(util.throwOrReturn);
}

export default {
  listPlans,
}
