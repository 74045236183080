import maps from './maps'
import time from './time'
import icons from './icons'
import theme from './theme'
import toolbar from './toolbar'
import locales from './locales'
import analytics from './analytics'
import currencies from './currencies'
import navigation from './navigation'
import security from './security';

export default {
  // product display information
  product: {
    name: process.env.VUE_APP_NAME,
    subtitle: process.env.VUE_APP_SUBTITLE,
    version: require('@/../package.json').version,
    privacy_policy_url: process.env.VUE_APP_PRIVACY_POLICY_URL,
    terms_url: process.env.VUE_APP_TERMS_URL,
  },

  flags: process.env.VUE_APP_DEVCYCLE_ENV,

  // google maps
  maps,

  // time configs
  time,

  // icon libraries
  icons,

  // theme configs
  theme,

  // toolbar configs
  toolbar,

  // locales configs
  locales,

  // analytics configs
  analytics,

  // currencies configs
  currencies,

  // navigation configs
  navigation,

  // security configs
  security,
}
