import Vue from 'vue';
import Vuex from 'vuex';

import VuexPersistence from 'vuex-persist';

// Global vuex
import AppModule from './app';
import AuthModule from './auth';
import UserModule from './user';
import PortfolioModule from './portfolio';
import SecurityModule from './securities';
import ScreenerModule from './screener';

Vue.use(Vuex);

const persist = new VuexPersistence({
  strictMode: process.env.NODE_ENV !== 'production',
  key: 'state',
  modules: ['app', 'auth', 'user', 'portfolio', 'screener'],
});

/**
 * Main Vuex Store
 */
const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [persist.plugin],
  modules: {
    app: AppModule,
    auth: AuthModule,
    user: UserModule,
    portfolio: PortfolioModule,
    securities: SecurityModule,
    screener: ScreenerModule,
  },
  mutations: {
    RESTORE_MUTATION: persist.RESTORE_MUTATION,
  },
  actions: {
    initStore: async ({ commit }) => {
      await commit('app/resetToast');
      await commit('app/setLoading', false);

      // rehydration
      //await store.commit('user/hydrate');
      await store.commit('portfolio/hydrate');
    },
    resetStore: async ({ commit }) => {
      await commit('app/resetToast');
      await commit('portfolio/reset');
    },

    loginUser: async ({ commit }, { user, token }) => {
      await Promise.all([
        commit('auth/setToken', token),
        commit('user/setUser', user),
      ]);
    },
    logoutUser: async ({ commit }) => {
      await Promise.all([
        commit('auth/setToken', null),
        commit('user/setUser', null),
        commit('screener/resetEquityScreener'),
        commit('screener/resetEtfScreener'),
        commit('screener/resetBondScreener'),
      ]);
    },
  },
});

export default store;
